import axios from "axios";
import { onValue } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import { api, authRef, makeRef } from "../Firebase";
import { Customer } from "../interface";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Textarea from "../ui/input/Textarea";

export default function Broadcaster() {
  const [tokens, setTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const getCustomers = useCallback(() => {
    const ref = makeRef("customers");
    onValue(
      ref,
      (snapshot) => {
        if (snapshot.exists()) {
          const list = [] as string[];
          snapshot.forEach((item) => {
            const value = item.val() as Customer;
            if (value.notificationsToken) {
              list.push(value.notificationsToken);
            }
          });
          setTokens(list);
        } else {
          setTokens([]);
        }
      },
      { onlyOnce: true }
    );
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const broadcastMessage = async () => {
    if (!title.length || !description.length) return;
    try {
      setLoading(true);
      const response = await axios({
        url: `${api}/broadcast`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await authRef.currentUser?.getIdToken(true)}`,
        },
        data: {
          tokens,
          title,
          body: description,
        },
      });
      console.log(response.status);
      setLoading(false);
    } catch (error) {
      console.log((error as Error).message);
      setLoading(false);
    }
  };

  return (
    <>
      <Page>
        <Header
          title="Notificaciones Masivas"
          subtitle={tokens.length ? `${tokens.length} clientes recibiran este mensaje` : ""}
        />

        <Card>
          <FormControl title="Titulo">
            <Input
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              placeholder="¡Felicidades Pariente..."
            />
          </FormControl>
          <FormControl title="Descripción">
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
              placeholder="Has ganado 100 taquipesos..."
            />
          </FormControl>
          <Button disabled={!tokens.length || loading} loading={loading} onClick={() => broadcastMessage()}>
            Enviar
          </Button>
        </Card>
      </Page>
    </>
  );
}
