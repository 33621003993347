import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {getDatabase, ref} from "firebase/database";
import Setup from "./Setup";

export const app = initializeApp(Setup.firebase.config);
export const dbRef = getDatabase(app);
export const makeRef = (path: string) => ref(dbRef, "v1/" + path);
export const authRef = getAuth(app);
export const api = Setup.firebase.api;
