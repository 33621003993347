import dayjs from "dayjs";
import React from "react";
import business from "../../data/Business";
import {OrderInvoice} from "../../interface";
import {joinClasses} from "../../ui/utils/Utils";
import {orderStatusLabel} from "../../utils/Utils";

interface Props {
  data: OrderInvoice;
  kitchen?: boolean;
}

export default function TicketHeader({data, kitchen}: Props) {
  return (
    <div className="!text-black">
      <div
        className={joinClasses([
          "flex-col justify-center items-center hidden",
          kitchen ? "print:none" : "print:flex",
        ])}
      >
        <img alt="" src="/assets/brand.svg" className="h-[50px] w-auto mb-4" />
        <div className="text-sm leading-tight">
          {business.business.legal_name} — {data.restaurant.name}
        </div>
        <div className="text-sm leading-tight">{business.business.phone}</div>
      </div>
      <div className="mt-2">
        <h5 className="print:text-base">Pedido #{data.shortId}</h5>
        <div
          className={joinClasses([
            "text-sm flex items-center leading-tight",
            kitchen ? "print:hidden" : "",
          ])}
        >
          <div>
            {data.status === "cancelled" ? (
              <span className="ri-close-circle-fill text-red-600 text-xl" />
            ) : data.status === "cooking" ? (
              <span className="ri-fire-fill text-red-600 text-xl" />
            ) : data.status === "completed" ? (
              <span className="ri-checkbox-circle-fill text-green-600 text-xl" />
            ) : data.status === "pending" ? (
              <span className="ri-time-fill text-yellow-600 text-xl" />
            ) : data.status === "ready" ? (
              <span className="ri-flashlight-fill text-green-600 text-xl" />
            ) : (
              <span className="ri-question-line text-red-400 text-xl" />
            )}
          </div>
          {orderStatusLabel(data.status)}
        </div>
        <div className="flex">
          <p className="leading-tight mr-4">
            <span className="ri-time-line text-gray-600 text-lg align-middle print:hidden" />
            <span className="leading-none text-xs ml-1">
              {dayjs(data.timeRequested).format("DD/MMM/YYYY- HH:mm")}
            </span>
          </p>
          <p className="leading-tight">
            <span className="ri-router-line text-gray-600 text-lg align-middle print:hidden" />{" "}
            <span className="leading-none text-xs">
              {data.source === "app"
                ? "App"
                : data.source === "pos"
                ? "Punto de Venta"
                : data.source === "web"
                ? "Sitio Web"
                : "Desconocido"}
            </span>
          </p>
        </div>
        <p className="text-xxs leading-none print:hidden">
          <span className="ri-bug-2-fill text-gray-600 text-sm align-middle" />{" "}
          {data.id}
        </p>
      </div>
    </div>
  );
}
