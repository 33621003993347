import dayjs from "dayjs";
import {
  endAt,
  off,
  onValue,
  orderByChild,
  query,
  startAt,
} from "firebase/database";
import React, {useEffect, useRef, useState} from "react";
import useSound from "use-sound";
import OrderDetails from "../components/OrderDetails";
import OrderItem from "../components/OrderItem";
import Page from "../components/Page";
import RestaurantSelect from "../components/RestaurantSelect";
import {InitOrderInvoice} from "../data/InitData";
import {makeRef} from "../Firebase";
import {OrderInvoice} from "../interface";
import Modal from "../ui/modal/Modal";
import {sorter} from "../utils/Utils";
import useCurrentRestaurant from "../hooks/useCurrentRestaurant";
import {useAppSelector} from "../redux/store";

export default function Orders() {
  const restaurant = useCurrentRestaurant();
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const currentTime = useRef(dayjs().valueOf()).current;
  const [data, setData] = useState<OrderInvoice[]>([]);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<OrderInvoice | null>(null);
  const [play, {stop}] = useSound("/assets/sound.mp3", {volume: 0.5});

  useEffect(() => {
    if (!restaurants.length) return;
    // stop getting orders from other restaurants
    restaurants
      .filter((a) => a.id !== restaurant?.id)
      .map((item) => {
        const ref = makeRef(`orders/${item.id}`);
        off(ref);
        return item;
      });
    // subscribe to orders from current restaurant
    if (!restaurant?.id) return;
    const ref = makeRef(`orders/${restaurant.id}`);
    const startTime = dayjs().startOf("day").subtract(1, "day").valueOf();
    const endTime = dayjs().endOf("day").valueOf();
    const queryRef = query(
      ref,
      orderByChild("timeRequested"),
      startAt(startTime),
      endAt(endTime)
    );
    onValue(queryRef, (snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.exists());
        const list: OrderInvoice[] = [];
        snapshot.forEach((item) => {
          const value: OrderInvoice = item.val();
          list.push({...InitOrderInvoice, ...value, id: item.key});
          if (
            value.timeRequested >= currentTime &&
            value.status === "pending"
          ) {
            play();
          } else {
            stop();
          }
        });
        setData(list);
      } else {
        setData([]);
      }
    });
    console.log('-- finished loading orders')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurants, restaurant]);

  return (
    <>
      <Page>
        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <h3>Pedidos</h3>
          <div className="flex w-full">
            <RestaurantSelect hideUnpublished />
          </div>
        </div>
        <div className="mb-6">
          <h4>Nuevos</h4>
          <div className="mt-4">
            {data.filter((a) => a.status === "pending").length > 0 ? (
              data
                .filter((a) => a.status === "pending")
                .map((item, index) => (
                  <button
                    key={`order-${item.id}-${index}`}
                    className="w-full hover:cursor-pointer"
                    onClick={() => {
                      setOrder(item);
                      setOpen(true);
                    }}
                  >
                    <OrderItem data={item} />
                  </button>
                ))
            ) : (
              <EmptyItem />
            )}
          </div>
        </div>
        <div className="mb-6">
          <h4>En Preparación</h4>
          <div className="mt-4">
            {data.filter((a) => a.status === "cooking").length > 0 ? (
              data
                .filter((a) => a.status === "cooking")
                .map((item, index) => (
                  <button
                    key={`order-${item.id}-${index}`}
                    className="w-full hover:cursor-pointer"
                    onClick={() => {
                      setOrder(item);
                      setOpen(true);
                    }}
                  >
                    <OrderItem data={item} />
                  </button>
                ))
            ) : (
              <EmptyItem />
            )}
          </div>
        </div>
        <div className="mb-6">
          <h4>Listos para Entregar</h4>
          <div className="mt-4">
            {data.filter((a) => a.status === "ready").length > 0 ? (
              data
                .filter((a) => a.status === "ready")
                .map((item, index) => (
                  <button
                    key={`order-${item.id}-${index}`}
                    className="w-full hover:cursor-pointer"
                    onClick={() => {
                      setOrder(item);
                      setOpen(true);
                    }}
                  >
                    <OrderItem data={item} />
                  </button>
                ))
            ) : (
              <EmptyItem />
            )}
          </div>
        </div>
        <div>
          <h4>Completados (24hrs)</h4>
          <div className="mt-4">
            {data.filter((a) => a.status === "completed").length > 0 ? (
              sorter(
                data.filter((a) => a.status === "completed"),
                "-timeRequested"
              ).map((item, index) => (
                <button
                  key={`order-${item.id}-${index}`}
                  className="w-full hover:cursor-pointer"
                  onClick={() => {
                    setOrder(item);
                    setOpen(true);
                  }}
                >
                  <OrderItem data={item} />
                </button>
              ))
            ) : (
              <EmptyItem />
            )}
          </div>
        </div>
      </Page>
      <Modal key={order?.shortId} open={open} onClose={() => setOpen(false)}>
        <OrderDetails
          data={order}
          onClose={() => {
            setOpen(false);
            setOrder(null);
          }}
        />
      </Modal>
    </>
  );
}

const EmptyItem = () => (
  <div className="p-4 bg-gray-100 bg-opacity-20">
    <p className="text-gray-400">Sin pedidos</p>
  </div>
);
