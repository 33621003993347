import React from "react";
import { Link, useLocation } from "react-router-dom";
import Setup from "../Setup";
import { joinClasses } from "../ui/utils/Utils";
import useAllowedRoutes from "../hooks/useAllowedRoutes";

export default function Sidebar() {
  const routes = useAllowedRoutes();
  const { pathname } = useLocation();

  return (
    <nav className={"sidebar"}>
      <img src={Setup.assets.brand} className="h-12 w-12 m-2" alt="" />
      <div className="sidebar-content">
        {routes
          .filter((a) => a.sidebar)
          .map((route, index) => {
            const active = pathname === route.path;
            return (
              <Link
                to={route.path}
                key={`route-${index}`}
                className={joinClasses([
                  "border-l-2 w-full h-16 flex items-center justify-center hover:bg-primary-100 hover:bg-opacity-30",
                  active ? "border-primary-500 bg-primary-100 bg-opacity-20" : "border-transparent",
                ])}
              >
                <span
                  className={joinClasses([
                    `ri-${route.icon}-${active ? "fill" : "line"} text-2xl`,
                    active ? "text-primary-500" : "",
                  ])}
                />
              </Link>
            );
          })}
      </div>
    </nav>
  );
}
