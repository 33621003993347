import React, { useCallback, useEffect, useState } from "react";
import { get, onValue, update } from "firebase/database";
import { useLocation } from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import InputSearch from "../components/InputSearch";
import OrderDetails from "../components/OrderDetails";
import Page from "../components/Page";
import { InitCustomer, InitOrderInvoice } from "../data/InitData";
import { makeRef } from "../Firebase";
import { Customer, Dish, OrderInvoice } from "../interface";
import { RowItemAvatarName, RowItemChip } from "../tables/TableAtoms";
import Table from "../ui/tables/Table";
import {
  deliveryMethodLabel,
  paymentMethodLabel,
  orderStatusLabel,
  sorter,
  groupArrayBy,
} from "../utils/Utils";
import Modal from "../ui/modal/Modal";
import dayjs from "dayjs";
import Setup from "../Setup";

export default function CustomerDetails() {
  const { pathname } = useLocation();
  const [state, setState] = useState<Customer>(InitCustomer);
  const [order, setOrder] = useState<OrderInvoice | null>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState<OrderInvoice[]>([]);
  const [favorites, setFavorites] = useState<{ dish: Dish; qty: number }[]>([]);

  const getCustomer = useCallback(async (uid?: string) => {
    if (!uid) return setState(InitCustomer);
    const dbRef = makeRef(`customers/${uid}`);
    onValue(dbRef, (snapshot) => {
      setState({
        ...InitCustomer,
        ...snapshot.val(),
        id: uid,
      });
    });
  }, []);

  const getOrders = useCallback(async (customerId?: string) => {
    if (!customerId) return setOrders([]);
    const dbRef = makeRef(`invoices/${customerId}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const list = [] as OrderInvoice[];
      snapshot.forEach((item) => {
        list.push({
          ...InitOrderInvoice,
          ...item.val(),
          id: item.key,
        });
      });
      setOrders(sorter(list, "timeRequested").reverse());
      const items = list.map((a) => a.items);
      const dishes = !!items ? items.flat(1).map((a) => a.dish) : [];
      const grouped = !!dishes ? groupArrayBy(dishes, (a) => a.id) : [];
      const entries = !!grouped ? Object.entries(grouped) : [];
      const favs = !!entries
        ? entries
            .map(([key, values]) => {
              const dish = dishes.find((a) => a.id === key);
              if (dish?.id) return { dish, qty: values.length };
              return null;
            })
            .filter((a) => !!a?.dish)
        : [];
      setFavorites(favs as any);
    } else {
      setOrders([]);
    }
  }, []);

  useEffect(() => {
    const path = pathname.split("/");
    const customerId = path[path.length - 1];
    getCustomer(customerId);
    getOrders(customerId);
  }, [pathname, getCustomer, getOrders]);

  const removeRewards = () => {
    const ref = makeRef(`customers/${state.id}`);
    update(ref, { rewards: 0 });
  };

  return (
    <>
      <Page>
        <Header
          title={"Cliente"}
          subtitle={state.hasProfile ? `${state.name} ${state.lastname}` : state.phone}
          goBack
        />
        <Card title="Detalles" className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex">
              <img
                className="h-32 w-32 bg-gray-300 aspect-square"
                src={state.avatar ? state.avatar : Setup.assets.fallback}
                alt=""
              />
              <div className="flex-1 ml-3">
                <h4>
                  {state.name} {state.lastname}
                </h4>
                <p>{state.phone}</p>
                <p>{state.email}</p>

                <p className="text-xxs leading-none mt-3">
                  <span className="ri-router-fill text-gray-600 text-sm align-middle" />{" "}
                  {state.notificationsToken}
                </p>
                <p className="text-xxs leading-none">
                  <span className="ri-bug-2-fill text-gray-600 text-sm align-middle" /> {state.id}
                </p>
              </div>
            </div>
            <div className="text-left md:text-right">
              <p>Puntos</p>
              <div className="inline-block">
                <button
                  className="inline-block text-xs px-3 py-1 rounded-full bg-red-200 text-red-900"
                  onClick={() => removeRewards()}
                >
                  Eliminar Puntos
                </button>
                <h2 className="inline-block">{Number(state.rewards ?? 0).toFixed(2)}</h2>
              </div>
              <p>{state.purchases} compras</p>
              <p className="text-xs text-gray-500">Total ${state.totalSpent.toFixed(2)}</p>
              <RowItemChip
                value={!!state.notificationsToken}
                labelTrue={"Recibe Notificaciones"}
                labelFalse={"Notificaciones Deshabilitadas"}
              />
            </div>
          </div>
        </Card>
        <Card title="Favoritos" subtitle={"En base a los pedidos recientes"} className="mt-4">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
            {favorites.slice(0, 3).map(({ dish, qty }, index) => {
              return (
                <div className="flex p-4 bg-gray-100" key={`fav-${index}`}>
                  <img
                    src={dish.media.length ? dish.media[0] : Setup.assets.fallback}
                    className="h-16 w-16 aspect-square bg-gray-300"
                    alt=""
                  />
                  <div className="flex-1 ml-3">
                    <h5>{dish.name}</h5>
                    <p>Ordenado {qty} veces</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
        <div className="grid grid-cols-1 md:grid-cols-3 mb-2 mt-4">
          <InputSearch
            value={search}
            onChange={setSearch}
            onClear={() => setSearch("")}
            placeholder={"Buscar por ID"}
          />
        </div>
        <Table
          columns={["Detalle", "Fecha", "Entrega", "Platillos", "Total", "Método", "Status"]}
          data={orders
            .filter((a) => (search.length ? a.id?.toLowerCase().includes(search.toLowerCase()) : a))
            .map((a) => [
              <RowItemAvatarName
                media={a.customer.avatar}
                name={`Pedido #${a.shortId}`}
                description={`${a.customer.name} ${a.customer.lastname}`}
              />,
              dayjs(a.timeRequested).format("MM/DD/YY — hh:mmA"),
              deliveryMethodLabel(a.deliveryMethod),
              a.items.length,
              `$${a.paymentTotal}`,
              paymentMethodLabel(a.paymentMethod),
              orderStatusLabel(a.status),
              a,
            ])}
          onClick={(index, value) => {
            setOrder(orders[index]);
            setOpen(true);
          }}
        />
      </Page>

      <Modal key={order?.shortId} open={open} onClose={() => setOpen(false)}>
        <OrderDetails
          data={order}
          onClose={() => {
            setOpen(false);
            setOrder(null);
          }}
          noActions
        />
      </Modal>
    </>
  );
}
