import dayjs from "dayjs";

const Setup = {
  creation: dayjs("01-01-2021").startOf("day").valueOf(),
  business: {
    name: process.env.REACT_APP_TITLE,
    phone: process.env.REACT_APP_TITLE,
    email: process.env.REACT_APP_TITLE,
    site: process.env.PUBLIC_URL,
  },
  assets: {
    brand: "/assets/brand.png",
    fallback: "/assets/fallback.png",
  },
  firebase: {
    config: {
      apiKey: "AIzaSyAG2OwM6DsJ7eXdIJgEzA9-fymwc5f87wA",
      authDomain: "taqueria-el-pariente.firebaseapp.com",
      databaseURL: "https://taqueria-el-pariente-default-rtdb.firebaseio.com",
      projectId: "taqueria-el-pariente",
      storageBucket: "taqueria-el-pariente.appspot.com",
      messagingSenderId: "926263681552",
      appId: "1:926263681552:web:f13ba0136d223d50c96095",
      measurementId: "G-EP8X7LTJX4",
    },
    api: "https://us-central1-taqueria-el-pariente.cloudfunctions.net/api",
  },
};

export default Setup;
