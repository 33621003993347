import {useAppSelector} from "../redux/store";
import useCurrentUser from "./useCurrentUser";

export default function useCurrentRestaurant() {
  const user = useCurrentUser();
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const restaurantId = useAppSelector((state) => state.database.restaurantId);

  const hasUserRestaurantId = !!user?.restaurantId;
  const restaurant = restaurants.find((a) =>
    hasUserRestaurantId ? a.id === user.restaurantId : a.id === restaurantId
  );

  return restaurant;
}
