import React, {useState} from "react";
import {Crop} from "react-image-crop";
import ImageCropper from "./ImageCropper";
import Button from "../ui/button/Button";
import Modal from "../ui/modal/Modal";

interface Props {
  open: boolean;
  onConfirm: (image: any) => void;
  onClose: () => void;
  options?: Crop;
}

export default function MediaModal({open, onClose, onConfirm, options}: Props) {
  const [resetIdx, setResetIdx] = useState(0);
  const [image, setImage] = useState<any>();

  return (
    <Modal open={open} onClose={onClose} closeable={false}>
      <div className="p-6">
        <div className="flex items-center justify-between relative">
          <h1 className="font-medium text-lg">Seleccionar Imagen</h1>
        </div>
        <div className="mt-4">
          <ImageCropper
            key={resetIdx}
            onClear={() => {
              setResetIdx(resetIdx + 1);
              setImage(undefined);
            }}
            onImageChange={setImage}
            options={options}
          />
        </div>
        <div className="mt-4">
          <div className="flex items-center justify-end">
            <Button kind="secondary" className="mr-auto" onClick={onClose}>
              Cancelar
            </Button>
            <Button kind="success" onClick={() => onConfirm(image)}>
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
