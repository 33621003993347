import React from "react";
import {Enhancer, Size} from "..";
import {joinClasses} from "../utils/Utils";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerSize?: Size;
  error?: boolean;
  startEnhancer?: Enhancer;
  endEnhancer?: Enhancer;
  containerClassName?: HTMLDivElement["className"];
}

export default function Input({
  error,
  startEnhancer,
  endEnhancer,
  containerSize,
  containerClassName,
  disabled,
  ...props
}: Props) {
  const sizes = {
    xs: "input-xs",
    sm: "input-sm",
    md: "input-md",
    lg: "input-lg",
    xl: "input-xl",
  } as {[s in Size]: string};

  const styles = joinClasses([
    "px-3 max-w-3xl input",
    sizes[containerSize ?? "md"],
    error ? "input-error" : disabled ? "input-disabled" : "",
    containerClassName ?? ""
  ]);

  const inputStyles = joinClasses([
    "w-full bg-transparent",
    sizes[containerSize ?? "md"],
    props.className ?? "",
    !!startEnhancer || !!endEnhancer ? "px-2" : ""
  ]);

  return (
    <div className={styles}>
      {startEnhancer ?? <></>}
      <div className="flex-1">
        <input
          {...props}
          className={inputStyles}
          placeholder={props.placeholder ?? "placeholder"}
        />
      </div>
      {endEnhancer ?? <></>}
    </div>
  );
}
