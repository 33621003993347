import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactCrop, {Crop} from "react-image-crop";
import {FileUploader} from "baseui/file-uploader";
import ErrorAlert from "../ui/alert/ErrorAlert";
import "react-image-crop/dist/ReactCrop.css";

interface Props {
  options?: Crop;
  onClear: () => void;
  onImageChange: (value: any) => void;
}

const defaultOptions: Crop = {
  unit: "%",
  width: 1200,
  aspect: 1,
  x: 0,
  y: 0,
  height: 0,
};

export default function ImageCropper(props: Props) {
  const imgRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [uploaded, setUploaded] = useState<string | ArrayBuffer | null | any>(
    null
  );
  const [options, setOptions] = useState(
    props.options ? props.options : defaultOptions
  );
  const [completed, setCompleted] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const onDropFiles = (accepted: File[], rejected: File[]) => {
    setLoading(true);
    if (accepted.length) {
      let file = accepted.find((a) => a);
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", () => setUploaded(reader.result));
        reader.readAsDataURL(file);
        setLoading(false);
      } else {
        setUploaded(null);
        setErrors("No fue posible editar la imagen, intenta de nuevo.");
      }
    }
    if (rejected.length) {
      setUploaded(null);
    }
  };

  const onLoadImage = useCallback((image) => {
    imgRef.current = image;
  }, []);

  const makeCrop = useCallback(() => {
    if (!completed || !canvasRef.current || !imgRef.current) return;
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const cropped = completed;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const context = canvas.getContext("2d");
    const pixelRatio = 2;

    canvas.width = cropped.width * pixelRatio;
    canvas.height = cropped.height * pixelRatio;

    if (context) {
      context.imageSmoothingEnabled = true;
      context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      context.imageSmoothingQuality = "high";

      context.drawImage(
        image,
        cropped.x * scaleX,
        cropped.y * scaleY,
        cropped.width * scaleX,
        cropped.height * scaleY,
        0,
        0,
        cropped.width,
        cropped.height
      );
      props.onImageChange(canvas.toDataURL("image/jpeg"));
    }
  }, [completed, props]);

  useEffect(() => {
    makeCrop();
  }, [makeCrop]);

  if (loading) return <div>Cargando...</div>;
  return (
    <div className="w-full">
      {!uploaded ? (
        <FileUploader
          accept={"image/*"}
          multiple={false}
          onDrop={onDropFiles}
        />
      ) : (
        <></>
      )}
      <div className="mt-4 flex flex-col w-full">
        <div className="rounded-xl overflow-hidden">
          <ReactCrop
            src={uploaded}
            onImageLoaded={onLoadImage}
            crop={options}
            onChange={(e) => setOptions(e)}
            onComplete={(e) => setCompleted(e)}
            ruleOfThirds
          />
        </div>
        <ErrorAlert message={errors} />
      </div>
      <div className="absolute hidden">
        <canvas
          ref={canvasRef}
          style={{
            marginTop: "1rem",
            width: Math.round(options.width ?? 0),
            height: Math.round(options.height ?? 0),
          }}
        />
      </div>
    </div>
  );
}
