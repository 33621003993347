import React from "react";
import {OrderInvoice} from "../../interface";

interface Props {
  data: OrderInvoice;
  kitchen?: boolean;
}
export default function TicketTotals({data, kitchen}: Props) {
  return (
    <div className={kitchen ? "print:hidden" : ""}>
      <div className="flex items-center justify-between">
        <p className="print:text-xs">Subtotal</p>
        <p className="print:text-xs">${Number(data.paymentSubtotal ?? 0)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="print:text-xs">Servicio</p>
        <p className="print:text-xs">
          ${Number(data.paymentTip ?? 0).toFixed(2)}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="print:text-xs">Entrega</p>
        <p className="print:text-xs">
          ${Number(data.deliveryFee ?? 0).toFixed(2)}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="font-medium">Total</p>
        <p className="font-medium">
          ${Number(data.paymentTotal ?? 0).toFixed(2)}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="print:text-xs">Puntos</p>
        <p className="print:text-xs">
          {Number(data.paymentRewards ?? 0).toFixed(2)}
        </p>
      </div>
    </div>
  );
}
