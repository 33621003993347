import React from "react";
import {OrderInvoice} from "../../interface";
import TicketCustomer from "./TicketCustomer";
import TicketDelivery from "./TicketDelivery";
import TicketDishes from "./TicketDishes";
import TicketFooter from "./TicketFooter";
import TicketHeader from "./TicketHeader";
import TicketPayment from "./TicketPayment";
import TicketTotals from "./TicketTotals";

interface Props {
  data: OrderInvoice;
  toggleModal: () => void;
  kitchen?: boolean;
}

export default function Ticket({data, toggleModal, kitchen}: Props) {
  return (
    <div className="p-4 print:!text-black">
      <TicketHeader data={data} kitchen={kitchen} />
      <TicketCustomer data={data} kitchen={kitchen} />
      <TicketDelivery data={data} kitchen={kitchen} />
      <TicketDishes data={data} />
      <TicketTotals data={data} kitchen={kitchen} />
      <TicketPayment data={data} kitchen={kitchen} toggleModal={toggleModal} />
      <TicketFooter data={data} kitchen={kitchen} />
    </div>
  );
}
