import React, {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {endAt, onValue, orderByChild, query, startAt} from "firebase/database";
import CalendarButton from "../components/CalendarButton";
import Header from "../components/Header";
import InputSearch from "../components/InputSearch";
import ModalCalendar from "../components/ModalCalendar";
import OrderDetails from "../components/OrderDetails";
import Page from "../components/Page";
import {InitOrderInvoice} from "../data/InitData";
import {makeRef} from "../Firebase";
import {OrderInvoice} from "../interface";
import {RowItemAvatarName, RowItemChip} from "../tables/TableAtoms";
import Modal from "../ui/modal/Modal";
import Table from "../ui/tables/Table";
import {joinClasses} from "../ui/utils/Utils";
import {
  deliveryMethodLabel,
  findQueryText,
  orderStatusLabel,
  paymentMethodLabel,
  paymentStatusLabel,
} from "../utils/Utils";
import {Parser, transforms} from "json2csv";
import RestaurantSelect from "../components/RestaurantSelect";
import useCurrentRestaurant from "../hooks/useCurrentRestaurant";
import colors from "tailwindcss/colors";
import CardWithDonut from "../components/ChartWithDonut";
import Card from "../components/Card";

const chartColors = [
  colors.green[500],
  colors.blue[500],
  colors.yellow[500],
  colors.orange[500],
  colors.purple[500],
];

export default function History() {
  const restaurant = useCurrentRestaurant();
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState<Date[]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("day").toDate(),
  ]);
  const [data, setData] = useState<OrderInvoice[]>([]);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<OrderInvoice | null>(null);
  const [search, setSearch] = useState("");

  const getOrders = useCallback(() => {
    const ref = makeRef(`orders/${restaurant?.id}`);
    const queryRef = query(
      ref,
      orderByChild("timeRequested"),
      startAt(dayjs(date[0]).valueOf()),
      endAt(dayjs(date[1]).valueOf())
    );
    onValue(
      queryRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const list = [] as OrderInvoice[];
          snapshot.forEach((item) => {
            list.push({
              ...InitOrderInvoice,
              ...item.val(),
              id: item.key,
            });
          });
          setData(list.reverse());
        } else {
          setData([]);
        }
      },
      {onlyOnce: true}
    );
  }, [date, restaurant?.id]);

  useEffect(() => {
    if (date.length === 2) {
      getOrders();
    }
  }, [date, getOrders]);

  const values = data.filter((a) =>
    findQueryText(
      `${a.shortId}-${a.customer.name}-${a.customer.lastname}`,
      search
    )
  );

  const dataStatus = (
    ["card", "cash", "courtesy", "rewards"] as OrderInvoice["paymentMethod"][]
  ).map((method, index) => {
    const value = data.filter((a) => a.paymentMethod === method).length;
    return {
      title: paymentMethodLabel(method),
      value,
      caption: "pedidos",
      color: chartColors[index % chartColors.length],
    };
  });

  const dataSource = (["app", "pos", "web"] as OrderInvoice["source"][]).map(
    (source, index) => {
      return {
        title:
          source === "app"
            ? "App"
            : source === "pos"
            ? "Punto de Venta"
            : source === "web"
            ? "Sitio Web"
            : "Desconocido",
        value: data.filter((a) => a.source === source).length,
        caption: "pedidos",
        color: chartColors[index % chartColors.length],
      };
    }
  );

  const dataDelivery = (
    ["delivery", "pickup", "table"] as OrderInvoice["deliveryMethod"][]
  ).map((method, index) => {
    return {
      title: deliveryMethodLabel(method),
      value: data.filter((a) => a.deliveryMethod === method).length,
      caption: "pedidos",
      color: chartColors[index % chartColors.length],
    };
  });

  return (
    <>
      <Page>
        <Header title="Reportes">
          <div className="flex-1 mr-2">
            <RestaurantSelect />
          </div>
          <div className="flex">
            <CalendarButton
              label={`${dayjs(Array.isArray(date) ? date[0] : date).format(
                "MM/DD/YYYY"
              )}
              ${
                Array.isArray(date)
                  ? ` — ${dayjs(date[1]).format("MM/DD/YYYY")}`
                  : ""
              }`}
              onClick={() => setOpenDate(true)}
            />
            <button
              onClick={() => convertToCSV(data)}
              disabled={!data.length}
              className={joinClasses([
                "h-10 w-10 aspect-square btn btn-secondary ml-2 bg-black shadow-md",
                !data.length ? "bg-opacity-50 text-opacity-50" : "",
              ])}
            >
              <span className="text-white ri-download-line" />
            </button>
          </div>
        </Header>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Card title="Ventas">
            <ul>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-green-600">Completadas</div>
                <div className="font-bold">
                  {data.filter((a) => a.status === "completed").length}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-red-600">Canceladas</div>
                <div className="font-bold">
                  {data.filter((a) => a.status === "cancelled").length}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Total</div>
                <div className="font-bold">{data.length}</div>
              </li>
            </ul>
          </Card>
          <Card title="Ingresos">
            <ul>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-green-600">Total</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    data.map((a) => a.paymentTotal).reduce((a, b) => a + b, 0)
                  )}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket Promedio</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    data.map((a) => a.paymentTotal).reduce((a, b) => a + b, 0) /
                      data.length
                  )}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket más alto</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(Math.max(...data.map((a) => a.paymentTotal)))}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket más bajo</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(Math.min(...data.map((a) => a.paymentTotal)))}
                </div>
              </li>
            </ul>
          </Card>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
          <CardWithDonut title="Métodos de Pago" data={dataStatus} />
          <CardWithDonut title="Origen de Pedidos" data={dataSource} />
          <CardWithDonut title="Métodos de Entrega" data={dataDelivery} />
        </div>

        <div className="my-6">
          <InputSearch
            value={search}
            onChange={setSearch}
            onClear={() => setSearch("")}
            containerClassName="w-full max-w-md"
          />
        </div>
        <Table
          maxHeight={data.length * 100}
          columns={[
            "Detalle",
            "Fecha",
            "Entrega",
            "Platillos",
            "Total",
            "Método",
            "Pago",
            "Status",
          ]}
          data={values.map((a) => [
            <RowItemAvatarName
              hideMedia
              name={`Pedido #${a.shortId}`}
              description={`${a.customer.name} ${a.customer.lastname}`}
            />,
            dayjs(a.timeRequested).format("DD/MM/YY — hh:mmA"),
            deliveryMethodLabel(a.deliveryMethod),
            a.items.length,
            `$${a.paymentTotal}`,
            paymentMethodLabel(a.paymentMethod),
            <RowItemChip
              value={a.paymentStatus === "paid"}
              labelTrue="Pagado"
              labelFalse={paymentStatusLabel(a.paymentStatus)}
            />,
            <RowItemChip
              value={a.status === "completed"}
              labelTrue="Completado"
              labelFalse={orderStatusLabel(a.status)}
            />,
            a,
          ])}
          onClick={(index, value) => {
            setOrder(values[index]);
            setOpen(true);
          }}
        />
      </Page>
      <ModalCalendar
        open={openDate}
        onChange={setDate}
        onClose={() => setOpenDate(false)}
        value={date}
      />

      <Modal key={order?.shortId} open={open} onClose={() => setOpen(false)}>
        <OrderDetails
          data={order}
          onClose={() => {
            setOpen(false);
            setOrder(null);
          }}
          noActions
        />
      </Modal>
    </>
  );
}

type arg<T> = keyof T;
type CombineAll<T> = T extends {[name in keyof T]: infer Type} ? Type : never;

type PropertyNameMap<T, IncludeIntermediate extends boolean> = {
  [name in keyof T]: T[name] extends object
    ?
        | SubPathsOf<name, T, IncludeIntermediate>
        | (IncludeIntermediate extends true ? name : never)
    : name;
};

type SubPathsOf<
  key extends keyof T,
  T,
  IncludeIntermediate extends boolean
> = `${string & key}.${string & PathsOf<T[key], IncludeIntermediate>}`;
type PathsOf<T, IncludeIntermediate extends boolean = false> = CombineAll<
  PropertyNameMap<T, IncludeIntermediate>
>;

type Field = {
  label: string;
  value: `${arg<OrderInvoice>}` | PathsOf<OrderInvoice>;
};
const convertToCSV = (orders: OrderInvoice[]) => {
  const fields = [
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Short Id",
      value: "shortId",
    },
    {
      label: "Restaurante",
      value: "restaurant.name",
    },
    {
      label: "Entrega",
      value: "deliveryMethod",
    },
    {
      label: "Distance",
      value: "deliveryDistance",
    },
    {
      label: "Precio de Entrega",
      value: "deliveryFee",
    },
    {
      label: "Dirección de Entrega",
      value: "deliveryPlace.address",
    },
    {
      label: "Método de Pago",
      value: "paymentMethod",
    },
    {
      label: "Status del Pago",
      value: "paymentStatus",
    },
    {
      label: "Total",
      value: "paymentTotal",
    },
    {
      label: "Stripe® Ticket",
      value: "paymentInvoice.id",
    },
    {
      label: "Puntos",
      value: "paymentRewards",
    },
    {
      label: "ID del Cliente",
      value: "customer.id",
    },
    {
      label: "Nombre del Cliente",
      value: "customer.name",
    },
    {
      label: "Apellido del Cliente",
      value: "customer.lastname",
    },
    {
      label: "Email",
      value: "customer.email",
    },
    {
      label: "Teléfono",
      value: "customer.phone",
    },
    {
      label: "ID del Platillo",
      value: "items.dish.id",
    },
    {
      label: "Platillo Nombre",
      value: "items.dish.name",
    },
    {
      label: "Platillo Cantidad",
      value: "items.qty",
    },
    {
      label: "Platillo Total",
      value: "items.total",
    },
    {
      label: "ID del Extra",
      value: "items.extras.id",
    },
    {
      label: "Extra Nombre",
      value: "items.extras.name",
    },
    {
      label: "Extra Total",
      value: "items.extras.total",
    },
    {
      label: "Ordenado",
      value: "timeRequested",
    },
    {
      label: "Canal de Venta",
      value: "source",
    },
    {
      label: "Servicio",
      value: "paymentFees",
    },
  ] as Field[];
  const paths = ["items", "items.extras"];
  const transformed = [
    transforms.unwind({
      blankOut: true,
      paths,
    }),
  ];
  const parsed = orders;
  const json2csv = new Parser({fields, transforms: transformed});
  const csv = json2csv.parse(parsed);
  let blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  let link = document.createElement("a");
  if (link.download !== undefined) {
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "reporte.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
