import {useSnackbar} from "baseui/snackbar";
import {remove, update} from "firebase/database";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import SpicyLevel from "../components/SpicyLevel";
import WeekSchedule from "../components/WeekSchedule";
import {InitDish, InitIngredient} from "../data/InitData";
import {makeRef} from "../Firebase";
import {Dish, ErrorKeys, Ingredient, Variation} from "../interface";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Textarea from "../ui/input/Textarea";
import {joinClasses} from "../ui/utils/Utils";
import {v4 as uuidv4} from "uuid";
import {
  convertArrayToObject,
  shouldUploadMedia,
  uniqueArraySet,
  uploadMediaArray,
} from "../utils/Utils";
import Button from "../ui/button/Button";
import Modal from "../ui/modal/Modal";
import Switch from "../ui/input/Switch";
import {useAppSelector} from "../redux/store";

export default function FormIngredient() {
  const ingredients = useAppSelector((state) => state.database.ingredients);
  const variations = useAppSelector((state) => state.database.variations);
  const {pathname} = useLocation();
  const {enqueue} = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [state, setState] = useState<Ingredient>(InitIngredient);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false); // delete modal
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (pathname.includes("/ingredients/")) {
      const path = pathname.split("/");
      const id = path[path.length - 1];
      const match = ingredients.find((d) => d.id === id);
      if (match?.id) {
        setEditing(true);
        setState({
          ...InitIngredient,
          ...match,
        });
      } else {
        setEditing(false);
      }
    } else {
      setEditing(false);
    }
  }, [pathname, ingredients]);

  const handleDelete = async () => {
    if (state.id) {
      try {
        setDeleting(true);
        const inVariations = variations.filter(
          (a) => a.items.filter((b) => b.id === state.id).length
        );
        if (inVariations.length) {
          const updates = inVariations.map((a) => {
            const removed = a.items.filter((b: unknown) => b !== state.id); // when not parsed its a string[] of items
            return {
              ...a,
              items: removed,
            } as Variation;
          });
          const objUpdates = convertArrayToObject(updates, "id");
          const variationsRef = makeRef("variations");
          await update(variationsRef, objUpdates);
        }
        const itemRef = makeRef(`ingredients/${state.id}`);
        await remove(itemRef);
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        window.history.back();
      } catch (error) {
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        setDeleting(false);
      }
    } else {
      setDeleting(false);
    }
  };

  const handleSave = async () => {
    setErrors({});
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido o invalido";
    errs.name = !state.name ? msg : "";
    const hasErrors = Object.values(errs).filter((a) => a.length);
    errs.global = hasErrors.length ? hasErrors[0] : "";
    if (!hasErrors.length) {
      setLoading(true);
      try {
        const id = editing ? state.id : uuidv4();
        const mediaForUpload = state.media.length
          ? state.media.filter((a) => shouldUploadMedia(a))
          : [];
        const media = mediaForUpload.length
          ? await uploadMediaArray(
              mediaForUpload,
              `dishes/${id}`,
              "media",
              "image/jpeg"
            )
          : [];
        const payload = {
          ...InitDish,
          ...state,
          media: state.media.length
            ? state.media.filter((a) => !shouldUploadMedia(a)).concat(media)
            : [],
          salePriceSchedule: state.salePriceSchedule.map((a) =>
            uniqueArraySet(a)
          ),
          id,
        } as Dish;
        const itemRef = makeRef(`ingredients/${id}`);
        await update(itemRef, payload);
        enqueue({message: `${payload.name} se actualizó correctamente.`}, 2000);
        window.history.back();
      } catch (error) {
        console.log(error);
        setErrors({
          global: "Hubo un error al intentar guardar los intenta de nuevo",
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrors(errs);
    }
  };

  return (
    <>
      <Page>
        <Header title={editing ? state.name : "Agregar Ingrediente"} goBack />
        <Card title="Detalles" className="mt-4">
          <FormControl
            title="Nombre del Ingrediente"
            caption={`${state.name.length}/150`}
            error={errors.name}
          >
            <Input
              value={state.name}
              onChange={(e) =>
                setState({...state, name: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Nombre Interno"
            caption={"Ayuda a identificarlo al momento de crear variaciones"}
            error={errors.internal}
          >
            <Input
              value={state.internal}
              onChange={(e) =>
                setState({...state, internal: e.currentTarget.value})
              }
              placeholder={"Para platillo tal..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Descripción"
            caption={`${state.description.length}/300`}
          >
            <Textarea
              value={state.description}
              onChange={(e) =>
                setState({...state, description: e.currentTarget.value})
              }
              placeholder="Describe el platillo..."
              maxLength={300}
            />
          </FormControl>
          <FormControl title="Nivel Picante">
            <SpicyLevel
              value={state.spicy}
              onChange={(level) => setState({...state, spicy: level})}
            />
          </FormControl>
        </Card>
        <Card title="Precio" className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <FormControl title="Precio Regular">
                <Input
                  type="number"
                  step={0.01}
                  value={Number(state.price)}
                  onChange={(e) =>
                    setState({...state, price: e.currentTarget.valueAsNumber})
                  }
                  placeholder="0.00"
                  startEnhancer={<span>$</span>}
                  endEnhancer={<span>MXN</span>}
                  min={0}
                />
              </FormControl>
              <button
                className="flex items-center"
                onClick={() =>
                  setState({...state, showSalePrice: !state.showSalePrice})
                }
              >
                <span
                  className={joinClasses([
                    "text-2xl",
                    state.showSalePrice
                      ? "ri-checkbox-fill"
                      : "ri-checkbox-blank-line",
                  ])}
                />
                <span className="leading-none ml-1">
                  Activar Precio de Oferta
                </span>
              </button>
            </div>
            {state.showSalePrice && (
              <div>
                <FormControl title="Precio en Oferta">
                  <Input
                    type="number"
                    step={0.01}
                    value={Number(state.salePrice)}
                    onChange={(e) =>
                      setState({
                        ...state,
                        salePrice: e.currentTarget.valueAsNumber,
                      })
                    }
                    placeholder="0.00"
                    startEnhancer={<span>$</span>}
                    endEnhancer={<span>MXN</span>}
                    min={0}
                  />
                </FormControl>
              </div>
            )}
          </div>
          {state.showSalePrice && (
            <>
              <hr />
              <h6>Horarios de Oferta</h6>
              <WeekSchedule
                value={state.salePriceSchedule}
                onChange={(value) =>
                  setState({...state, salePriceSchedule: value})
                }
              />
            </>
          )}
        </Card>
        <Card title="Publicación" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Publicado</p>
              <p className="text-xs text-gray-400">
                Muestra el platillo en el sitio web & app móvil.
              </p>
            </div>
            <Switch
              value={state.published}
              onChange={() => {
                setState({
                  ...state,
                  published: !state.published,
                });
              }}
            />
          </div>
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Disponibilidad</p>
              <p className="text-xs text-gray-400">
                Marca el producto como disponible.
              </p>
            </div>
            <Switch
              value={state.available}
              onChange={() => {
                setState({
                  ...state,
                  available: !state.available,
                });
              }}
            />
          </div>
        </Card>
        <div className="mt-4 flex items-center">
          <Button
            kind="success"
            className="mr-auto"
            loading={loading}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
          {editing ? (
            <Button kind="danger" onClick={() => showDeleteModal(true)}>
              Eliminar
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Page>

      <Modal
        open={deleteModal}
        onClose={() => showDeleteModal(false)}
        closeable={false}
      >
        <div className="p-6">
          <h5>Eliminar</h5>
          <hr />
          <p>
            Esta acción no se puede revertir. ¿Deseas realmente eliminar estos
            datos?
          </p>

          <div className="mt-4 flex items-center justify-between">
            <Button
              kind="secondary"
              className="mr-auto"
              onClick={() => showDeleteModal(false)}
              disabled={deleting}
            >
              Cancelar
            </Button>
            <Button
              kind="danger"
              onClick={() => handleDelete()}
              loading={deleting}
              disabled={deleting}
            >
              Eliminar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
