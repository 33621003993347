import React, {forwardRef, useEffect, useState} from "react";
import {useSnackbar} from "baseui/snackbar";
import {useLocation} from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import {InitDish, InitVariation} from "../data/InitData";
import {Dish, ErrorKeys, Ingredient, Variation} from "../interface";
import Button from "../ui/button/Button";
import {joinClasses} from "../ui/utils/Utils";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Textarea from "../ui/input/Textarea";
import {Select} from "baseui/select";
import {List, arrayMove, arrayRemove} from "baseui/dnd-list";
import Modal from "../ui/modal/Modal";
import {makeRef} from "../Firebase";
import {remove, update} from "firebase/database";
import {v4 as uuidv4} from "uuid";
import {convertArrayToObject} from "../utils/Utils";
import Switch from "../ui/input/Switch";
import {useAppSelector} from "../redux/store";

export default function FormVariation() {
  const dishes = useAppSelector((state) => state.database.dishes);
  const variations = useAppSelector((state) => state.database.variations);
  const ingredients = useAppSelector((state) => state.database.ingredients);
  const {pathname} = useLocation();
  const {enqueue} = useSnackbar();

  const [editing, setEditing] = useState(false);
  const [state, setState] = useState<Variation>(InitVariation);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false); // delete modal
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (pathname.includes("/variations/")) {
      setEditing(true);
      const path = pathname.split("/");
      const id = path[path.length - 1];
      const match = variations.find((d) => d.id === id);
      if (match?.id) {
        setState({
          ...InitVariation,
          ...match,
        });
      }
    } else {
      setEditing(false);
      setState(InitVariation);
    }
  }, [pathname, variations]);

  const handleDelete = async () => {
    if (state.id) {
      try {
        setDeleting(true);
        const inDishes = dishes.filter(
          (a) => a.variations.filter((b) => b === state.id).length
        );
        if (inDishes.length) {
          const updates = inDishes.map((a) => {
            const removed = a.variations.filter((b: unknown) => b !== state.id); // when not parsed its a string[] of items
            return {
              ...a,
              variations: removed,
            } as Dish;
          });
          const objUpdates = convertArrayToObject(updates, "id");
          const dishesRef = makeRef("dishes");
          await update(dishesRef, objUpdates);
        }
        const itemRef = makeRef(`variations/${state.id}`);
        await remove(itemRef);
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        window.history.back();
      } catch (error) {
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        setDeleting(false);
      }
    } else {
      setDeleting(false);
    }
  };

  const handleSave = async () => {
    setErrors({});
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido o invalido";
    errs.name = !state.name ? msg : "";
    const hasErrors = Object.values(errs).filter((a) => a.length);
    errs.global = hasErrors.length ? hasErrors[0] : "";
    if (!hasErrors.length) {
      setLoading(true);
      try {
        const id = editing ? state.id : uuidv4();
        const payload = {
          ...InitDish,
          ...state,
          id,
        } as Variation;
        const itemRef = makeRef(`variations/${id}`);
        await update(itemRef, payload);
        enqueue({message: `${payload.name} se actualizó correctamente.`}, 2000);
        window.history.back();
      } catch (error) {
        console.log(error);
        setErrors({
          global: "Hubo un error al intentar guardar los intenta de nuevo",
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrors(errs);
    }
  };

  return (
    <>
      <Page>
        <Header title={editing ? state.name : "Agregar Variación"} goBack />
        <Card title="Detalles" className="mt-4">
          <FormControl
            title="Nombre de la Variación"
            caption={`${state.name.length}/150`}
            error={errors.name}
          >
            <Input
              value={state.name}
              onChange={(e) =>
                setState({...state, name: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Nombre Interno"
            caption={
              "Ayuda a identificar la variación con un nombre unico, tus clientes no podran ver este nombre."
            }
            error={errors.internal}
          >
            <Input
              value={state.internal}
              onChange={(e) =>
                setState({...state, internal: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Descripción"
            caption={`${state.description.length}/300`}
          >
            <Textarea
              value={state.description}
              onChange={(e) =>
                setState({...state, description: e.currentTarget.value})
              }
              placeholder="Describe el platillo..."
              maxLength={300}
            />
          </FormControl>
        </Card>
        <Card title="Ingredientes" className="mt-4">
          <FormControl title="Seleccionar Ingredientes">
            <Select
              options={
                ingredients.filter(
                  (a) => !state.items.find((b: unknown) => b === a.id)
                ) as any[]
              }
              labelKey="name"
              getOptionLabel={({option}: any) => option.name}
              getValueLabel={({option}: any) => option.name}
              maxDropdownHeight={"10rem"}
              onChange={({option}: any) =>
                setState({
                  ...state,
                  items: state.items.concat([option?.id]),
                })
              }
              clearable={false}
              placeholder={"Buscar ingrediente"}
              overrides={{
                DropdownListItem: {
                  component: forwardRef(
                    ({$isHighlighted, item, style, ...props}, ref: any) => {
                      const value = item as Ingredient;
                      return (
                        <div
                          ref={ref}
                          style={style}
                          onClick={props.onClick}
                          className={joinClasses([
                            "w-full text-left p-2 hover:cursor-pointer hover:bg-gray-200 border-b",
                            $isHighlighted ? "bg-gray-200" : "",
                          ])}
                        >
                          <div>{value.name}</div>
                          <div className="text-sm text-gray-400">
                            {value.internal}
                          </div>
                        </div>
                      );
                    }
                  ),
                },
              }}
            />
          </FormControl>
          <List
            removable
            items={state.items.map((a: unknown) =>
              ingredients.find((b) => b.id === a)
            )}
            onChange={({oldIndex, newIndex}) =>
              setState({
                ...state,
                items:
                  newIndex === -1
                    ? arrayRemove(state.items, oldIndex)
                    : arrayMove(state.items, oldIndex, newIndex),
              })
            }
            overrides={{
              Item: {
                style: {
                  borderLeftColor: "rgb(228 228 231)",
                  borderRightColor: "rgb(228 228 231)",
                  borderTopColor: "rgb(228 228 231)",
                  borderBottomColor: "rgb(228 228 231)",
                },
                component: forwardRef(
                  ({$value, children, $isDragged, style}: any, ref: any) => {
                    return (
                      <div
                        ref={ref}
                        style={style}
                        className={joinClasses([
                          "w-full bg-white border-2 py-2 px-4 min-h-[4rem] -mb-1 flex items-center",
                          $isDragged ? "border-black" : "border-gray-200",
                        ])}
                      >
                        {children[0]}
                        <div className="flex-1">
                          <div>
                            {$value.name} — ${$value.price}
                          </div>
                          <div className="text-sm text-gray-400">
                            {$value.internal}
                          </div>
                        </div>
                        {children[2]}
                      </div>
                    );
                  }
                ) as any,
              },
            }}
          />
          <div className="my-8" />
          <h5>Selección</h5>
          <hr />
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Requerido</p>
              <p className="text-xs text-gray-400">
                El cliente deberá elegir 1 opción. La primera opción de la lista
                de ingredientes estará preseleccionado.
              </p>
            </div>
            <Switch
              value={state.required}
              onChange={() => {
                setState({
                  ...state,
                  required: !state.required,
                  multiple: !state.required === true ? false : state.multiple,
                  min: 0,
                  max: state.items.length,
                });
              }}
            />
          </div>
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Multiple</p>
              <p className="text-xs text-gray-400">
                El cliente podrá seleccionar multples opciones.
              </p>
              {state.multiple && (
                <div className="max-w-sm flex mt-2">
                  <Button
                    disabled={state.max === 1}
                    onClick={() => setState({...state, max: state.max - 1})}
                  >
                    <div className="ri-subtract-fill" />
                  </Button>
                  <Input
                    className="text-center"
                    value={`${state.max} / ${state.items.length}`}
                    readOnly
                    error={
                      Number(state.max ?? 0) > Number(state.items.length ?? 0)
                    }
                    containerClassName="text-center justify-center"
                  />
                  <Button
                    disabled={state.max === state.items.length}
                    onClick={() => setState({...state, max: state.max + 1})}
                  >
                    <div className="ri-add-fill" />
                  </Button>
                </div>
              )}
            </div>
            <Switch
              value={state.multiple}
              onChange={() => {
                setState({
                  ...state,
                  multiple: !state.multiple,
                  required: !state.multiple === true ? false : state.required,
                  min: 0,
                  max: state.items.length,
                });
              }}
            />
          </div>
        </Card>
        <Card title="Publicación" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Publicado</p>
              <p className="text-xs text-gray-400">
                Muestra el platillo en el sitio web & app móvil.
              </p>
            </div>
            <Switch
              value={state.published}
              onChange={() => {
                setState({
                  ...state,
                  published: !state.published,
                });
              }}
            />
          </div>
        </Card>
        <div className="mt-4 flex items-center">
          <Button
            kind="success"
            className="mr-auto"
            loading={loading}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
          {editing && (
            <Button kind="danger" onClick={() => showDeleteModal(true)}>
              Eliminar
            </Button>
          )}
        </div>
      </Page>

      <Modal
        open={deleteModal}
        onClose={() => showDeleteModal(false)}
        closeable={false}
      >
        <div className="p-6">
          <h5>Eliminar</h5>
          <hr />
          <p>
            Esta acción no se puede revertir. ¿Deseas realmente eliminar estos
            datos?
          </p>

          <div className="mt-4 flex items-center justify-between">
            <Button
              kind="secondary"
              className="mr-auto"
              onClick={() => showDeleteModal(false)}
              disabled={deleting}
            >
              Cancelar
            </Button>
            <Button
              kind="danger"
              onClick={() => handleDelete()}
              loading={deleting}
              disabled={deleting}
            >
              Eliminar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
