import React from "react";

interface Props {
  title?: string;
  message?: string;
}
export default function ErrorAlert({title, message}: Props) {
  if (!message) return <></>;
  return (
    <div className="w-full my-2">
      <div className="sm:flex sm:items-start bg-red-800 p-3">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <i className="ri-error-warning-line text-2xl text-red-600" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h5 className="text leading-6 font-medium text-white">
            {title ? title : "Lo sentimos"}
          </h5>
          <div>
            <p className="text-xs text-white">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
