import dayjs from "dayjs";
import {
  Category,
  Customer,
  Dish,
  Ingredient,
  KitchenArea,
  OrderInvoice,
  Place,
  Restaurant,
  Settings,
  Table,
  User,
  Variation,
} from "../interface";

export const InitSettings = {
  business: {
    rewardRate: 0,
  },
  offline: {
    app: false,
    website: false,
    admin: false,
  },
  subscription: {
    id: "",
    status: "",
    itemId: "",
    priceId: "",
    productId: "",
    billingAmount: 10,
    billingCycle: "",
    created: dayjs().valueOf(),
    dueDate: dayjs().valueOf(),
    customerId: "",
  },
} as Settings;

export const CountryMX = {
  id: "MX",
  label: "Mexico",
  dialCode: "+52",
} as User["phoneCountry"];
export const InitWeek = [[], [], [], [], [], [], []];
export const InitPlace: Place = {
  id: "",
  name: "",
  address: "",
  latitude: 0,
  longitude: 0,
  placeId: "",
};

export const InitAdministrator: User = {
  id: "",
  name: "",
  lastname: "",
  lastSession: dayjs().valueOf(),
  email: "",
  phone: "",
  phoneCountry: CountryMX,
  avatar: "",
  role: "user",
  restaurants: [],
  notificationToken: "",
};

export const InitRestaurant: Restaurant = {
  id: "",
  name: "",
  phone: "",
  phoneCountry: CountryMX,
  email: "",
  hours: InitWeek,
  holidaysHours: [],
  media: [],
  occupancy: 0,
  tables: 0,
  deliveryCoverage: [],
  deliveryPricing: [],
  published: false,
  enableCardPayments: false,
  enableDelivery: false,
  enableDineIn: false,
  address: InitPlace,
  kitchenAreas: ["general"],
  showComingSoon: false,
  offline: false,
};

export const InitIngredient: Ingredient = {
  id: "",
  name: "",
  internal: "",
  description: "",
  price: 0,
  salePrice: 0,
  showSalePrice: false,
  chargeTax: false,
  media: [],
  sku: "",
  available: false,
  published: false,
  spicy: 0,
  allergyWarning: false,
  salePriceSchedule: InitWeek,
};

export const InitDish: Dish = {
  ...InitIngredient,
  ingredients: [],
  variations: [],
  categories: [],
  hideFromRestaurants: [],
  cookingTime: 1,
  kitchenArea: "general",
};

export const InitVariation: Variation = {
  id: "",
  name: "",
  internal: "",
  description: "",
  multiple: false,
  required: false,
  max: 0,
  min: 0,
  items: [],
  published: false,
};

export const InitCategory: Category = {
  id: "",
  name: "",
  description: "",
  hours: InitWeek,
  holidayHours: [],
  media: [],
  published: false,
};

export const InitCustomer: Customer = {
  id: "",
  hasProfile: false,
  name: "",
  lastname: "",
  email: "",
  phone: "",
  phoneCountry: CountryMX,
  avatar: "",
  joined: dayjs().valueOf(),
  purchases: 0,
  totalSpent: 0,
  rewards: 0,
  lastPurchase: 0,
  notificationsEnabled: true,
  notificationsToken: "",
};

export const InitOrderInvoice: OrderInvoice = {
  id: "",
  shortId: "",
  source: "web",
  status: "pending",
  restaurant: InitRestaurant,
  deliveryStatus: "pending",
  deliveryMethod: "pickup",
  paymentMethod: "cash",
  paymentStatus: "unpaid",
  paymentSubtotal: 0,
  paymentTotal: 0,
  paymentFees: 0,
  paymentTip: 0,
  paymentRewards: 0,
  customer: InitCustomer,
  rating: 0,
  items: [],
  timeRequested: 0,
  timePrepared: 0,
  timeReady: 0,
  timeInRoute: 0,
  timeCompleted: 0,
  timeCancelled: 0,
};

export const InitTable: Table = {
  id: "",
  available: true,
};

export const InitKitchenArea: KitchenArea = {
  id: "",
  name: "",
  description: "",
  published: false,
};
