import React from "react";
import {joinClasses} from "../ui/utils/Utils";
import Setup from "../Setup"

interface RowItemAvatarNameProps {
  name: string;
  media?: string;
  description: string;
  hideMedia?: boolean;
}
export const RowItemAvatarName = ({
  media,
  name,
  description,
  hideMedia,
}: RowItemAvatarNameProps) => {
  return (
    <div className="flex items-center">
      {!hideMedia && (
        <img
          src={media ? media : Setup.assets.fallback}
          alt=""
          className="h-10 w-10 aspect-square mr-2"
        />
      )}
      <div className="flex-1">
        <p className="text-sm font-semibold leading-none">
          {name.length > 30 ? `${name.substring(0, 30)}...` : name}
        </p>
        {!!description && (
          <p className="text-sm leading-none text-gray-400">
            {description.length > 30
              ? `${description.substring(0, 30)}...`
              : description}
          </p>
        )}
      </div>
    </div>
  );
};

interface RowItemChipProps {
  value?: boolean;
  labelTrue?: string;
  labelFalse?: string;
}
export const RowItemChip = ({
  value,
  labelFalse,
  labelTrue,
}: RowItemChipProps) => {
  return (
    <div
      className={joinClasses([
        "mx-auto leading-tight text-center text-xs font-medium px-3 py-1 rounded-full inline-flex justify-center items-center",
        value ? "bg-green-200 text-green-900" : "bg-red-200 text-red-900",
      ])}
    >
      {value ? labelTrue ?? "SI" : labelFalse ?? "NO"}
    </div>
  );
};

interface RowPricePromoProps {
  price: number;
  salePrice: number;
  showSalePrice: boolean;
}
export const RowPricePromo = ({
  price,
  salePrice,
  showSalePrice,
}: RowPricePromoProps) => {
  return (
    <div>
      <div
        className={joinClasses([
          "text-left",
          showSalePrice ? "text-red-700 line-through text-xxs" : "",
        ])}
      >
        ${price}
      </div>
      {showSalePrice && (
        <div className="text-green-600 text-left">${salePrice}</div>
      )}
    </div>
  );
};
