import dayjs from "dayjs";
import React from "react";
import {OrderInvoice} from "../interface";
import {joinClasses} from "../ui/utils/Utils";

interface Props {
  data: OrderInvoice;
}

export default function OrderItem({data}: Props) {
  return (
    <div
      className={joinClasses([
        "!text-black grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 items-center p-4 bg-gray-100 shadow-sm hover:shadow-md mb-1",
        data.status === "pending" ? "bg-red-200" : "",
        data.status === "cooking" ? "bg-orange-200" : "",
        data.status === "ready" ? "bg-green-200" : "",
        data.status === "pending" ? "bg-red-200" : "",
        data.status === "completed" ? "bg-lime-100" : "",
      ])}
    >
      <div className="flex items-center">
        <div className="ml-4 text-left">
          <h5 className="leading-tight">#{data.shortId}</h5>
          <p className="leading-tight">
            {data.customer.name} {data.customer.lastname}
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center leading-tight">
          <span className="ri-phone-fill text-lg mr-2" />
          <span>{data.customer.phone}</span>
        </div>
        <div className="flex items-center leading-tight">
          <span className="ri-time-fill text-lg mr-2" />
          <span>
            {dayjs(data.timeRequested).isBefore(dayjs().startOf("day"))
              ? dayjs(data.timeRequested).format("[ayer] HH:mm")
              : dayjs(data.timeRequested).fromNow()}
          </span>
        </div>
      </div>

      <div>
        <div className="flex items-center leading-tight">
          <span className="ri-restaurant-fill text-lg mr-2" />
          <span>
            Platillos {data.items.length} — ${data.paymentTotal}
          </span>
        </div>
        <div className="flex items-center leading-tight">
          <span
            className={joinClasses([
              "text-lg mr-2",
              data.deliveryMethod === "delivery"
                ? "ri-motorbike-fill"
                : data.deliveryMethod === "pickup"
                ? "ri-walk-fill"
                : "ri-question-fill",
            ])}
          />
          <span>
            {data.deliveryMethod === "pickup" ? "En Sucursal" : "Domicilio"}
          </span>
        </div>
      </div>

      <div>
        <div className="text-sm flex items-center">
          {data.paymentStatus === "paid" ? (
            <span className="ri-checkbox-circle-fill text-lime-600 text-xl" />
          ) : data.paymentStatus === "processing" ? (
            <span className="ri-timer-line text-orange-300 text-xl" />
          ) : data.paymentStatus === "cancelled" ? (
            <span className="ri-close-circle-fill text-red-600 text-xl" />
          ) : data.paymentStatus === "refunded" ? (
            <span className="ri-replay-line text-sky-600 text-xl" />
          ) : data.paymentStatus === "unpaid" ? (
            <span className="ri-time-fill text-yellow-600 text-xl" />
          ) : (
            <span className="ri-question-line text-red-400 text-xl" />
          )}
          <span>
            {data.paymentStatus === "paid"
              ? "Pagado"
              : data.paymentStatus === "processing"
              ? "Procesando"
              : data.paymentStatus === "cancelled"
              ? "Cancelado"
              : data.paymentStatus === "refunded"
              ? "Reembolsado"
              : data.paymentStatus === "unpaid"
              ? "Sin Pagar"
              : "Desconocido"}
          </span>
          <p className="ml-2">
            {data.paymentMethod === "card"
              ? "Tarjeta"
              : data.paymentMethod === "cash"
              ? "Efectivo"
              : data.paymentMethod === "rewards"
              ? "Puntos"
              : data.paymentMethod === "courtesy"
              ? "Cortesia"
              : "Desconocido"}
          </p>
        </div>
        <div className="text-sm flex items-center">
          {data.paymentMethod === "card" &&
            (typeof data.paymentInvoice === "string" ? (
              <h6>Captura manual</h6>
            ) : (
              <>
                {data.paymentInvoice && (
                  <div className="p-1 bg-gray-800 inline-flex items-center">
                    <img
                      src={`/assets/cards/${data.paymentInvoice?.charges.data[0].payment_method_details?.card?.brand}.svg`}
                      className="h-3 w-auto"
                      alt=""
                    />
                    <span className="ml-2 font-semibold text-white leading-tight">
                      {
                        data.paymentInvoice?.charges.data[0]
                          .payment_method_details?.card?.last4
                      }
                    </span>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
