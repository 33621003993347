import React from "react";
import {joinClasses} from "../ui/utils/Utils";

interface Props {
  value: number;
  onChange: (value: number) => void;
}
export default function SpicyLevel({value, onChange}: Props) {
  return (
    <div>
      {new Array(5).fill("spicy").map((item, index) => {
        const active = index < value;
        return (
          <button
            key={`spicy-${index}`}
            onClick={() => onChange(value === index + 1 ? 0 : index + 1)}
            className={joinClasses([
              "h-10 w-10  mr-1 hover:bg-red-100",
              active ? "bg-red-200" : "bg-gray-100",
            ])}
          >
            <div
              className={joinClasses([
                "transition text-2xl hover:text-red-500",
                active
                  ? "ri-fire-fill text-red-700"
                  : "ri-fire-line text-gray-500",
              ])}
            />
          </button>
        );
      })}
    </div>
  );
}
