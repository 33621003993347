import React, {forwardRef, useEffect, useState} from "react";
import {useSnackbar} from "baseui/snackbar";
import {useLocation} from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import {InitDish} from "../data/InitData";
import {Category, Dish, ErrorKeys, Variation} from "../interface";
import Button from "../ui/button/Button";
import {joinClasses} from "../ui/utils/Utils";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Textarea from "../ui/input/Textarea";
import WeekSchedule from "../components/WeekSchedule";
import SpicyLevel from "../components/SpicyLevel";
import {Select} from "baseui/select";
import {List, arrayMove, arrayRemove} from "baseui/dnd-list";
import Modal from "../ui/modal/Modal";
import {makeRef} from "../Firebase";
import {remove, update} from "firebase/database";
import {v4 as uuidv4} from "uuid";
import MediaModal from "../components/MediaModal";
import {
  shouldUploadMedia,
  uniqueArraySet,
  uploadMediaArray,
} from "../utils/Utils";
import Switch from "../ui/input/Switch";
import {useAppSelector} from "../redux/store";

export default function FormDish() {
  const dishes = useAppSelector((state) => state.database.dishes);
  const variations = useAppSelector((state) => state.database.variations);
  const categories = useAppSelector((state) => state.database.categories);
  const {pathname} = useLocation();
  const {enqueue} = useSnackbar();

  const [editing, setEditing] = useState(false);
  const [state, setState] = useState<Dish>(InitDish);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false); // delete modal
  const [deleting, setDeleting] = useState(false);

  const [media, showMedia] = useState(false);

  useEffect(() => {
    if (pathname.includes("/dishes/")) {
      setEditing(true);
      const path = pathname.split("/");
      const id = path[path.length - 1];
      const match = dishes.find((d) => d.id === id);
      if (match?.id) {
        setState({
          ...InitDish,
          ...match,
        });
      }
    } else {
      setEditing(false);
      setState(InitDish);
    }
  }, [pathname, dishes]);

  const handleDelete = async () => {
    if (state.id) {
      try {
        setDeleting(true);
        const itemRef = makeRef(`dishes/${state.id}`);
        await remove(itemRef);
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        window.history.back();
      } catch (error) {
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        setDeleting(false);
      }
    } else {
      setDeleting(false);
    }
  };

  const handleSave = async () => {
    setErrors({});
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido o invalido";
    errs.name = !state.name ? msg : "";
    errs.categories = !state.categories.length
      ? "El platillo debe de pertenecer a una categoria"
      : "";
    errs.kitchen = !state.kitchenArea
      ? "El platillo requiere un area de cocina"
      : "";
    const hasErrors = Object.values(errs).filter((a) => a.length);
    errs.global = hasErrors.length ? hasErrors[0] : "";
    if (!hasErrors.length) {
      setLoading(true);
      try {
        const id = editing ? state.id : uuidv4();
        const mediaForUpload = state.media.length
          ? state.media.filter((a) => shouldUploadMedia(a))
          : [];
        const media = mediaForUpload.length
          ? await uploadMediaArray(
              mediaForUpload,
              `dishes/${id}`,
              "media",
              "image/jpeg"
            )
          : [];
        const payload = {
          ...InitDish,
          ...state,
          media: state.media.length
            ? state.media.filter((a) => !shouldUploadMedia(a)).concat(media)
            : [],
          salePriceSchedule: state.salePriceSchedule.map((a) =>
            uniqueArraySet(a)
          ),
          id,
        } as Dish;
        const itemRef = makeRef(`dishes/${id}`);
        await update(itemRef, payload);
        enqueue({message: `${payload.name} se actualizó correctamente.`}, 2000);
        window.history.back();
      } catch (error) {
        console.log(error);
        setErrors({
          global: "Hubo un error al intentar guardar los intenta de nuevo",
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrors(errs);
    }
  };

  return (
    <>
      <Page>
        <Header title={editing ? state.name : "Agregar Platillo"} goBack />
        <Card title="Galeria">
          <div className="flex flex-wrap">
            {state.media.map((src, idx) => (
              <div className="relative mr-2" key={`media-${idx}`}>
                <img
                  src={src}
                  alt=""
                  className="max-h-40 max-w-[10rem] w-full h-full !aspect-square bg-gray-200"
                />
                <Button
                  size="sm"
                  kind="danger"
                  className="absolute top-0 right-0 !aspect-square h-10"
                  onClick={() => {
                    const updates = state.media.filter((_, i) => i !== idx);
                    setState({...state, media: updates});
                  }}
                >
                  <div className="ri-close-fill text-xl" />
                </Button>
              </div>
            ))}
            {state.media.length < 4 && (
              <button
                className="max-h-40 max-w-[10rem] w-full h-full !aspect-square bg-gray-200"
                onClick={() => showMedia(true)}
              >
                <div className="ri-image-2-line text-5xl text-gray-400" />
              </button>
            )}
          </div>
        </Card>
        <Card title="Detalles" className="mt-4">
          <FormControl
            title="Nombre del Platillo"
            caption={`${state.name.length}/150`}
            error={errors.name}
          >
            <Input
              value={state.name}
              onChange={(e) =>
                setState({...state, name: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Descripción"
            caption={`${state.description.length}/300`}
          >
            <Textarea
              value={state.description}
              onChange={(e) =>
                setState({...state, description: e.currentTarget.value})
              }
              placeholder="Describe el platillo..."
              maxLength={300}
            />
          </FormControl>
          <FormControl title="Nivel Picante">
            <SpicyLevel
              value={state.spicy}
              onChange={(level) => setState({...state, spicy: level})}
            />
          </FormControl>

          <FormControl title="Tiempo de Preparación" caption="Valor en minutos">
            <div className="flex items-center">
              <button
                className="btn btn-md aspect-square btn-primary"
                onClick={() =>
                  setState({...state, cookingTime: state.cookingTime - 5})
                }
                disabled={state.cookingTime === 0}
              >
                <div className="ri-subtract-fill text-xl" />
              </button>
              <Input
                value={state.cookingTime}
                onChange={(e) =>
                  setState({
                    ...state,
                    cookingTime: e.currentTarget.valueAsNumber,
                  })
                }
                containerClassName="max-w-xs mx-2"
                className="text-center"
                type={"number"}
                min={0}
                step={5}
              />
              <button
                className="btn btn-md aspect-square btn-primary"
                onClick={() =>
                  setState({...state, cookingTime: state.cookingTime + 5})
                }
              >
                <div className="ri-add-fill text-xl" />
              </button>
            </div>
          </FormControl>
        </Card>
        <Card title="Precio" className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <FormControl title="Precio Regular">
                <Input
                  type="number"
                  step={0.01}
                  value={Number(state.price)}
                  onChange={(e) =>
                    setState({...state, price: e.currentTarget.valueAsNumber})
                  }
                  placeholder="0.00"
                  startEnhancer={<span>$</span>}
                  endEnhancer={<span>MXN</span>}
                  min={0}
                />
              </FormControl>
              <button
                className="flex items-center"
                onClick={() =>
                  setState({...state, showSalePrice: !state.showSalePrice})
                }
              >
                <span
                  className={joinClasses([
                    "text-2xl",
                    state.showSalePrice
                      ? "ri-checkbox-fill"
                      : "ri-checkbox-blank-line",
                  ])}
                />
                <span className="leading-none ml-1">
                  Activar Precio de Oferta
                </span>
              </button>
            </div>
            {state.showSalePrice && (
              <div>
                <FormControl title="Precio en Oferta">
                  <Input
                    type="number"
                    step={0.01}
                    value={Number(state.salePrice)}
                    onChange={(e) =>
                      setState({
                        ...state,
                        salePrice: e.currentTarget.valueAsNumber,
                      })
                    }
                    placeholder="0.00"
                    startEnhancer={<span>$</span>}
                    endEnhancer={<span>MXN</span>}
                    min={0}
                  />
                </FormControl>
              </div>
            )}
          </div>
          {state.showSalePrice && (
            <>
              <hr />
              <h6>Horarios de Oferta</h6>
              <WeekSchedule
                value={state.salePriceSchedule}
                onChange={(value) =>
                  setState({...state, salePriceSchedule: value})
                }
              />
            </>
          )}
        </Card>
        <Card title="Categorias" className="mt-4">
          <FormControl title="Categorias">
            <Select
              valueKey="name"
              options={categories as any[]}
              getOptionLabel={({option}: any) => option.name}
              getValueLabel={({option}: any) => option.name}
              maxDropdownHeight={"10rem"}
              onChange={({option}: any) =>
                setState({
                  ...state,
                  categories: state.categories.concat([option?.id]),
                })
              }
              clearable={false}
              placeholder={"Buscar categoria"}
              overrides={{
                DropdownListItem: {
                  component: forwardRef(
                    ({$isHighlighted, item, style, ...props}, ref: any) => {
                      const value = item as Category;
                      return (
                        <div
                          ref={ref}
                          style={style}
                          onClick={props.onClick}
                          className={joinClasses([
                            "w-full text-left p-2 hover:cursor-pointer hover:bg-gray-200 border-b",
                            $isHighlighted ? "bg-gray-200" : "",
                          ])}
                        >
                          <div>{value.name}</div>
                        </div>
                      );
                    }
                  ),
                },
              }}
            />
          </FormControl>
          <List
            items={
              state.categories.length
                ? state.categories.map(
                    (a) => categories.find((b) => b.id === a)?.name ?? ""
                  )
                : []
            }
            removable
            overrides={{
              Item: {
                style: {
                  borderLeftColor: "rgb(228 228 231)",
                  borderRightColor: "rgb(228 228 231)",
                  borderTopColor: "rgb(228 228 231)",
                  borderBottomColor: "rgb(228 228 231)",
                },
              },
            }}
            onChange={({oldIndex, newIndex}) =>
              setState({
                ...state,
                categories:
                  newIndex === -1
                    ? arrayRemove(state.categories, oldIndex)
                    : arrayMove(state.categories, oldIndex, newIndex),
              })
            }
          />
        </Card>
        <Card title="Modificadores" className="mt-4">
          <FormControl title="Seleccionar Variaciones">
            <Select
              options={
                variations.filter(
                  (a) => !state.variations.find((b) => b === a.id)
                ) as any[]
              }
              labelKey="name"
              getOptionLabel={({option}: any) => option.name}
              getValueLabel={({option}: any) => option.name}
              maxDropdownHeight={"10rem"}
              onChange={({option}: any) =>
                setState({
                  ...state,
                  variations: state.variations.concat([option?.id]),
                })
              }
              clearable={false}
              placeholder={"Buscar variación"}
              overrides={{
                DropdownListItem: {
                  component: forwardRef(
                    ({$isHighlighted, item, style, ...props}, ref: any) => {
                      const value = item as Variation;
                      return (
                        <div
                          ref={ref}
                          style={style}
                          onClick={props.onClick}
                          className={joinClasses([
                            "w-full text-left p-2 hover:cursor-pointer hover:bg-gray-200 border-b",
                            $isHighlighted ? "bg-gray-200" : "",
                          ])}
                        >
                          <div>{value.name}</div>
                          <div className="text-sm">{value.internal}</div>
                        </div>
                      );
                    }
                  ),
                },
              }}
            />
          </FormControl>
          <List
            removable
            items={state.variations.map((a) =>
              variations.find((b) => b.id === a)
            )}
            onChange={({oldIndex, newIndex}) =>
              setState({
                ...state,
                variations:
                  newIndex === -1
                    ? arrayRemove(state.variations, oldIndex)
                    : arrayMove(state.variations, oldIndex, newIndex),
              })
            }
            overrides={{
              Item: {
                style: {
                  borderLeftColor: "rgb(228 228 231)",
                  borderRightColor: "rgb(228 228 231)",
                  borderTopColor: "rgb(228 228 231)",
                  borderBottomColor: "rgb(228 228 231)",
                },
                component: forwardRef(
                  ({$value, children, $isDragged, style}: any, ref: any) => {
                    return (
                      <div
                        ref={ref}
                        style={style}
                        className={joinClasses([
                          "w-full bg-white border-2 py-2 px-4 min-h-[4rem] -mb-1",
                          $isDragged ? "border-black" : "border-gray-200",
                        ])}
                      >
                        {children[0]}
                        <div className="flex-1">
                          <div>{$value.name}</div>
                          <div className="text-sm text-gray-400">
                            {$value.internal}
                          </div>
                        </div>
                        {children[2]}
                      </div>
                    );
                  }
                ) as any,
              },
            }}
          />
        </Card>
        <Card title="Publicación" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Publicado</p>
              <p className="text-xs text-gray-400">
                Muestra el platillo en el sitio web & app móvil.
              </p>
            </div>
            <Switch
              value={state.published}
              onChange={() => {
                setState({
                  ...state,
                  published: !state.published,
                });
              }}
            />
          </div>
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Disponibilidad</p>
              <p className="text-xs text-gray-400">
                Marca el producto como disponible.
              </p>
            </div>
            <Switch
              value={state.available}
              onChange={() => {
                setState({
                  ...state,
                  available: !state.available,
                });
              }}
            />
          </div>
        </Card>
        <div className="mt-4 flex items-center">
          <Button
            kind="success"
            className="mr-auto"
            loading={loading}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
          {editing && (
            <Button kind="danger" onClick={() => showDeleteModal(true)}>
              Eliminar
            </Button>
          )}
        </div>
      </Page>

      <Modal
        open={deleteModal}
        onClose={() => showDeleteModal(false)}
        closeable={false}
      >
        <div className="p-6">
          <h5>Eliminar</h5>
          <hr />
          <p>
            Esta acción no se puede revertir. ¿Deseas realmente eliminar estos
            datos?
          </p>

          <div className="mt-4 flex items-center justify-between">
            <Button
              kind="secondary"
              className="mr-auto"
              onClick={() => showDeleteModal(false)}
              disabled={deleting}
            >
              Cancelar
            </Button>
            <Button
              kind="danger"
              onClick={() => handleDelete()}
              loading={deleting}
              disabled={deleting}
            >
              Eliminar
            </Button>
          </div>
        </div>
      </Modal>

      <MediaModal
        open={media}
        onClose={() => showMedia(false)}
        onConfirm={(img) => {
          setState({...state, media: [...state.media, img]});
          showMedia(false);
        }}
      />
    </>
  );
}
