import {signOut} from "firebase/auth";
import React from "react";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import {authRef} from "../Firebase";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import useCurrentUser from "../hooks/useCurrentUser";

export default function Account() {
  const user = useCurrentUser();

  return (
    <>
      <Page>
        <Header title="Cuenta" />
        <Card title="Detalles" className="max-w-md">
          <FormControl title="Nombre">
            <Input readOnly value={`${user?.name} ${user?.lastname}`} />
          </FormControl>
          <FormControl title="Teléfono">
            <Input
              readOnly
              value={user?.phone ?? ""}
              placeholder="000-000-0000"
            />
          </FormControl>
          <FormControl title="Tipo de Usuario">
            <Input
              readOnly
              value={user?.role ?? ""}
              placeholder="Tipo"
              className="!text-gray-500"
            />
          </FormControl>
          <p className="text-xs text-gray-400">
            <i>
              La edición de tu perfil aún esta en desarrollo, agradecemos tu
              paciecia.
            </i>
          </p>
          <hr />
          <Button
            kind="danger"
            className="mt-4"
            onClick={() => signOut(authRef)}
          >
            Cerrar Sesión
          </Button>
        </Card>
      </Page>
    </>
  );
}
