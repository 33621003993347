import React, {useCallback, useEffect, useState} from "react";
import Sidebar from "../components/Sidebar";
import {Route, Routes} from "react-router-dom";
import Loading from "../screens/Loading";
import Offline from "../screens/Offline";
import {useAppDispatch} from "../redux/store";
import {makeRef} from "../Firebase";
import {onValue} from "firebase/database";
import {
  Category,
  Dish,
  Ingredient,
  Restaurant,
  User,
  Variation,
} from "../interface";
import {
  InitAdministrator,
  InitCategory,
  InitDish,
  InitIngredient,
  InitRestaurant,
  InitSettings,
  InitVariation,
  InitWeek,
} from "../data/InitData";
import {
  setCategories,
  setDishes,
  setIngredients,
  setRestaurants,
  setSettings,
  setUsers,
  setVariations,
} from "../redux/databaseSlice";
import useAllowedRoutes from "../hooks/useAllowedRoutes";
import {weekParser} from "../utils/Utils";

export default function MainRouter() {
  const [offline, setOffline] = useState(false);
  const routes = useAllowedRoutes();
  const dispatch = useAppDispatch();

  const getOfflineState = useCallback(() => {
    const ref = makeRef("settings/offline/admin");
    onValue(ref, (snapshot) => setOffline(snapshot.val()));
  }, []);

  const getUsers = useCallback(() => {
    const ref = makeRef("administrators");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: User[] = [];
        snapshot.forEach((item) => {
          list.push({
            ...InitAdministrator,
            ...item.val(),
            id: item.key,
          });
        });
        dispatch(setUsers(list));
      } else {
        dispatch(setUsers([]));
      }
    });
  }, [dispatch]);

  const getRestaurants = useCallback(() => {
    const ref = makeRef("restaurants");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: Restaurant[] = [];
        snapshot.forEach((item) => {
          list.push({
            ...InitRestaurant,
            ...item.val(),
            id: item.key,
          });
        });
        dispatch(setRestaurants(list));
      } else {
        dispatch(setRestaurants([]));
      }
    });
  }, [dispatch]);

  const getDishes = useCallback(() => {
    const ref = makeRef("dishes");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: Dish[] = [];
        snapshot.forEach((item) => {
          const value: Dish = item.val();
          const salePriceSchedule = InitWeek.map((hours, day) => {
            if (value.salePriceSchedule && value.salePriceSchedule[day]) {
              return value.salePriceSchedule[day];
            }
            return hours;
          });
          list.push({
            ...InitDish,
            ...value,
            salePriceSchedule,
            id: item.key as string,
          });
        });
        dispatch(setDishes(list));
      } else {
        dispatch(setDishes([]));
      }
    });
  }, [dispatch]);

  const getCategories = useCallback(() => {
    const ref = makeRef("categories");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: Category[] = [];
        snapshot.forEach((item) => {
          const hours = weekParser(item.val().hours);
          list.push({
            ...InitCategory,
            ...item.val(),
            hours,
            id: item.key,
          });
        });
        dispatch(setCategories(list));
      } else {
        dispatch(setCategories([]));
      }
    });
  }, [dispatch]);

  const getIngredients = useCallback(() => {
    const ref = makeRef("ingredients");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: Ingredient[] = [];
        snapshot.forEach((item) => {
          const value = item.val() as Ingredient;
          const salePriceSchedule = InitWeek.map((hours, day) => {
            return value.salePriceSchedule
              ? value.salePriceSchedule[day]
                ? value.salePriceSchedule[day]
                : hours
              : hours;
          });
          list.push({
            ...InitIngredient,
            ...value,
            salePriceSchedule,
            id: item.key as string,
          });
        });
        dispatch(setIngredients(list));
      } else {
        dispatch(setIngredients([]));
      }
    });
  }, [dispatch]);

  const getVariations = useCallback(() => {
    const ref = makeRef("variations");
    onValue(ref, (snapshot) => {
      if (snapshot.exists()) {
        const list: Variation[] = [];
        snapshot.forEach((item) => {
          list.push({
            ...InitVariation,
            ...item.val(),
            id: item.key,
          });
        });
        dispatch(setVariations(list));
      } else {
        dispatch(setVariations([]));
      }
    });
  }, [dispatch]);

  const getSettings = useCallback(() => {
    const ref = makeRef("settings");
    onValue(ref, (snapshot) => {
      dispatch(setSettings({...InitSettings, ...snapshot.val()}));
    });
  }, [dispatch]);

  useEffect(() => {
    getOfflineState();
    getRestaurants();
    getUsers();
    getDishes();
    getCategories();
    getIngredients();
    getVariations();
    getSettings();
  }, [
    getRestaurants,
    getUsers,
    getOfflineState,
    getDishes,
    getCategories,
    getIngredients,
    getVariations,
    getSettings,
  ]);

  if (offline) return <Offline />;
  return (
    <div className="routes-container">
      <Sidebar />
      <div className="routes">
        <Routes>
          {routes.length > 0 && <Route path="/" element={routes[0].element} />}
          {routes.map((route, index) => {
            return (
              <Route
                key={route.path + index}
                element={route.element}
                path={route.path}
              />
            );
          })}
          <Route path="*" element={<Loading />} />
        </Routes>
      </div>
    </div>
  );
}
