import { UserRole } from "../interface";
import { useAppSelector } from "../redux/store";

export default function useAllowedRestaurants() {
  const user = useAppSelector((state) => state.database.user);
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const roles = ["marketing", "user"] as UserRole[];
  const allowed = roles.includes(user?.role || "user")
    ? restaurants.filter((a) => user?.restaurants.includes(a.id))
    : restaurants;

  return allowed;
}
