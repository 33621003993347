import React from 'react'
import Setup from '../Setup'

export default function Loading() {
  return (
    <div className="flex items-center justify-center min-h-screen w-screen">
      <img src={Setup.assets.brand} alt="" className="h-[40vh] w-[40vh]" />
    </div>
  )
}
