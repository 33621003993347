import React from "react";

interface Props {
  onClick: () => void;
  label: string;
}
export default function CalendarButton({onClick, label}: Props) {
  return (
    <button
      onClick={onClick}
      className="bg-white flex items-center btn btn-md text-black w-full shadow text-left"
    >
      <span className="ri-calendar-line text-xl mr-2" />
      <span className="text-sm leading-tight mr-auto">{label}</span>
    </button>
  );
}
