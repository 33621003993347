import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useSnackbar} from "baseui/snackbar";
import {useLocation} from "react-router-dom";
import Card from "../components/Card";
import DistancePricer from "../components/DistancePricer";
import DrawableMaps from "../components/DrawableMaps";
import Header from "../components/Header";
import Map from "../components/Map";
import MediaModal from "../components/MediaModal";
import Page from "../components/Page";
import WeekSchedule from "../components/WeekSchedule";
import {InitRestaurant} from "../data/InitData";
import {api, authRef, makeRef} from "../Firebase";
import {ErrorKeys, LatLng, Restaurant, RestaurantSecret} from "../interface";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Switch from "../ui/input/Switch";
import Textarea from "../ui/input/Textarea";
import {v4 as uuidv4} from "uuid";
import {
  shouldUploadMedia,
  uniqueArraySet,
  uploadMediaArray,
} from "../utils/Utils";
import {update} from "firebase/database";
import ErrorAlert from "../ui/alert/ErrorAlert";
import {useAppSelector} from "../redux/store";
import useCurrentUser from "../hooks/useCurrentUser";

const getRestaurantKey = async (restaurantId: string) => {
  try {
    const token = await authRef.currentUser?.getIdToken(true);
    const {data} = await axios({
      url: `${api}/payment-auth-keys/${restaurantId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default function FormRestaurant() {
  const user = useCurrentUser();
  const restId = useRef(uuidv4()).current;
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const {pathname} = useLocation();
  const {enqueue} = useSnackbar();

  const [editing, setEditing] = useState(false);
  const [state, setState] = useState<Restaurant>(InitRestaurant);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);

  const [media, showMedia] = useState(false);

  const [keys, setKeys] = useState<RestaurantSecret>({
    publishable: "",
    secret: "",
    webhook: "",
  });

  useEffect(() => {
    if (pathname.includes("/locations/") && authRef.currentUser?.uid) {
      setEditing(true);
      const path = pathname.split("/");
      const id = path[path.length - 1];
      const match = restaurants.find((d) => d.id === id);
      if (match?.id) {
        setState({
          ...InitRestaurant,
          ...match,
        });
        getRestaurantKey(id)
          .then((value) => {
            if (value?.publishable) {
              setKeys(value);
            } else {
              setKeys({
                publishable: "",
                secret: "",
                webhook: "",
              });
            }
          })
          .catch((error) => {
            console.log((error as Error).message);
            setKeys({
              publishable: "",
              secret: "",
              webhook: "",
            });
          });
      }
    } else {
      setEditing(false);
      setState(InitRestaurant);
    }
  }, [pathname, restaurants]);

  const handleSave = async () => {
    setLoading(true);
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido";
    errs.name = !state.name ? msg : "";
    errs.phone = !state.phone ? msg : "";
    errs.hours = !state.hours.flat(1).length
      ? "Agrega horarios de operación"
      : "";
    errs.deliveryCoverage =
      state.enableDelivery && !state.deliveryCoverage.length
        ? "Dibuja la cobertura de tus envios a domicilio"
        : "";
    errs.deliveryPricing =
      state.enableDelivery && !state.deliveryPricing.length
        ? "Agrega el costo & distancia de entrega"
        : "";
    errs.stripe =
      state.enableCardPayments &&
      !Object.values(keys).filter((a) => a.length).length
        ? "Para aceptar pagos con tarjeta es requerido agregar las 3 llaves de Stripe"
        : "";
    const hasErrors = Object.values(errs).filter((a) => a.length).length;
    errs.global = hasErrors ? "Algunos campos son requeridos o invalidos" : "";
    if (!hasErrors) {
      try {
        const id = editing ? state.id : restId;
        const mediaToUpload = state.media.length
          ? state.media.filter((a) => shouldUploadMedia(a))
          : [];
        const uploadedMedia = mediaToUpload.length
          ? await uploadMediaArray(
              mediaToUpload,
              `restaurants/${id}`,
              "media",
              "image/jpeg"
            )
          : [];
        const media = state.media.length
          ? state.media
              .filter((a) => !shouldUploadMedia(a))
              .concat(uploadedMedia)
          : [];
        const payload = {
          ...InitRestaurant,
          ...state,
          media,
          hours: state.hours.map((hrs) => uniqueArraySet(hrs)),
          id,
        } as Restaurant;
        const restaurantRef = makeRef(`restaurants/${id}`);
        const keysRef = makeRef(`apis/stripe/${id}`);
        await update(restaurantRef, payload);
        await update(keysRef, keys);
        enqueue({
          message: `Se actualizo la sucursal ${state.name} correctamente.`,
        });
        window.history.back();
      } catch (error) {
        setErrors({
          global: "No fue posible guardar los datos, intenta de nuevo.",
        });
        setLoading(false);
      }
    } else {
      setErrors(errs);
      setLoading(false);
    }
  };

  return (
    <>
      <Page>
        <Header title={editing ? state.name : "Agregar Restaurante"} goBack />
        <Card title="Galeria">
          <div className="flex flex-wrap">
            {state.media.map((src, idx) => (
              <div className="relative mr-2" key={`media-${idx}`}>
                <img
                  src={src}
                  alt=""
                  className="max-h-40 max-w-[10rem] w-full h-full !aspect-square bg-gray-200"
                />
                <Button
                  size="sm"
                  kind="danger"
                  className="absolute top-0 right-0 !aspect-square h-10"
                  onClick={() => {
                    const updates = state.media.filter((_, i) => i !== idx);
                    setState({...state, media: updates});
                  }}
                >
                  <div className="ri-close-fill text-xl" />
                </Button>
              </div>
            ))}
            {state.media.length < 4 && (
              <button
                className="max-h-40 max-w-[10rem] w-full h-full !aspect-square bg-gray-200"
                onClick={() => showMedia(true)}
              >
                <div className="ri-image-2-line text-5xl text-gray-400" />
              </button>
            )}
          </div>
        </Card>

        <Card title="Detalles" className="mt-4">
          <FormControl
            title="Nombre de la Sucursal"
            caption={`${state.name.length}/150`}
            error={errors.name}
          >
            <Input
              value={state.name}
              onChange={(e) =>
                setState({...state, name: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
              error={!!errors.name}
            />
          </FormControl>
          <FormControl title="Teléfono" error={errors.phone}>
            <Input
              value={state.phone}
              onChange={(e) =>
                setState({...state, phone: e.currentTarget.value})
              }
              placeholder={"000-000-0000"}
              error={!!errors.phone}
            />
          </FormControl>
          <FormControl title="Email" error={errors.email}>
            <Input
              value={state.email}
              onChange={(e) =>
                setState({...state, email: e.currentTarget.value})
              }
              placeholder={"email@restaurant.com"}
              error={!!errors.email}
            />
          </FormControl>
        </Card>

        <Card title="Dirección" className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            <Map
              className="col-span-1 md:col-span-2"
              center={{
                lat: state.address.latitude,
                lng: state.address.longitude,
              }}
            />
            <div className="col-span-1 md:col-span-3">
              <h4 className="leading-tight">{state.address.name}</h4>
              <p className="leading-tight">{state.address.address}</p>
              <p className="leading-tight mt-2 text-sm">
                <span className="ri-google-fill mr-1 leading-none" />
                {state.address.placeId}
              </p>
              <p className="leading-tight mt-2 text-sm">
                <span className="ri-map-pin-2-fill mr-1 leading-none" />
                {state.address.latitude}, {state.address.longitude}
              </p>
              <p className="mt-3 text-xs text-gray-400">
                Una vez agregada una sucursal no se puede modificar la
                dirección.
              </p>
            </div>
          </div>
        </Card>

        <Card title="Horario de Operación" className="mt-4">
          <WeekSchedule
            value={state.hours}
            onChange={(value) => setState({...state, hours: value})}
          />
          <ErrorAlert
            message={
              errors.hours
                ? errors.hours
                : "" || state.hours.flat(1).length
                ? ""
                : "Agrega horarios de operación"
            }
          />
        </Card>

        <Card title="Envios a Domicilio" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">
                Habilitar Envios a Domicilio
              </p>
              <p className="text-xs text-gray-400">
                Configura las zonas, distancias y costos por entregar a
                domicilo.
              </p>
            </div>
            <Switch
              value={state.enableDelivery}
              onChange={() => {
                setState({
                  ...state,
                  enableDelivery: !state.enableDelivery,
                  deliveryCoverage:
                    !state.enableDelivery === false
                      ? []
                      : state.deliveryCoverage,
                });
              }}
            />
          </div>
          {state.enableDelivery && (
            <>
              <div className="mt-3">
                <h6>Limite de Servicio</h6>
                <p className="leading-4 text-sm mb-4">
                  Dibuja en el mapa los limites del servicio a domicilio de esta
                  sucursal.
                </p>
                <DrawableMaps
                  onChange={(coords: unknown) =>
                    setState({...state, deliveryCoverage: coords as LatLng[]})
                  }
                  value={
                    state.deliveryCoverage
                      ? (state.deliveryCoverage as unknown as google.maps.LatLngLiteral[])
                      : []
                  }
                  center={{
                    lat: state.address.latitude,
                    lng: state.address.longitude,
                  }}
                />
              </div>
              <div className="mt-4">
                <h6>Distancia & Precios</h6>
                <p className="leading-4 text-sm mb-4">
                  Ingresa el precio por entrega en base a la distancia. Ten en
                  cuenta que no superará los limites que dibujaste en el mapa.
                </p>
                <DistancePricer
                  value={state.deliveryPricing}
                  onChange={(deliveryPricing) =>
                    setState({...state, deliveryPricing})
                  }
                />
              </div>
            </>
          )}
        </Card>

        <Card title="Pago con Tarjeta" className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h6>Tarjetas de Débito/Crédito</h6>
              <p className=" leading-4 text-sm">
                Acepta pagos con tarjetas Visa, MasterCard, Discover & American
                Express.
              </p>
            </div>
            <Switch
              value={state.enableCardPayments}
              onChange={() =>
                setState({
                  ...state,
                  enableCardPayments: !state.enableCardPayments,
                })
              }
            />
          </div>
          {state.enableCardPayments && (
            <div className="mt-2">
              <hr />
              <h6>Claves Stripe®</h6>
              <p className="leading-tight text-sm mb-4">
                Ingresa a tu panel de control Stripe® para obtener tus llaves de
                autorización para habilitar los pagos con tarjeta.
              </p>
              <FormControl title={"Clave Pública"}>
                <Textarea
                  value={keys.publishable}
                  onChange={(e) =>
                    setKeys({
                      ...keys,
                      publishable: e.currentTarget.value,
                    })
                  }
                  placeholder={"PK_STRIPE_XXXX"}
                  type={"password"}
                  error={!!errors.stripe}
                  disabled={user?.role !== "administrator"}
                />
              </FormControl>
              <FormControl title={"Clave Secreta"}>
                <Textarea
                  value={keys.secret}
                  onChange={(e) =>
                    setKeys({
                      ...keys,
                      secret: e.currentTarget.value,
                    })
                  }
                  placeholder={"SK_STRIPE_XXXX"}
                  type={"password"}
                  error={!!errors.stripe}
                  disabled={user?.role !== "administrator"}
                />
              </FormControl>
              <FormControl title={"Stripe Webhook Key"}>
                <Textarea
                  value={keys.webhook}
                  onChange={(e) =>
                    setKeys({
                      ...keys,
                      webhook: e.currentTarget.value,
                    })
                  }
                  placeholder={"WHSEC_STRIPE-XXXX"}
                  type={"password"}
                  error={!!errors.stripe}
                  disabled={user?.role !== "administrator"}
                />
              </FormControl>
            </div>
          )}
        </Card>

        <Card title="Publicación" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Pausar Pedidos</p>
              <p className="text-xs text-gray-400">
                Deshabilita la sucursal, no podrá recibir pedidos.
              </p>
            </div>
            <Switch
              value={state.offline}
              onChange={() => {
                setState({
                  ...state,
                  offline: !state.offline,
                });
              }}
            />
          </div>
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Publicada</p>
              <p className="text-xs text-gray-400">
                Muestra la sucursal en el sitio web & app móvil.
              </p>
            </div>
            <Switch
              value={state.published}
              onChange={() => {
                setState({
                  ...state,
                  published: !state.published,
                });
              }}
            />
          </div>
        </Card>

        <ErrorAlert message={errors.global} />
        <div className="mt-4 flex items-center">
          <Button
            kind="success"
            className="mr-auto"
            loading={loading}
            disabled={loading}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
        </div>
      </Page>

      <MediaModal
        open={media}
        onClose={() => showMedia(false)}
        onConfirm={(img) => {
          setState({...state, media: [...state.media, img]});
          showMedia(false);
        }}
      />
    </>
  );
}
