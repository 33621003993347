import axios from "axios";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import Header from "../components/Header";
import Page from "../components/Page";
import {api, authRef, makeRef} from "../Firebase";
import {Restaurant} from "../interface";
import Setup from "../Setup";
import ErrorAlert from "../ui/alert/ErrorAlert";
import Button from "../ui/button/Button";
import Icon from "../ui/icon/Icon";
import Input from "../ui/input/Input";
import Modal from "../ui/modal/Modal";
import {update} from "firebase/database";
import {joinClasses} from "../ui/utils/Utils";
import {useAppSelector} from "../redux/store";

export default function Locations() {
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Page>
        <Header title="Sucursales">
          <Button
            kind="success"
            onClick={() => setOpen(true)}
            className="ml-auto rounded-md"
          >
            <span className="ri-add-line text-xl mr-1" />
            Agregar
          </Button>
        </Header>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {restaurants.map((item, index) => (
            <ItemCard key={`item-${index}`} data={item} />
          ))}
        </div>
      </Page>

      <RestaurantModalAdd
        open={open}
        onClose={() => setOpen(false)}
        key={open ? 100 : 200}
      />
    </>
  );
}

interface RestaurantModalAddProps {
  open: boolean;
  onClose: () => void;
}
const RestaurantModalAdd = ({open, onClose}: RestaurantModalAddProps) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [message, setMessage] = useState("");

  const searchAddress = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${api}/maps-search`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await authRef.currentUser?.getIdToken(
            true
          )}`,
        },
        data: {
          address: query,
        },
      });
      if (response.data) {
        setData(response.data);
      } else {
        throw new Error("no_results");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage(
        "Lo sentimos no encontramos resultados. " + (error as Error).message
      );
    }
  };

  const handleAddress = async (place: any) => {
    try {
      const payload = {
        name: place.name,
        address: {
          name: place.name,
          address: place.formatted_address,
          latitude: place.geometry.location.lat,
          longitude: place.geometry.location.lng,
          placeId: place.place_id,
        },
        offline: true,
        published: false,
      } as Restaurant;
      const ref = makeRef(`restaurants/${place.place_id}`);
      await update(ref, payload);
      onClose();
    } catch (error) {
      setMessage("Hubo un error intenta de nuevo");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="p-6 text-center">
        <div className="h-60 w-60 bg-gray-200 rounded-full mx-auto flex items-center justify-center mb-6">
          <div className="ri-store-2-line text-8xl text-green-600" />
        </div>
        <h3>Agregar Sucursal</h3>

        <div className="mt-4">
          <Input
            disabled={loading}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            containerClassName="w-full border-2 border-black"
            startEnhancer={<Icon name="search-2-line" />}
            placeholder="Buscar dirección"
            endEnhancer={
              <Button
                onClick={() => searchAddress()}
                loading={loading}
                className="-mr-3"
              >
                Buscar
              </Button>
            }
          />
          {data.length > 0 && (
            <div className="mt-4 text-left">
              {data.map((item, index) => (
                <button
                  key={`item-${index}`}
                  className="flex w-full text-left"
                  onClick={() => handleAddress(item)}
                >
                  <div className="mr-2">
                    <Icon name="map-pin-fill" className="text-2xl" />
                  </div>
                  <div>
                    <h4>{item.name}</h4>
                    <div>{item.formatted_address}</div>
                    <div>{item.placeId}</div>
                  </div>
                </button>
              ))}
            </div>
          )}
          <ErrorAlert message={message} />
        </div>
      </div>
    </Modal>
  );
};

interface ItemCardProps {
  data: Restaurant;
}
const ItemCard = ({data}: ItemCardProps) => {
  return (
    <Link
      to={`/locations/${data.id}`}
      className={joinClasses([
        "shadow p-4 rounded-lg bg-white",
        data?.offline ? "opacity-50" : "",
      ])}
    >
      <img
        className="w-full !aspect-square rounded"
        alt=""
        src={data.media ? data.media[0] : Setup.assets.fallback}
      />
      <div className="mt-2 text-black">
        <h6>{data.name}</h6>
        <p className="leading-tight text-xs text-gray-700">
          {data.address.address}
        </p>
        <p className="leading-tight text-sm text-gray-900 my-2 font-semibold">
          <i className="ri-phone-fill text-base align-middle" /> {data.phone}
        </p>
      </div>
    </Link>
  );
};
