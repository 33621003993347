import dayjs from "dayjs";
import {
  limitToFirst,
  onValue,
  orderByChild,
  query,
  startAfter,
} from "firebase/database";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";
import InputSearch from "../components/InputSearch";
import Page from "../components/Page";
import {InitCustomer} from "../data/InitData";
import {makeRef} from "../Firebase";
import {Customer} from "../interface";
import Setup from "../Setup";
import {RowItemAvatarName, RowItemChip} from "../tables/TableAtoms";
import Icon from "../ui/icon/Icon";
import Table from "../ui/tables/Table";
import {findQueryText, sorter} from "../utils/Utils";
import Button from "../ui/button/Button";

const COUNT = 1000;

export default function Customers() {
  const navigate = useNavigate();
  const [data, setData] = useState<Customer[]>([]);
  const [search, setSearch] = useState("");
  const [by, setBy] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);

  const getMoreCustomers = () => {
    const ref = makeRef("customers");
    const queryRef = query(
      ref,
      orderByChild("joined"),
      startAfter(data[data.length - 1].joined),
      limitToFirst(COUNT)
    );
    onValue(
      queryRef,
      (snapshot) => {
        const list: Customer[] = [];
        snapshot.forEach((item) => {
          list.push({
            ...InitCustomer,
            ...item.val(),
            id: item.key,
          });
        });
        setData([...data, ...list]);
        setLoadingMore(false);
      },
      {onlyOnce: true}
    );
  };

  const getCustomers = useCallback(() => {
    const ref = makeRef("customers");
    const queryRef = query(ref, orderByChild("joined"), limitToFirst(COUNT));
    onValue(
      queryRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const list = [] as Customer[];
          snapshot.forEach((item) => {
            list.push({
              ...InitCustomer,
              ...item.val(),
              id: item.key,
            });
          });
          setData(list);
        } else {
          setData([]);
        }
      },
      {onlyOnce: true}
    );
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const sortBy = (filter: keyof Customer | `-${keyof Customer}`) => {
    const sorted = sorter(data, filter);
    setData(sorted);
    setBy(filter);
  };

  const values = data.length
    ? data.filter((a) =>
        findQueryText(`${a.phone}-${a.name}-${a.lastname}-${a.email}`, search)
      )
    : [];
  return (
    <>
      <Page>
        <Header title="Clientes">
          <div className="ml-auto">
            <InputSearch
              value={search}
              onChange={setSearch}
              onClear={() => setSearch("")}
            />
          </div>
        </Header>
        <Table
          footer={
            <>
              <Button
                loading={loadingMore}
                disabled={loadingMore}
                onClick={() => getMoreCustomers()}
              >
                Cargar más
              </Button>
            </>
          }
          maxHeight={600}
          columns={[
            "Detalle",
            <SortButton
              onClick={() =>
                sortBy(by === "purchases" ? "-purchases" : "purchases")
              }
              label="COMPRAS"
            />,
            <SortButton
              onClick={() => sortBy(by === "rewards" ? "-rewards" : "rewards")}
              label="PUNTOS"
            />,
            <SortButton
              onClick={() => sortBy(by === "joined" ? "-joined" : "joined")}
              label="SE UNIÓ"
            />,
            <SortButton
              onClick={() =>
                sortBy(
                  by === "notificationsToken"
                    ? "-notificationsToken"
                    : "notificationsToken"
                )
              }
              label="APP"
            />,
          ]}
          data={values.map((a) => [
            <RowItemAvatarName
              media={a.avatar ? a.avatar : Setup.assets.fallback}
              name={`${a.name} ${a.lastname}`}
              description={a.phone}
            />,
            a.purchases,
            a.rewards.toFixed(2),
            dayjs(a.joined).format("MM/DD/YY"),
            <RowItemChip value={!!a.notificationsToken} />,
            a,
          ])}
          onClick={(index) => {
            navigate(`/customers/${values[index].id}`);
          }}
        />
      </Page>
    </>
  );
}

interface SortButtonProps {
  label: string;
  onClick: () => void;
}
const SortButton = ({label, onClick}: SortButtonProps) => {
  const [inv, setInv] = useState(false);
  return (
    <button
      onClick={() => {
        setInv(!inv);
        onClick();
      }}
      className="flex"
    >
      {label} <Icon name={!inv ? "arrow-up-line" : "arrow-down-line"} />
    </button>
  );
};
