import React from "react";
import {Link} from "react-router-dom";
import {Kind, Size} from "..";
import Spinner from "../spinner/Spinner";
import {joinClasses} from "../utils/Utils";

type Shape = "pill" | "rounded" | "square" | "default";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  kind?: Kind | "success" | "danger";
  size?: Size;
  asLink?: boolean;
  href?: string;
  shape?: Shape;
}

export default function Button({
  loading,
  className,
  kind,
  size,
  disabled,
  asLink,
  href,
  children,
  shape,
  ...props
}: ButtonProps) {
  const kinds = {
    primary: "btn-primary",
    secondary: "btn-secondary",
    success: "btn-success",
    transparent: "btn-transparent",
    disabled: "btn-disabled",
    danger: "btn-danger",
  } as {[name: string]: string};

  const sizes = {
    xs: "btn-xs",
    sm: "btn-sm",
    md: "btn-md",
    lg: "btn-lg",
    xl: "btn-xl",
  } as {[s in Size]: string};

  const shapes = {
    pill: "rounded-full",
    rounded: "rounded-md",
    default: "",
    square: "",
  } as {[s in Shape]: string};

  const styles = joinClasses([
    "btn",
    sizes[size ?? "md"],
    shapes[shape ?? "default"],
    className ?? "",
    disabled ? kinds.disabled : kinds[kind ?? "primary"],
  ]);

  if (asLink && href)
    return (
      <Link to={href} className={styles}>
        {loading ? <Spinner /> : children}
      </Link>
    );
  return (
    <button {...props} disabled={disabled} className={styles}>
      {loading ? <Spinner /> : children}
    </button>
  );
}
