import React from "react";
import business from "../../data/Business";
import {OrderInvoice} from "../../interface";

interface Props {
  data: OrderInvoice;
  kitchen?: boolean;
}
export default function TicketFooter({kitchen, data}: Props) {
  if (kitchen) return <></>;
  return (
    <div className="hidden print:block text-center">
      <hr />
      <p className="leading-tight text-sm text-center">
        Gracias por tu preferencia
      </p>
      <p className="text-xxs leading-tight text-center">
        Visita nuestra pagina web o descarga nuestra aplicación.
      </p>
      <img
        src="/assets/qr-website.png"
        alt=""
        className="w-40 h-40 text-center block ml-auto mr-auto"
      />
      <p className="text-xs text-center">{business.domain}</p>
    </div>
  );
}
