import React from "react";
import {useNavigate} from "react-router-dom";
import {OrderInvoice} from "../../interface";
import Setup from "../../Setup";

interface Props {
  data: OrderInvoice;
  kitchen?: boolean;
}
export default function TicketCustomer({data, kitchen}: Props) {
  const navigation = useNavigate();
  return (
    <div className={kitchen ? "print:hidden" : ""}>
      <hr />
      <div
        className="flex items-center hover:cursor-pointer"
        onClick={() => navigation(`/customers/${data.customer.id}`)}
      >
        <div className="print:hidden mr-4">
          <img
            src={
              data.customer?.avatar
                ? data.customer.avatar
                : Setup.assets.fallback
            }
            className="h-12 w-12 bg-gray-300"
            alt=""
          />
        </div>
        <div>
          <p className="leading-tight print:text-xs">
            {data.customer.name} {data.customer.lastname}
          </p>
          <p className="leading-tight print:text-xs">{data.customer.phone}</p>
          <p className="leading-tight text-xs">{data.customer.email}</p>
        </div>
      </div>
      <hr />
    </div>
  );
}
