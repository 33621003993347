import React from "react";
import {CartItem, CartItemV1} from "../interface";
import Setup from "../Setup";
import Button from "../ui/button/Button";
import {useAppSelector} from "../redux/store";

interface Props {
  data: CartItem;
  onRemove?: () => void;
  hideDelete?: boolean;
}

export default function CartItemCard({data, onRemove, hideDelete}: Props) {
  const dishes = useAppSelector((state) => state.database.dishes);
  const newCartItem = data as unknown as CartItemV1 | null;
  const dish = dishes.find(
    (a) => a.id === data?.dish.id || a.id === newCartItem?.itemId
  );
  const quantity = data?.qty || newCartItem?.quantity;

  return (
    <div className="flex py-4 print:p-0">
      <div className="h-16 w-16 aspect-square print:hidden mr-3">
        {!!dish?.media ? (
          dish.media.map((src, idx) => (
            <div key={`media-${idx}`}>
              <img
                src={src}
                alt=""
                className="w-full aspect-square object-cover bg-gray-800"
              />
            </div>
          ))
        ) : (
          <div>
            <img
              src={Setup.assets.fallback}
              alt=""
              className="w-full aspect-square"
            />
          </div>
        )}
      </div>
      <div className="flex-1">
        <p className="leading-tight print:text-sm">
          {quantity} x {dish?.name}
        </p>
        <span className="text-lg print:text-sm">
          <span className="text-sm align-middle">$</span>
          {data.total.toFixed(2)}
          <span className="text-sm align-middle">MXN</span>
        </span>
        {!!data.extras ? (
          data.extras.map((extra, index) => (
            <div key={`extra-${index}`}>
              <p className="text-gray-600 text-sm print:text-xs font-semibold">
                {extra?.variation || (extra as any)?.variationName}
              </p>
              <p className="text-gray-900 text-xs print:text-xxs">
                {extra.name}
                {extra.total > 0 && " — $"}
                {extra.total ? extra.total : ""}
              </p>
            </div>
          ))
        ) : (
          <></>
        )}
        {!!data?.notes && <p className="text-gray-900 text-sm">{data.notes}</p>}
      </div>
      <div className={hideDelete ? "hidden" : ""}>
        <Button
          size="xs"
          kind="transparent"
          className="h-8 w-8 bg-red-900"
          onClick={onRemove}
        >
          <span className="ri-delete-bin-line text-opacity-80 text-white text-2xl" />
        </Button>
      </div>
    </div>
  );
}
