import React from "react";
import {Modal as BaseModal} from "baseui/modal";

interface Props {
  open: boolean;
  onClose: () => void;
  children: any;
  closeable?: boolean;
  size?: "auto" | "default"
}

export default function Modal({open, onClose, children, closeable, size}: Props) {
  return (
    <BaseModal
      size={size}
      unstable_ModalBackdropScroll
      onClose={() => onClose()}
      isOpen={open}
      closeable={closeable}
      overrides={{
        Dialog: {
          style: {
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
          }
        }
      }}
    >
      <div className="!text-black">{children}</div>
    </BaseModal>
  );
}
