import React from "react";
import {OrderInvoicePaymentStatus} from "../interface";

interface Props {
  status: OrderInvoicePaymentStatus;
}
export default function PaymentStatusIcon({status}: Props) {
  return (
    <>
      {status === "paid" ? (
        <span className="ri-checkbox-circle-fill text-lime-600 text-xl" />
      ) : status === "processing" ? (
        <span className="ri-timer-line text-orange-300 text-xl" />
      ) : status === "cancelled" ? (
        <span className="ri-close-circle-fill text-red-600 text-xl" />
      ) : status === "refunded" ? (
        <span className="ri-replay-line text-sky-600 text-xl" />
      ) : status === "unpaid" ? (
        <span className="ri-time-fill text-yellow-600 text-xl" />
      ) : (
        <span className="ri-question-line text-red-400 text-xl" />
      )}
    </>
  );
}
