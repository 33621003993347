import React, {useState} from "react";
import InputSearch from "../components/InputSearch";
import Table from "../ui/tables/Table";
import {findQueryText, sorter} from "../utils/Utils";
import {RowItemAvatarName} from "./TableAtoms";
import {useAppSelector} from "../redux/store";

export default function UsersTable(props: any) {
  const users = useAppSelector((state) => state.database.users);
  const [query, setQuery] = useState("");

  const data = sorter(
    users.filter((a) => a.email).filter((a) => findQueryText(a.name, query)),
    "name"
  );

  return (
    <>
      <div className="mb-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputSearch
          value={query}
          onChange={setQuery}
          onClear={() => setQuery("")}
        />
      </div>
      <Table
        columns={["NOMBRE", "TIPO"]}
        data={data.map((item) => [
          <RowItemAvatarName
            media={item.avatar}
            name={`${item.name} ${item.lastname}`}
            description={item.email}
          />,
          item.role === "administrator"
            ? "Admin"
            : item.role === "manager"
            ? "Supervisor"
            : "Cajero",
          item,
        ])}
        onClick={(index, value) => props.onEdit(data[index])}
      />
    </>
  );
}
