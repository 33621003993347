import {GoogleMap, OverlayView} from "@react-google-maps/api";
import React, {useState} from "react";
import Setup from "../Setup";

interface Props {
  value: google.maps.LatLngLiteral[];
  onChange: (value: google.maps.LatLngLiteral[]) => void;
  center?: google.maps.LatLngLiteral;
}

const center: google.maps.LatLngLiteral = {
  lat: 22.23,
  lng: -97.85,
};

export default function DrawableMaps(props: Props) {
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(
      google.maps.drawing.OverlayType.POLYGON
    );

  const initDrawableMap = (map: google.maps.Map) => {
    // GENERAL OPTIONS
    map.setOptions({
      gestureHandling: "cooperative",
      fullscreenControl: true,
      disableDefaultUI: true,
      zoom: 13,
      center: props.center ?? center,
      zoomControl: true,
    });

    // DRAWER MANAGER
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      drawingMode: drawingMode,
      polygonOptions: {
        editable: true,
        draggable: true,
        clickable: true,
      },
    });
    drawingManager.setMap(map);
    drawingManager.addListener(
      "polygoncomplete",
      (shape: google.maps.Polygon) => {
        const coords = shape
          .getPath()
          .getArray()
          .map((latlng) => ({lat: latlng.lat(), lng: latlng.lng()}));
        props.onChange(coords);
        currentPolygons = shape;
        setDrawingMode(null);
      }
    );

    // POLYGON EVENTS
    let polygons: google.maps.Polygon;
    polygons = new google.maps.Polygon({
      editable: true,
      draggable: true,
      clickable: true,
      paths: props.value ? props.value : [],
    });
    let currentPolygons = polygons;

    polygons.setMap(map);
    polygons.getPaths().forEach((path, index) => {
      google.maps.event.addListener(path, "insert_at", () => {
        const coords = polygons
          .getPath()
          .getArray()
          .map((latlng) => ({lat: latlng.lat(), lng: latlng.lng()}));
        props.onChange(coords);
        currentPolygons = polygons;
      });
      google.maps.event.addListener(path, "remove_at", () => {
        const coords = polygons
          .getPath()
          .getArray()
          .map((latlng) => ({lat: latlng.lat(), lng: latlng.lng()}));
        props.onChange(coords);
        currentPolygons = polygons;
      });
      google.maps.event.addListener(path, "set_at", () => {
        const coords = polygons
          .getPath()
          .getArray()
          .map((latlng) => ({lat: latlng.lat(), lng: latlng.lng()}));
        props.onChange(coords);
        currentPolygons = polygons;
      });
    });
    google.maps.event.addListener(polygons, "dragend", () => {
      const coords = polygons
        .getPath()
        .getArray()
        .map((latlng) => ({lat: latlng.lat(), lng: latlng.lng()}));
      props.onChange(coords);
      currentPolygons = polygons;
    });

    // REMOVE SHAPE CONTROLUI
    const removeShapesButton = document.createElement("div");
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.textAlign = "center";
    controlUI.style.margin = "8px";
    controlUI.title = "Elimina todos los trazos.";
    removeShapesButton.appendChild(controlUI);
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(255,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "30px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Eliminar Trazos";
    controlUI.appendChild(controlText);
    controlUI.addEventListener("click", () => {
      currentPolygons.setMap(null);
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    });
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      removeShapesButton
    );

    // ENDS
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{height: "600px", width: "100%"}}
        onLoad={initDrawableMap}
        center={props.center ?? center}
      >
        {props.center && (
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={props.center}
          >
            <div className="h-9 w-9 rounded-full bg-black flex items-center justify-center">
              <img src={Setup.assets.brand} alt="" className="h-7 w-auto" />
            </div>
          </OverlayView>
        )}
      </GoogleMap>
    </div>
  );
}
