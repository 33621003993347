import React from "react";
import {joinClasses} from "../utils/Utils";

export interface IconProps {
  name: string;
  className?: string;
}

export default function Icon({name, className}: IconProps) {
  return (
    <div
      className={joinClasses([
        `ri-${name} leading-none`,
        className ?? "",
      ])}
    />
  );
}
