import React from "react";
import {GoogleMap, Marker, OverlayView} from "@react-google-maps/api";
import {OrderInvoice, Place} from "../../interface";
import Setup from "../../Setup";
import {joinClasses} from "../../ui/utils/Utils";
import useCurrentRestaurant from "../../hooks/useCurrentRestaurant";

interface Props {
  data: OrderInvoice;
  kitchen?: boolean;
}

export default function TicketDelivery({data, kitchen}: Props) {
  const restaurant = useCurrentRestaurant();
  const place = {
    address: data.deliveryPlace?.address,
    latitude: data.deliveryPlace?.latitude,
    longitude: data.deliveryPlace?.longitude,
  } as Place;

  return (
    <div className={kitchen ? "print:hidden" : ""}>
      <div>
        <h6 className="print:text-sm font-medium">Entrega</h6>
        <div className="flex items-center leading-tight">
          <span
            className={joinClasses([
              "text-lg mr-2 print:text-xs",
              data.deliveryMethod === "delivery"
                ? "ri-motorbike-fill"
                : data.deliveryMethod === "pickup"
                ? "ri-walk-fill"
                : "ri-question-fill",
            ])}
          />
          <span className="print:text-xs">
            {data.deliveryMethod === "pickup" ? "En Sucursal" : "Domicilio"}
          </span>
        </div>
        <p className="text-sm leading-tight mb-2 print:text-xs">
          {data.deliveryMethod === "delivery" && place?.address}
        </p>
        <p className="text-sm leading-tight mb-2 print:text-xs">
          <strong>NOTAS: </strong>
          {data.deliveryMethod === "delivery" && data.deliveryNotes}
        </p>
        {data.deliveryMethod === "delivery" && data.deliveryPlace && (
          <GoogleMap
            mapContainerClassName="h-48 w-full print:hidden"
            zoom={13}
            center={{
              lat: place.latitude,
              lng: place.longitude,
            }}
            options={{gestureHandling: "cooperative"}}
          >
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{
                lat: Number(restaurant?.address.latitude || 0),
                lng: Number(restaurant?.address.longitude || 0),
              }}
            >
              <div className="h-9 w-9 rounded-full bg-black flex items-center justify-center">
                <img src={Setup.assets.brand} alt="" className="h-7 w-auto" />
              </div>
            </OverlayView>
            <Marker
              position={{
                lat: place.latitude,
                lng: place.longitude,
              }}
            />
          </GoogleMap>
        )}
      </div>
    </div>
  );
}
