import React from "react";
import { OrderInvoice } from "../../interface";
import CartItemCard from "../CartItem";

interface Props {
  data: OrderInvoice;
}
export default function TicketDishes({ data }: Props) {
  return (
    <div className="mb-4">
      <hr />
      <h5 className="print:text-sm">Platillos</h5>
      <div>
        {data.items.map((item, index) => (
          <div key={`item-${index}`}>
            <CartItemCard data={item} hideDelete />
            <hr />
          </div>
        ))}
      </div>
      <h6 className="print:text-sm">Extras</h6>
      <div>
        {data.extras?.map((item, index) => (
          <div key={`extra-${index}`} className="text-sm print:text-xs">
            {item.quantity} x {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}
