import React, {useEffect, useState} from "react";
import {useSnackbar} from "baseui/snackbar";
import {useLocation} from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import {InitCategory} from "../data/InitData";
import {Category, Dish, ErrorKeys} from "../interface";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Textarea from "../ui/input/Textarea";
import Modal from "../ui/modal/Modal";
import {makeRef} from "../Firebase";
import {remove, update} from "firebase/database";
import {v4 as uuidv4} from "uuid";
import {convertArrayToObject} from "../utils/Utils";
import Switch from "../ui/input/Switch";
import WeekSchedule from "../components/WeekSchedule";
import {useAppSelector} from "../redux/store";

export default function FormCategory() {
  const dishes = useAppSelector((state) => state.database.dishes);
  const categories = useAppSelector((state) => state.database.categories);
  const {pathname} = useLocation();
  const {enqueue} = useSnackbar();

  const [editing, setEditing] = useState(false);
  const [state, setState] = useState<Category>(InitCategory);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false); // delete modal
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (pathname.includes("/categories/")) {
      setEditing(true);
      const path = pathname.split("/");
      const id = path[path.length - 1];
      const match = categories.find((d) => d.id === id);
      if (match?.id) {
        setState({
          ...InitCategory,
          ...match,
        });
      }
    } else {
      setEditing(false);
      setState(InitCategory);
    }
  }, [pathname, categories]);

  const handleDelete = async () => {
    if (state.id) {
      try {
        setDeleting(true);
        const inDishes = dishes.filter(
          (a) => a.categories.filter((b) => b === state.id).length
        );
        if (inDishes.length) {
          const updates = inDishes.map((a) => {
            const removed = a.categories.filter((b: unknown) => b !== state.id); // when not parsed its a string[] of items
            return {
              ...a,
              categories: removed,
            } as Dish;
          });
          const objUpdates = convertArrayToObject(updates, "id");
          const dishesRef = makeRef("dishes");
          await update(dishesRef, objUpdates);
        }
        const itemRef = makeRef(`categories/${state.id}`);
        await remove(itemRef);
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        window.history.back();
      } catch (error) {
        enqueue({message: `${state.name} fue eliminado.`}, 2000);
        setDeleting(false);
      }
    } else {
      setDeleting(false);
    }
  };

  const handleSave = async () => {
    setErrors({});
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido o invalido";
    errs.name = !state.name ? msg : "";
    const hasErrors = Object.values(errs).filter((a) => a.length);
    errs.global = hasErrors.length ? hasErrors[0] : "";
    if (!hasErrors.length) {
      setLoading(true);
      try {
        const id = editing ? state.id : uuidv4();
        const payload = {
          ...InitCategory,
          ...state,
          id,
        } as Category;
        const itemRef = makeRef(`categories/${id}`);
        await update(itemRef, payload);
        enqueue({message: `${payload.name} se actualizó correctamente.`}, 2000);
        window.history.back();
      } catch (error) {
        console.log(error);
        setErrors({
          global: "Hubo un error al intentar guardar los intenta de nuevo",
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrors(errs);
    }
  };

  return (
    <>
      <Page>
        <Header title={editing ? state.name : "Agregar Categoria"} goBack />
        <Card title="Detalles" className="mt-4">
          <FormControl
            title="Nombre de la Categoria"
            caption={`${state.name.length}/150`}
            error={errors.name}
          >
            <Input
              value={state.name}
              onChange={(e) =>
                setState({...state, name: e.currentTarget.value})
              }
              placeholder={"Escribe el nombre del platillo..."}
              maxLength={150}
            />
          </FormControl>
          <FormControl
            title="Descripción"
            caption={`${state.description.length}/300`}
          >
            <Textarea
              value={state.description}
              onChange={(e) =>
                setState({...state, description: e.currentTarget.value})
              }
              placeholder="Describe el platillo..."
              maxLength={300}
            />
          </FormControl>
        </Card>
        <Card title="Horarios de Disponibilidad">
          <h6>Horarios de Oferta</h6>
          <WeekSchedule
            value={state.hours}
            onChange={(value) => setState({...state, hours: value})}
          />
        </Card>
        <Card title="Publicación" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Publicado</p>
              <p className="text-xs text-gray-400">
                Muestra el platillo en el sitio web & app móvil.
              </p>
            </div>
            <Switch
              value={state.published}
              onChange={() => {
                setState({
                  ...state,
                  published: !state.published,
                });
              }}
            />
          </div>
        </Card>
        <div className="mt-4 flex items-center">
          <Button
            kind="success"
            className="mr-auto"
            loading={loading}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
          {editing && (
            <Button kind="danger" onClick={() => showDeleteModal(true)}>
              Eliminar
            </Button>
          )}
        </div>
      </Page>

      <Modal
        open={deleteModal}
        onClose={() => showDeleteModal(false)}
        closeable={false}
      >
        <div className="p-6">
          <h5>Eliminar</h5>
          <hr />
          <p>
            Esta acción no se puede revertir. ¿Deseas realmente eliminar estos
            datos?
          </p>

          <div className="mt-4 flex items-center justify-between">
            <Button
              kind="secondary"
              className="mr-auto"
              onClick={() => showDeleteModal(false)}
              disabled={deleting}
            >
              Cancelar
            </Button>
            <Button
              kind="danger"
              onClick={() => handleDelete()}
              loading={deleting}
              disabled={deleting}
            >
              Eliminar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
