import {update} from "firebase/database";
import React, { useState} from "react";
import Card from "../components/Card";
import Header from "../components/Header";
import Page from "../components/Page";
import {makeRef} from "../Firebase";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Switch from "../ui/input/Switch";
import {useAppSelector} from "../redux/store";

export default function Settings() {
  const settings = useAppSelector((state) => state.database.settings);
  const [percentage, setPercentage] = useState(
    settings.business.rewardRate > 0
      ? Number(settings.business.rewardRate) * 100
      : 0
  );
  const [updating, setUpdating] = useState(false);
  const [initRewards, setInitRewards] = useState(
    settings.business.initRewards || 0
  );
  const [updatingInitRewards, setUpdatingInitRewards] = useState(false);

  const setOffline = async (mode: boolean, kind: "website" | "app") => {
    try {
      const ref = makeRef(`settings/offline`);
      await update(ref, {
        [kind]: !mode,
      });
    } catch (error) {
      console.log((error as Error).message);
    }
  };

  const updateRewardRate = async () => {
    try {
      setUpdating(true);
      const ref = makeRef(`settings/business`);
      await update(ref, {
        rewardRate: percentage / 100,
      });
      setUpdating(false);
    } catch (error) {
      console.log((error as Error).message);
      setUpdating(false);
    }
  };

  const updateInitRewardRate = async () => {
    try {
      setUpdatingInitRewards(true);
      const ref = makeRef(`settings/business`);
      await update(ref, {
        initRewards,
      });
      setUpdatingInitRewards(false);
    } catch (error) {
      console.log((error as Error).message);
      setUpdatingInitRewards(false);
    }
  };

  return (
    <>
      <Page>
        <Header title={"Configuraciones"} />
        <Card title="Sitio Web">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Habilitar</p>
              <p className="text-xs text-gray-400">
                Controla la disponibilidad del sitio web.
              </p>
            </div>
            <Switch
              value={!settings.offline.website}
              onChange={() => setOffline(settings.offline.website, "website")}
            />
          </div>
        </Card>
        <Card title="App iOS/Android" className="mt-4">
          <div className="flex items-center mb-4">
            <div className="flex-1 mr-4">
              <p className="text-sm font-semibold">Habilitar</p>
              <p className="text-xs text-gray-400">
                Controla la disponibilidad de las apps iOS & Android.
              </p>
            </div>
            <Switch
              value={!settings.offline.app}
              onChange={() => setOffline(settings.offline.app, "app")}
            />
          </div>
        </Card>
        <Card title="Puntos de Lealtad" className="mt-4">
          <div className="max-w-sm">
            <FormControl title="Porcentaje de Puntos por Compra">
              <div className="flex">
                <Button
                  disabled={percentage === 0}
                  onClick={() => setPercentage(percentage - 0.5)}
                >
                  <div className="ri-subtract-fill" />
                </Button>
                <Input
                  value={percentage}
                  type="number"
                  endEnhancer={"%"}
                  readOnly
                  containerClassName="max-w-xs mx-4"
                />
                <Button
                  disabled={percentage === 100}
                  onClick={() => setPercentage(percentage + 0.5)}
                >
                  <div className="ri-add-fill" />
                </Button>
              </div>
            </FormControl>
          </div>
          <div className="my-4">
            <h4>Una compra de $1000</h4>
            <p>Bonificaría ${(1000 * (percentage / 100)).toFixed(2)}</p>
          </div>
          <Button
            onClick={() => updateRewardRate()}
            loading={updating}
            disabled={updating}
            kind="success"
          >
            Guardar
          </Button>
        </Card>

        <Card title="Puntos de Regalo" className="mt-4">
          <FormControl
            title="Total de Puntos"
            caption="Ingresa la cantidad de puntos que obtendra el cliente al registrarse por primera vez en la app."
          >
            <Input
              value={initRewards}
              type="number"
              placeholder="0"
              min={0}
              step={1}
              onChange={(e) => setInitRewards(e.currentTarget.valueAsNumber)}
            />
          </FormControl>

          <Button
            onClick={() => updateInitRewardRate()}
            loading={updatingInitRewards}
            disabled={updatingInitRewards}
            kind="success"
          >
            Guardar
          </Button>
        </Card>
      </Page>
    </>
  );
}
