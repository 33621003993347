import {onAuthStateChanged} from "firebase/auth";
import React, {useCallback, useEffect, useState} from "react";
import {authRef, makeRef} from "./Firebase";
import AuthRouter from "./router/AuthRouter";
import MainRouter from "./router/MainRouter";
import Loading from "./screens/Loading";
import {useAppDispatch} from "./redux/store";
import {onValue} from "firebase/database";
import {setUser} from "./redux/databaseSlice";
import {InitAdministrator} from "./data/InitData";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(false);
  const dispatch = useAppDispatch();

  const getUserData = useCallback(
    (uid: string) => {
      const ref = makeRef(`administrators/${uid}`);
      onValue(ref, (snapshot) => {
        dispatch(
          setUser(
            snapshot.exists()
              ? {...InitAdministrator, ...snapshot.val(), id: uid}
              : null
          )
        );
        setLoading(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    onAuthStateChanged(authRef, (state) => {
      if (state?.uid) {
        setSession(true);
        getUserData(state.uid);
      } else {
        setSession(false);
        setLoading(false);
      }
    });
  }, [getUserData]);

  if (!session && !loading) return <AuthRouter />;
  if (session && !loading) return <MainRouter />;
  return <Loading />;
}
