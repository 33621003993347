import React from "react";
import { OrderInvoice } from "../../interface";
import ErrorAlert from "../../ui/alert/ErrorAlert";
import Button from "../../ui/button/Button";
import PaymentStatusIcon from "../PaymentStatusIcon";

interface Props {
  data: OrderInvoice;
  toggleModal: () => void;
  kitchen?: boolean;
}
export default function TicketPayment({ data, toggleModal, kitchen }: Props) {
  if (kitchen) return <></>;
  return (
    <div>
      <div>
        <div className="my-2 border-b-2 border-gray-200 w-full" />
        {!!data.paymentNotes && (
          <p className="text-sm leading-tight mb-2 print:text-xs">
            <strong>NOTAS: </strong>
            {data.paymentNotes}
          </p>
        )}
        <div className="flex items-center justify-between">
          <div className="print:hidden">
            <div className="flex items-center">
              <PaymentStatusIcon status={data.paymentMethod === "rewards" ? "paid" : data.paymentStatus} />
              <p className="ml-1">
                {data.paymentStatus === "paid" || data.paymentMethod === "rewards"
                  ? "Pagado"
                  : data.paymentStatus === "processing"
                  ? "Procesando"
                  : data.paymentStatus === "cancelled"
                  ? "Cancelado"
                  : data.paymentStatus === "refunded"
                  ? "Reembolsado"
                  : data.paymentStatus === "unpaid"
                  ? "Sin Pagar"
                  : "Desconocido"}
              </p>
            </div>
            <div>
              {data.paymentMethod === "card" &&
                (typeof data.paymentInvoice === "string" ? (
                  <h6>Captura manual</h6>
                ) : (
                  <>
                    {data.paymentInvoice && (
                      <div className="p-1 bg-gray-800 inline-flex items-center">
                        <img
                          src={`/assets/cards/${data.paymentInvoice?.charges.data[0].payment_method_details?.card?.brand}.svg`}
                          className="h-3 w-auto"
                          alt=""
                        />
                        <span className="ml-2 font-semibold text-white leading-tight">
                          {data.paymentInvoice?.charges.data[0].payment_method_details?.card?.last4}
                        </span>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="print:hidden">
            <Button
              size="sm"
              onClick={toggleModal}
              disabled={
                data.paymentStatus === "paid" ||
                data.paymentStatus === "refunded" ||
                data.paymentStatus === "cancelled" ||
                data.paymentMethod === "rewards"
              }
            >
              Procesar Pago
            </Button>
          </div>
        </div>
      </div>
      {data.paymentMethod !== "rewards" && (
        <ErrorAlert
          title="Pago Requerido"
          message={
            data.paymentStatus !== "paid" && data.status === "ready"
              ? "Procesa el pago antes de completar este pedido."
              : ""
          }
        />
      )}
    </div>
  );
}
