import { createSlice } from "@reduxjs/toolkit";
import { Category, Dish, Ingredient, Restaurant, Settings, User, Variation } from "../interface";
import { InitSettings } from "../data/InitData";

type State = {
  user: User | null;
  users: User[];
  restaurantId: string;
  restaurants: Restaurant[];
  ingredients: Ingredient[];
  dishes: Dish[];
  variations: Variation[];
  categories: Category[];
  settings: Settings;
};

const initialState: State = {
  user: null,
  users: [],
  restaurantId: "",
  restaurants: [],
  ingredients: [],
  dishes: [],
  variations: [],
  categories: [],
  settings: InitSettings,
};

const databaseSlice = createSlice({
  name: "databaseSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setRestaurantId: (state, action) => {
      state.restaurantId = action.payload;
    },
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setIngredients: (state, action) => {
      state.ingredients = action.payload;
    },
    setDishes: (state, action) => {
      state.dishes = action.payload;
    },
    setVariations: (state, action) => {
      state.variations = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const {
  setUser,
  setUsers,
  setRestaurantId,
  setRestaurants,
  setIngredients,
  setDishes,
  setVariations,
  setCategories,
  setSettings,
} = databaseSlice.actions;
export default databaseSlice.reducer;
