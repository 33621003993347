import axios from "axios";
import {Option, Select} from "baseui/select";
import {update} from "firebase/database";
import React, {useState} from "react";
import Header from "../components/Header";
import Page from "../components/Page";
import {InitAdministrator} from "../data/InitData";
import {api, authRef, makeRef} from "../Firebase";
import {ErrorKeys, User} from "../interface";
import UsersTable from "../tables/UsersTable";
import ErrorAlert from "../ui/alert/ErrorAlert";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";
import Modal from "../ui/modal/Modal";
import useCurrentUser from "../hooks/useCurrentUser";
import {useAppSelector} from "../redux/store";

export default function Users() {
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userEdit, setUserEdit] = useState<User | null>(null);

  return (
    <>
      <Page>
        <Header title="Usuarios">
          <Button
            kind="success"
            onClick={() => setEdit(true)}
            className="ml-auto"
            disabled={user?.role === "user"}
          >
            <span className="ri-add-line text-xl mr-1" />
            Agregar
          </Button>
        </Header>
        <UsersTable
          onEdit={(user: User) => {
            setUserEdit(user);
            setEdit(true);
          }}
        />
      </Page>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="p-6 text-center">
          <div className="h-60 w-60 bg-gray-200 rounded-full mx-auto flex items-center justify-center mb-6">
            <div className="ri-user-add-line text-8xl text-green-600" />
          </div>
          <h3>Agregar Usuario</h3>
          <p>
            Comunicate con nosotros para ver las diferentes opciones disponibles
            para agregar un administrador, manager o usuario a tu restaurant
            manager.
          </p>
        </div>
      </Modal>

      <Modal
        key={edit ? 100 : 200}
        open={edit}
        onClose={() => {
          setEdit(false);
          setUserEdit(null);
        }}
      >
        <UserEditForm
          data={userEdit}
          onClose={() => {
            setEdit(false);
            setUserEdit(null);
          }}
        />
      </Modal>
    </>
  );
}

interface UserEditFormProps {
  data: User | null;
  onClose: () => void;
}
const UserEditForm = ({data, onClose}: UserEditFormProps) => {
  const restaurants = useAppSelector((state) => state.database.restaurants);
  const user = useCurrentUser();
  const [state, setState] = useState<User>(!!data ? data : InitAdministrator);
  const [errors, setErrors] = useState<ErrorKeys>({});
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    let errs = {} as ErrorKeys;
    const msg = "Campo requerido o invalido";
    errs.name = !state.name ? msg : "";
    errs.lastname = !state.lastname ? msg : "";
    const hasErrors = Object.values(errs).filter((a) => a.length).length;
    errs.global = hasErrors ? "Algunos campos son requeridos o invalidos" : "";
    if (!hasErrors) {
      const updates = {
        ...InitAdministrator,
        ...state,
      };

      if (!state.id) {
        console.log("user is new");
        try {
          const response = await axios({
            url: `${api}/administrator`,
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await authRef.currentUser?.getIdToken(
                true
              )}`,
            },
            data: updates,
          });
          console.log(response.data);
          setLoading(false);
          onClose();
        } catch (error) {
          console.log((error as Error).message);
          setLoading(false);
          setErrors({
            global:
              "No se pudo guardar la información intenta de nuevo. " +
              (error as Error).message,
          });
        }
      } else {
        try {
          const userRef = makeRef(`administrators/${state.id}`);
          await update(userRef, updates);
          setLoading(false);
          onClose();
        } catch (error) {
          console.log((error as Error).message);
          setErrors({
            global: "No fue posible guardar la información, intenta de nuevo",
          });
        }
      }
    } else {
      setErrors(errs);
      setLoading(false);
    }
  };

  if (user?.role !== "administrator" && state.role === "administrator")
    return (
      <div className="p-4">
        <h5>No puedes editar este usuario</h5>
      </div>
    );
  return (
    <div className="p-4">
      <h4>Editar Usuario</h4>
      <hr />
      <div className="card p-0 !border-none !shadow-none">
        <FormControl title="Nombre" required error={errors.name}>
          <Input
            value={state.name}
            onChange={(e) => setState({...state, name: e.currentTarget.value})}
            placeholder="Nombre"
            error={!!errors.name}
          />
        </FormControl>
        <FormControl title="Apellido" required error={errors.lastname}>
          <Input
            value={state.lastname}
            onChange={(e) =>
              setState({...state, lastname: e.currentTarget.value})
            }
            placeholder="Nombre"
            error={!!errors.lastname}
          />
        </FormControl>
        <FormControl
          title="Correo"
          caption={!!state.id ? "No se puede editar este dato." : ""}
        >
          <Input
            value={state.email}
            onChange={(e) => setState({...state, email: e.currentTarget.value})}
            readOnly={!!state.id}
            disabled={!!state.id}
            placeholder="correo@restaurante.com"
          />
        </FormControl>
        {!state.id && (
          <FormControl title="Contraseña" caption="Al menos 8 caracteres">
            <Input
              value={state.password}
              onChange={(e) =>
                setState({...state, password: e.currentTarget.value})
              }
              placeholder="************"
              maxLength={20}
            />
          </FormControl>
        )}
        <FormControl title="Tipo de Usuario">
          <div className="flex">
            <Button
              kind={state.role === "manager" ? "primary" : "secondary"}
              onClick={() => setState({...state, role: "manager"})}
            >
              Supervisor
            </Button>
            <Button
              kind={state.role === "user" ? "primary" : "secondary"}
              onClick={() => setState({...state, role: "user"})}
            >
              Cajero
            </Button>
            <Button
              kind={state.role === "marketing" ? "primary" : "secondary"}
              onClick={() => setState({...state, role: "marketing"})}
            >
              Marketing
            </Button>
          </div>
        </FormControl>
        <FormControl
          title="Sucursales"
          caption="Elige la sucursales que administra este usuario"
        >
          <Select
            placeholder="Sucursales"
            options={restaurants}
            value={
              state.role === "administrator"
                ? []
                : (state.restaurants
                    .map((a) => restaurants.find((b) => b.id === a))
                    .filter(Boolean) as Option[])
            }
            onChange={({value}) => {
              setState({...state, restaurants: value.map((a) => a.id) as any});
            }}
            getValueLabel={({option}) => option.name}
            getOptionLabel={({option}) => option?.name}
            multi
            searchable={false}
            clearable
            disabled={state.role === "administrator"}
          />
        </FormControl>
        <hr />
        <ErrorAlert message={errors.global} />
        <div className="flex justify-between items-center">
          <Button kind="secondary" onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button kind="success" loading={loading} onClick={() => handleSave()}>
            Guardar
          </Button>
        </div>
      </div>
    </div>
  );
};
