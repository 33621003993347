const business = {
  business: {
    description: "",
    legal_name: "El Pariente",
    commercial_name: "El Pariente",
    phone: "+52 833 210 5161",
    address: "Guerrero 101, Unidad Nacional Ciudad Madero, Tamaulipas",
  },
  site: "https://www.taqueriaselpariente.com",
  email: "buzon@taqueriaselpariente.com",
  domain: "taqueriaselpariente.com",
};

export default business;
