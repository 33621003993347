import React from "react";
import {joinClasses} from "../utils/Utils";

interface Props {
  title: string;
  children: any;
  required?: boolean;
  caption?: string;
  error?: string;
}

export default function FormControl({
  title,
  children,
  required,
  caption,
  error,
}: Props) {
  return (
    <div className="mb-3">
      <label className="mb-1 text-sm">
        {title}
        {required ? <span className="text-red-600">*</span> : ""}
      </label>
      <div>{children}</div>
      <p
        className={joinClasses([
          "text-xxs text-gray-600 leading-none mt-1",
          !!error ? "text-red-400" : "",
        ])}
      >
        {!!error ? error : caption}
      </p>
    </div>
  );
}
