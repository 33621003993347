import React from "react";
import {Size} from "..";
import {joinClasses} from "../utils/Utils";

interface Props {
  size?: Size;
  className?: string;
}

export default function Spinner({size, className}: Props) {
  const sizes = {
    xs: "spinner-xs",
    sm: "spinner-sm",
    md: "spinner-md",
    lg: "spinner-lg",
    xl: "spinner-xl",
  } as {[s in Size]: string};

  const styles = joinClasses(["spinner", sizes[size ?? "md"], className ?? ""]);

  return <div className={styles} />;
}
