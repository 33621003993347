import React from "react";
import Setup from "../Setup";

export default function Offline() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-screen">
      <img src={Setup.assets.brand} alt="" className="h-[40vh] w-[40vh]" />
      <h3 className="mt-4">Tu cuenta se encuentra suspendida.</h3>
      <p className="mt-4">Comunicate con el administrator.</p>
      <a
        href="https://numiodigital.com"
        referrerPolicy="no-referrer"
        className="text-lime-700 hover:text-lime-500"
      >
        @numiodigital
      </a>
    </div>
  );
}
