import React from "react";
import {joinClasses} from "../ui/utils/Utils";

interface Props {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  placeholder?: string;
  containerClassName?: HTMLDivElement["className"];
  inputClassName?: HTMLDivElement["className"];
}
export default function InputSearch({
  value,
  onChange,
  onClear,
  placeholder,
  inputClassName,
  containerClassName,
}: Props) {
  return (
    <div
      className={joinClasses([
        "inline-flex items-center bg-white p-1 shadow text-black",
        containerClassName ?? "",
      ])}
    >
      <div className="ri-search-2-line text-xl" />
      <input
        type="text"
        placeholder={placeholder ?? "Buscar"}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        className={joinClasses([
          "p-1 w-full outline-none",
          inputClassName ?? "",
        ])}
      />
      <button
        onClick={onClear}
        disabled={!value.length}
        className={!value.length ? "hidden" : ""}
      >
        <div className="ri-close-line text-xl" />
      </button>
    </div>
  );
}
