import React, {useEffect, useState} from "react";
import Page from "../components/Page";
import ModalCalendar from "../components/ModalCalendar";
import dayjs from "dayjs";
import Header from "../components/Header";
import RestaurantSelect from "../components/RestaurantSelect";
import CalendarButton from "../components/CalendarButton";
import useCurrentRestaurant from "../hooks/useCurrentRestaurant";
import {makeRef} from "../Firebase";
import {endAt, onValue, orderByChild, query, startAt} from "firebase/database";
import {OrderInvoice} from "../interface";
import {InitOrderInvoice} from "../data/InitData";
import colors from "tailwindcss/colors";
import TableHeatMap from "../components/TableHeatMap";
import Button from "../ui/button/Button";
import CardWithDonut from "../components/ChartWithDonut";
import {
  deliveryMethodLabel,
  groupArrayBy,
  paymentMethodLabel,
} from "../utils/Utils";
import Card from "../components/Card";
import MainChart from "../components/MainChart";
import HeatMap from "../components/HeatMap";

const chartColors = [
  colors.green[500],
  colors.blue[500],
  colors.yellow[500],
  colors.orange[500],
  colors.purple[500],
];

const initDates = [
  dayjs().startOf("month").startOf("day").toDate(),
  dayjs().endOf("day").toDate(),
];

export default function Dashboard() {
  const restaurant = useCurrentRestaurant();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [date, setDate] = useState(initDates);
  const [orders, setOrders] = useState<OrderInvoice[]>([]);
  const [heatView, setHeatView] = useState<"money" | "transaction">(
    "transaction"
  );

  const calendarLabel = Array.isArray(date)
    ? `${dayjs(date[0]).format("DD/MM/YY")} — ${dayjs(date[1]).format(
        "DD/MM/YY"
      )}`
    : dayjs(date).format("DD/MM/YY");

  useEffect(() => {
    if (restaurant?.id && date.length === 2) {
      const ref = makeRef(`orders/${restaurant.id}`);
      // CURRENT PERIOD
      const startTime = dayjs(date[0]).valueOf();
      const endTime = dayjs(date[1]).valueOf();
      const isSame = dayjs(startTime).isSame(dayjs(endTime));
      const finalEndTime = isSame
        ? dayjs(endTime).endOf("day").valueOf()
        : endTime;
      const queryRef = query(
        ref,
        orderByChild("timeRequested"),
        startAt(startTime),
        endAt(finalEndTime)
      );
      onValue(
        queryRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const list: OrderInvoice[] = [];
            snapshot.forEach((item) => {
              list.push({
                ...InitOrderInvoice,
                ...item.val(),
                id: item.key,
              });
            });
            setOrders(list);
          } else {
            setOrders([]);
          }
        },
        {onlyOnce: true}
      );
    }
  }, [restaurant?.id, date]);

  const heatChart = orders.map((order) => {
    const day = dayjs(order.timeRequested).day();
    const hour = dayjs(order.timeRequested).hour();
    const sales =
      heatView === "transaction"
        ? orders.filter(
            (a) =>
              day === dayjs(a.timeRequested).day() &&
              hour === dayjs(a.timeRequested).hour()
          ).length
        : order.paymentTotal;
    return {
      day,
      hour,
      sales,
    };
  });

  const dataStatus = (
    ["card", "cash", "courtesy", "rewards"] as OrderInvoice["paymentMethod"][]
  ).map((method, index) => {
    const value = orders.filter((a) => a.paymentMethod === method).length;
    return {
      title: paymentMethodLabel(method),
      value,
      caption: "pedidos",
      color: chartColors[index % chartColors.length],
    };
  });

  const dataSource = (["app", "pos", "web"] as OrderInvoice["source"][]).map(
    (source, index) => {
      return {
        title:
          source === "app"
            ? "App"
            : source === "pos"
            ? "Punto de Venta"
            : source === "web"
            ? "Sitio Web"
            : "Desconocido",
        value: orders.filter((a) => a.source === source).length,
        caption: "pedidos",
        color: chartColors[index % chartColors.length],
      };
    }
  );

  const dataDelivery = (
    ["delivery", "pickup", "table"] as OrderInvoice["deliveryMethod"][]
  ).map((method, index) => {
    return {
      title: deliveryMethodLabel(method),
      value: orders.filter((a) => a.deliveryMethod === method).length,
      caption: "pedidos",
      color: chartColors[index % chartColors.length],
    };
  });

  const mainChart = parseForChart(orders);

  const heatMapValues = orders
    .filter((a) => a.deliveryMethod === "delivery")
    .map((order: any) => {
      return {
        location: new google.maps.LatLng({
          lat: order.deliveryPlace?.latitude,
          lng: order.deliveryPlace?.longitude,
        }),
        weight: 1,
      };
    });

  return (
    <Page>
      <Header title="Dashboard">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <RestaurantSelect hideUnpublished />
          <CalendarButton
            onClick={() => setCalendarOpen(true)}
            label={calendarLabel}
          />
        </div>
      </Header>

      <div className="space-y-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Card title="Ventas">
            <ul>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-green-600">Completadas</div>
                <div className="font-bold">
                  {orders.filter((a) => a.status === "completed").length}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-red-600">Canceladas</div>
                <div className="font-bold">
                  {orders.filter((a) => a.status === "cancelled").length}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Total</div>
                <div className="font-bold">{orders.length}</div>
              </li>
            </ul>
          </Card>
          <Card title="Ingresos">
            <ul>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium text-green-600">Total</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    orders.map((a) => a.paymentTotal).reduce((a, b) => a + b, 0)
                  )}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket Promedio</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    orders
                      .map((a) => a.paymentTotal)
                      .reduce((a, b) => a + b, 0) / orders.length
                  )}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket más alto</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(Math.max(...orders.map((a) => a.paymentTotal)))}
                </div>
              </li>
              <li className="flex items-center justify-between gap-4">
                <div className="font-medium">Ticket más bajo</div>
                <div className="font-bold">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(Math.min(...orders.map((a) => a.paymentTotal)))}
                </div>
              </li>
            </ul>
          </Card>
        </div>
        <div className="mt-4">
          <Card>
            <MainChart data={mainChart} />
          </Card>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
          <CardWithDonut title="Métodos de Pago" data={dataStatus} />
          <CardWithDonut title="Origen de Pedidos" data={dataSource} />
          <CardWithDonut title="Métodos de Entrega" data={dataDelivery} />
        </div>
        <div id="main-chart" className="card">
          <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
            <h3 className="font-medium text-lg">Ventas por Dia/Hora</h3>
            <div className="flex items-center">
              <Button
                kind={heatView === "transaction" ? "primary" : "secondary"}
                onClick={() => setHeatView("transaction")}
              >
                Ventas
              </Button>
              <Button
                kind={heatView === "money" ? "primary" : "secondary"}
                onClick={() => setHeatView("money")}
              >
                Ingresos
              </Button>
            </div>
          </div>
          <TableHeatMap data={heatChart} key={heatChart.length} />
        </div>
        <div className="card">
          <HeatMap
            key={`${restaurant?.id}-${heatMapValues.length}`}
            values={heatMapValues}
            mapsContainerStyle={{height: 600}}
            center={{
              lat: restaurant?.address.latitude || 0,
              lng: restaurant?.address.longitude || 0,
            }}
          />
        </div>
      </div>

      <ModalCalendar
        open={calendarOpen}
        onChange={setDate}
        onClose={() => setCalendarOpen(false)}
        value={date}
        minDate={dayjs("01/01/2020").toDate()}
      />
    </Page>
  );
}

const parseForChart = (data: OrderInvoice[]) => {
  const grouped = groupArrayBy(data, (a) =>
    dayjs(a.timeRequested).startOf("day").format("DD-MM-YY")
  );
  const values = Object.entries(grouped).map(([key, value]) => {
    return {
      date: key,
      value: value.map((a) => a.paymentTotal).reduce((a, b) => a + b, 0),
    };
  });
  return values;
};
