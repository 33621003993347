import {arrayMove, arrayRemove, List} from "baseui/dnd-list";
import {update} from "firebase/database";
import React, {useState} from "react";
import {makeRef} from "../../Firebase";
import {Category} from "../../interface";
import Button from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";
import {convertArrayToObject} from "../../utils/Utils";
import {useAppSelector} from "../../redux/store";

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default function CategoryOrderingModal({visible, onClose}: Props) {
  const categories = useAppSelector((state) => state.database.categories);
  const [order, setOrder] = useState<Category[]>(categories);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const finalOrder = order.map((item, index) => ({...item, order: index}));
    const obj = convertArrayToObject(finalOrder, "id");
    try {
      const ref = makeRef("categories");
      await update(ref, obj);
      setLoading(false);
      onClose();
    } catch (error) {
      console.log((error as Error).message);
      setLoading(false);
    }
  };

  return (
    <Modal open={visible} onClose={onClose} closeable={!loading}>
      <div className="p-6">
        <div>
          <h6>Ordenar Categorias</h6>
        </div>
        <hr />
        <List
          items={
            order.length
              ? order.map(
                  (a) => categories.find((b) => b.id === a.id)?.name || ""
                )
              : []
          }
          removable
          overrides={{
            Item: {
              style: {
                marginTop: -1,
                borderLeftColor: "rgb(228 228 231)",
                borderRightColor: "rgb(228 228 231)",
                borderTopColor: "rgb(228 228 231)",
                borderBottomColor: "rgb(228 228 231)",
              },
            },
          }}
          onChange={({oldIndex, newIndex}) => {
            const updates =
              newIndex === -1
                ? arrayRemove(order, oldIndex)
                : arrayMove(order, oldIndex, newIndex);
            setOrder(updates.map((a, i) => ({...a, order: i})));
          }}
        />
      </div>
      <hr />
      <div className="px-6 py-3 flex justify-between">
        <Button loading={loading} onClick={onClose} kind="secondary">
          Cancelar
        </Button>
        <Button loading={loading} onClick={() => handleSave()}>
          Guardar Cambios
        </Button>
      </div>
    </Modal>
  );
}
