import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import InputSearch from "../components/InputSearch";
import Table from "../ui/tables/Table";
import {findQueryText, sorter} from "../utils/Utils";
import {RowItemAvatarName, RowItemChip} from "./TableAtoms";
import {useAppSelector} from "../redux/store";

export default function VariationTable() {
  const variations = useAppSelector((state) => state.database.variations);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const data = sorter(
    variations.filter((a) => findQueryText(a.name, query)),
    "name"
  );

  return (
    <>
      <div className="mb-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputSearch
          value={query}
          onChange={setQuery}
          onClear={() => setQuery("")}
        />
      </div>
      <Table
        columns={[
          "NOMBRE",
          "MULTIPLE",
          "REQUERIDO",
          "OPCIONES",
          "MIN/MAX",
          "PUBLICADO",
        ]}
        data={data.map((item) => [
          <RowItemAvatarName
            name={item.name}
            description={item.internal}
            hideMedia
          />,
          <RowItemChip value={item.multiple} />,
          <RowItemChip value={item.required} />,
          item.items.length,
          `${item.min} / ${item.max}`,
          <RowItemChip value={item.published} />,
          item,
        ])}
        onClick={(index) => {
          navigate(`/menu/variations/${data[index].id}`);
        }}
      />
    </>
  );
}
