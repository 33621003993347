import {signInWithEmailAndPassword} from "@firebase/auth";
import {Input} from "baseui/input";
import {useState} from "react";
import {authRef} from "../Firebase";
import {useNavigate} from "react-router-dom";
import {ErrorKeys} from "../interface";
import Icon from "../ui/icon/Icon";
import ErrorAlert from "../ui/alert/ErrorAlert";
import FormControl from "../ui/form-control/FormControl";
import Button from "../ui/button/Button";
import Setup from "../Setup";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ErrorKeys>({email: "", password: ""});
  const [errors, setErrors] = useState<ErrorKeys>({
    email: "",
    password: "",
    global: "",
  });

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let errs: ErrorKeys = {};
    const msg = "Campo requerido";
    errs.email = !state.email ? msg : "";
    errs.pasword = !state.password ? msg : "";
    const hasErrors = Object.values(errs).filter((a) => a.length).length;
    if (!hasErrors) {
      try {
        await signInWithEmailAndPassword(authRef, state.email, state.password);
        navigate("/");
      } catch (error: any) {
        console.log(error.message, error.code);
        setLoading(false);
        switch (error.code) {
          case "auth/invalid-email":
            return setErrors({
              email: "El correo es invalido.",
              password: "",
              global: "",
            });
          case "auth/user-disabled":
            return setErrors({
              email: "",
              password: "",
              global: "La cuenta se encuentra suspendida por el administrador.",
            });
          case "auth/user-not-found":
            return setErrors({
              email: "No se encontró esta cuenta en el sistema.",
              password: "",
              global: "",
            });
          case "auth/wrong-password":
            return setErrors({
              email: "",
              password: "La contraseña es invalida.",
              global: "",
            });
          default:
            return setErrors({
              email: "",
              password: "",
              global:
                "Error de autenticación comuniquese con el desarrollador.",
            });
        }
      }
    } else {
      setErrors(errs);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-screen bg-gray-900 text-white">
      <div className="max-w-2xl mx-auto p-6">
        <img src={Setup.assets.brand} alt="" className="max-w-xs h-auto mx-auto" />
        <div className="h-full p-6 text-center">
          <h1 className="text-3xl">{Setup.business.name}</h1>
          <h2 className="text-2xl">Restaurant Manager</h2>
          <div className="mt-6">
            <form onSubmit={handleLogin} className="text-left">
              <FormControl title={"Email"} error={errors.email}>
                <Input
                  startEnhancer={<Icon name={"mail-line"} />}
                  placeholder={"email@restaurante.com"}
                  value={state.email}
                  name={"email"}
                  onChange={(e) =>
                    setState({...state, email: e.currentTarget.value})
                  }
                  autoComplete={"on"}
                  type={"email"}
                  error={!!errors.email}
                />
              </FormControl>
              <FormControl title={"Contraseña"} error={errors.password}>
                <Input
                  startEnhancer={<Icon name={"lock-line"} />}
                  placeholder={"**************"}
                  value={state.password}
                  name={"password"}
                  onChange={(e) =>
                    setState({...state, password: e.currentTarget.value})
                  }
                  autoComplete={"on"}
                  type={"password"}
                  error={!!errors.password}
                />
              </FormControl>
              <ErrorAlert message={errors.global} />
              <Button
                shape={"pill"}
                kind={"secondary"}
                loading={loading}
                disabled={loading}
                type={"submit"}
              >
                Iniciar Sesión
              </Button>
            </form>
          </div>
          <p className="text-xs text-background-700 mt-10">
            Desarrollado por{" "}
            <a
              href="https://numiodigital.com"
              className="text-lime-700 hover:text-lime-500"
            >
              @numio
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
