import React, {ReactNode} from "react";
import {joinClasses} from "../utils/Utils";

interface Props {
  columns: string[] | ReactNode[];
  data: any[][];
  onClick?: (index: number, value: any) => void;
  query?: string;
  maxHeight?: number;
  footer?: any;
}

export default function Table(props: Props) {
  return (
    <div
      className="shadow overflow-auto bg-white w-full"
      style={{
        maxHeight: props.maxHeight ? props.maxHeight : "70vh",
      }}
    >
      <table className="table-responsive w-full">
        {props.columns.length && (
          <thead className="bg-gray-600 sticky top-0 shadow-sm">
            <tr>
              {props.columns.map((th, idx) => (
                <th
                  key={`th-${idx}`}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                >
                  {th}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="bg-gray-100 bg-opacity-50 divide-y divide-gray-300 !text-black">
          {props.data.map((row, idx) => (
            <tr
              key={`tr-${idx}`}
              className={joinClasses([
                props.onClick
                  ? "hover:bg-gray-300 hover:bg-opacity-50 cursor-pointer"
                  : "",
              ])}
            >
              {row
                .slice(0, props.columns.length)
                .concat(
                  props.columns.length > row.length
                    ? new Array(props.columns.length - row.length).fill("")
                    : []
                )
                .map((td, i) => (
                  <td
                    key={`td-${i}`}
                    className="px-6 py-4 text-sm"
                    data-label={props.columns[i]}
                    onClick={() =>
                      props.onClick ? props.onClick(idx, row) : {}
                    }
                  >
                    <div>{td}</div>
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="sticky bottom-0 p-2 bg-gray-100 shadow-sm">
        <div className="flex items-center justify-between gap-6">
          <div className="text-sm text-gray-400">Total {props.data.length}</div>
          <div>{props.footer}</div>
        </div>
      </div>
    </div>
  );
}
