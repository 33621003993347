import dayjs from "dayjs";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import colors from "tailwindcss/colors";

interface DataItem {
  date: string;
  value: number;
}

interface Props {
  data: DataItem[];
}

const dummyData: DataItem[] = [
  {
    date: dayjs().format("DD/MM/YY"),
    value: 50,
  },
  {
    date: dayjs().format("DD/MM/YY"),
    value: 150,
  },
  {
    date: dayjs().format("DD/MM/YY"),
    value: 50,
  },
  {
    date: dayjs().format("DD/MM/YY"),
    value: 150,
  },
];

export default function MainChart({data}: Props) {
  return (
    <ResponsiveContainer width={"100%"} height={480}>
      <AreaChart
        width={600}
        height={600}
        data={data.length ? data : dummyData}
        margin={{left: -30}}
      >
        {data.length > 0 && <Tooltip />}
        <CartesianGrid strokeDasharray={"6 6"} />
        <XAxis dataKey="date" fontSize={12} />
        <YAxis fontSize={12} />
        <Area
          type="monotone"
          dataKey="value"
          fill={data.length ? colors.lime[600] : colors.gray[100]}
          stroke={data.length ? colors.lime[600] : colors.gray[200]}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
