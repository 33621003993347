import React from "react";
import {joinClasses} from "../utils/Utils";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: boolean;
  containerClassName?: string;
}

export default function Textarea({error, containerClassName, ...props}: Props) {
  return (
    <div
      className={joinClasses([
        "textarea",
        containerClassName ?? "",
        error ? "textarea-error" : "",
      ])}
    >
      <textarea {...props} rows={4} />
    </div>
  );
}
