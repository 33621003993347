import React from "react";
import classNames from "classnames";
import colors from "tailwindcss/colors";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";

interface DataItem {
  day: number;
  hour: number;
  sales: number;
}

interface TableHeatMapProps {
  containerClassName?: string;
  data: DataItem[];
}

const daysOfWeek = new Array(7).fill(true).map((_, idx) => {
  return dayjs().set("day", idx).locale("es-mx").format("dddd");
});

const colorShades: {[x: number]: string} = {
  50: colors.lime[50],
  100: colors.lime[100],
  200: colors.lime[200],
  300: colors.lime[300],
  400: colors.lime[400],
  500: colors.lime[500],
  600: colors.lime[600],
  700: colors.lime[700],
  800: colors.lime[800],
  900: colors.lime[900],
};

export default function TableHeatMap(props: TableHeatMapProps) {
  const getHourlySalesPerDay = (day: number, hour: number) =>
    props.data.find((x) => x.day === day && x.hour === hour)?.sales || 0;

  const getCellBackgroundColor = (sales: number) => {
    if (sales === 0) return "bg-white";

    const maxSales = Math.max(...props.data.map((a) => a.sales));
    const intensity = sales / maxSales;
    const shadeIndex = Math.floor(intensity * 9) * 100;

    return shadeIndex === 0 ? colorShades[50] : colorShades[shadeIndex];
  };

  const getCellTextColor = (backgroundColor: string) => {
    switch (backgroundColor) {
      case colorShades[50]:
        return "text-lime-900";
      case colorShades[100]:
        return "text-lime-900";
      case colorShades[200]:
        return "text-lime-900";
      case colorShades[300]:
        return "text-lime-900";
      case colorShades[400]:
        return "text-lime-900";
      case colorShades[500]:
        return "text-lime-900";
      case colorShades[600]:
        return "text-lime-100";
      case colorShades[700]:
        return "text-lime-100";
      case colorShades[800]:
        return "text-lime-100";
      case colorShades[900]:
        return "text-lime-100";
      default:
        return "text-black";
    }
  };

  return (
    <div className={classNames("w-full", props.containerClassName)}>
      <table className="w-full table-fixed">
        <tfoot>
          <tr>
            <th />
            {daysOfWeek.map((a) => (
              <th key={a} className="capitalize font-medium text-xs pt-3">
                {a}
              </th>
            ))}
          </tr>
        </tfoot>
        <tbody>
          {Array.from({length: 24}, (_, hour) => (
            <tr key={`tr-${hour}`}>
              <td className="border-e border-slate-100 text-xs w-[8%] text-center">
                {dayjs().startOf("day").set("hours", hour).format("HH:mm")}
              </td>
              {daysOfWeek.map((day, idx) => {
                const sales = getHourlySalesPerDay(idx, hour);
                const backgroundColor = getCellBackgroundColor(sales);
                const color = getCellTextColor(backgroundColor);

                return (
                  <td
                    key={`day-${day}`}
                    className="text-center border border-slate-100 group hover:cursor-pointer"
                    style={{backgroundColor}}
                  >
                    <span
                      className={classNames(
                        "text-xs opacity-0 group-hover:opacity-100 transition-all",
                        color
                      )}
                    >
                      {sales}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
