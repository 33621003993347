import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Page from "../components/Page";
import CategoryTable from "../tables/CategoryTable";
import DishTable from "../tables/DishTable";
import IngredientTable from "../tables/IngredientTable";
import VariationTable from "../tables/VariationTable";
import { joinClasses } from "../ui/utils/Utils";

const TABS = [
  {
    id: "dishes",
    label: "Platillos",
  },
  {
    id: "ingredients",
    label: "Ingredientes",
  },
  {
    id: "variations",
    label: "Variaciones",
  },
  {
    id: "categories",
    label: "Categorias",
  },
];

export default function Menu() {
  const { pathname, hash } = useLocation();
  const [tab, setTab] = useState(TABS[0]);

  useEffect(() => {
    if (hash) {
      const x = TABS.find((t) => t.id === hash.replace("#", ""));
      setTab(x ? x : TABS[0]);
    }
  }, [hash, pathname]);

  return (
    <>
      <Page>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <h3>Menu</h3>
          <div className="flex w-full">
            <Link
              to={`/menu/${tab.id}-add`}
              className="btn btn-md btn-success inline-flex mr-auto md:ml-auto md:mr-0 rounded-md"
            >
              <span className="ri-add-line text-xl mr-1" />
              Agregar
            </Link>
          </div>
        </div>
        <div className="my-4">
          <div className="inline-flex">
            {TABS.map((item, index) => (
              <button
                onClick={() => {
                  setTab(item);
                  window.history.pushState(null, "", `#${item.id}`);
                }}
                className={joinClasses([
                  "btn btn-md mr-1",
                  item === tab ? "btn-primary shadow-md" : "btn-secondary",
                ])}
                key={index}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
        <div>
          {tab.id === "dishes" && <DishTable />}
          {tab.id === "ingredients" && <IngredientTable />}
          {tab.id === "variations" && <VariationTable />}
          {tab.id === "categories" && <CategoryTable />}
        </div>
      </Page>
    </>
  );
}
