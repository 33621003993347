import React from "react";
import {Calendar} from "baseui/datepicker";
import dayjs from "dayjs";
import Modal from "../ui/modal/Modal";

interface Props {
  open: boolean;
  onClose: () => void;
  value: Date[];
  onChange: (value: Date[]) => void;
  minDate?: Date;
  maxDate?: Date;
}

export default function ModalCalendar({
  open,
  onChange,
  onClose,
  value,
  minDate,
  maxDate,
}: Props) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="!text-black">
        <div className="p-6 flex flex-col justify-center items-center">
          <h4>Fecha</h4>
          <div className="my-2 border-b-2 border-gray-200 w-full" />
          <Calendar
            range
            minDate={minDate ? minDate : dayjs("01/01/2020").toDate()}
            maxDate={maxDate ? maxDate : dayjs().endOf("day").toDate()}
            value={value}
            onChange={({date: value}) =>
              onChange(Array.isArray(value) ? value : [value])
            }
          />
        </div>
      </div>
    </Modal>
  );
}
