import React from "react";
import {DistancePrice} from "../interface";
import Button from "../ui/button/Button";
import FormControl from "../ui/form-control/FormControl";
import Input from "../ui/input/Input";

interface Props {
  value: DistancePrice[];
  onChange: (value: DistancePrice[]) => void;
}

export default function DistancePricer({value, onChange}: Props) {
  return (
    <div className="w-full">
      {value.map(({distance, price}, index) => (
        <div
          key={`distance-${index}`}
          className="flex justify-between"
        >
          <div className="mr-3">
            <FormControl title="Distancia">
              <Input
                error={isNaN(distance)}
                endEnhancer={"KM"}
                placeholder="0.00"
                type="number"
                min={0}
                value={distance}
                onChange={(e) => {
                  const updates = value.map((_, i) =>
                    i === index
                      ? {..._, distance: e.currentTarget.valueAsNumber}
                      : _
                  );
                  onChange(updates);
                }}
              />
            </FormControl>
          </div>
          <div className="mr-3">
            <FormControl title="Precio" error={isNaN(price) ? "Campo requerido" : ""}>
              <Input
                error={isNaN(price)}
                startEnhancer={"$"}
                endEnhancer={"MXN"}
                placeholder="0.00"
                type="number"
                min={0}
                value={price}
                onChange={(e) => {
                  const updates = value.map((_, i) =>
                    i === index
                      ? {..._, price: e.currentTarget.valueAsNumber}
                      : _
                  );
                  onChange(updates);
                }}
              />
            </FormControl>
          </div>
          <div className="mt-6 mr-auto">
            <Button
              size="sm"
              kind="danger"
              onClick={() => {
                const updates = value.filter((a, i) => i !== index);
                onChange(updates);
              }}
              className="h-10 aspect-square"
            >
              <div className="ri-close-line text-lg" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        kind="success"
        onClick={() => {
          const updates = value.concat([
            {
              distance: value.length ? value[value.length - 1].distance + 1 : 1,
              price: 0,
            },
          ]);
          onChange(updates);
        }}
      >
        <span className="ri-add-fill mr-1" />
        Agregar
      </Button>
    </div>
  );
}
