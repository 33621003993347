import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import InputSearch from "../components/InputSearch";
import Table from "../ui/tables/Table";
import {findQueryText, sorter} from "../utils/Utils";
import {RowItemAvatarName, RowItemChip, RowPricePromo} from "./TableAtoms";
import {useAppSelector} from "../redux/store";

export default function DishTable() {
  const dishes = useAppSelector((state) => state.database.dishes);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const data = sorter(
    dishes.filter((a) => findQueryText(a.name, query)),
    "name"
  );

  return (
    <>
      <div className="mb-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputSearch
          value={query}
          onChange={setQuery}
          onClear={() => setQuery("")}
        />
      </div>
      <Table
        columns={["NOMBRE", "PROMO", "PRECIO", "DISPONIBLE", "PUBLICADO"]}
        data={data.map((item) => [
          <RowItemAvatarName
            name={item.name}
            media={item.media[0]}
            description={item.description}
          />,
          <RowItemChip value={item.showSalePrice} />,
          <RowPricePromo
            price={item.price}
            salePrice={item.salePrice}
            showSalePrice={item.showSalePrice}
          />,
          <RowItemChip value={item.available} />,
          <RowItemChip value={item.published} />,
          item,
        ])}
        onClick={(index) => {
          navigate(`/menu/dishes/${data[index].id}`);
        }}
      />
    </>
  );
}
