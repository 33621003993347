import dayjs from "dayjs";
import {update} from "firebase/database";
import React, {useRef, useState} from "react";
import {authRef, makeRef} from "../Firebase";
import {
  OrderInvoice,
  OrderInvoicePaymentMethod,
  OrderInvoicePaymentStatus,
  OrderInvoiceStatus,
} from "../interface";
import Button from "../ui/button/Button";
import Modal from "../ui/modal/Modal";
import {joinClasses} from "../ui/utils/Utils";
import {useReactToPrint} from "react-to-print";
import Ticket from "./ticket/Ticket";

const STATUS = [
  {
    id: "pending",
    label: "Nueva",
  },
  {
    id: "cooking",
    label: "Preparar",
  },
  {
    id: "ready",
    label: "Completar",
  },
  {
    id: "completed",
    label: "Completada",
  },
] as {id: OrderInvoiceStatus; label: string}[];

interface Props {
  data?: OrderInvoice | null;
  onClose: () => void;
  noActions?: boolean;
}

export default function OrderDetails({data, onClose, noActions}: Props) {
  const printRef = useRef<HTMLDivElement>(null);
  const [isKitchen, setIsKitchen] = useState(false);
  const [paymentModal, showPaymentModal] = useState(false);
  const [cancelModal, showCancelModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<OrderInvoicePaymentMethod>(
    data?.paymentMethod ?? "cash"
  );
  const [paymentStatus, setPaymentStatus] = useState<OrderInvoicePaymentStatus>(
    data?.paymentStatus ?? "unpaid"
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Pedido#${data?.shortId}--${dayjs(
      data?.timeRequested
    ).format("MM-DD-YYYY--HH-mm")}`,
  });

  const nextStatus = (status: OrderInvoiceStatus) => {
    const currentIdx = STATUS.findIndex((a) => a.id === status);
    return STATUS[currentIdx + 1];
  };

  const handleCancelation = async () => {
    try {
      const ref = makeRef(`orders/${data?.restaurant.id}/${data?.id}`);
      await update(ref, {
        status: "cancelled",
        paymentStatus: "cancelled",
        handledBy: {
          id: authRef.currentUser?.uid,
          email: authRef.currentUser?.email,
        },
      } as OrderInvoice);
      showCancelModal(false);
      onClose();
    } catch (error) {
      console.log((error as Error).message);
      alert("No fue posible cancelar este pedido.");
    }
  };

  const handleUpdateStatus = async () => {
    if (!data) return;
    try {
      const itemRef = makeRef(`orders/${data.restaurant.id}/${data.id}`);
      await update(itemRef, {
        status: nextStatus(data.status).id,
        paymentMethod,
        paymentStatus,
        paymentInvoice: data.paymentInvoice ? data.paymentInvoice : "manual",
        handledBy: {
          id: authRef.currentUser?.uid,
          email: authRef.currentUser?.email,
        },
      });
      onClose();
    } catch (error) {
      console.log((error as Error).message);
    }
  };

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between m-4 mb-0 print:none max-w-xs">
        <Button
          size="xs"
          kind="secondary"
          onClick={() => {
            setIsKitchen(false);
            setTimeout(() => {
              handlePrint();
            }, 1000);
          }}
          className="print:hidden"
        >
          <span className="ri-download-fill mr-2" />
          Ticket Cliente
        </Button>
        <Button
          size="xs"
          kind="secondary"
          onClick={() => {
            setIsKitchen(true);
            setTimeout(() => {
              handlePrint();
            }, 1000);
          }}
          className="print:hidden"
        >
          <span className="ri-restaurant-2-fill mr-2" />
          Ticket Cocina
        </Button>
      </div>
      <div ref={printRef} id="ticket">
        <Ticket
          data={{...data, paymentMethod, paymentStatus}}
          kitchen={isKitchen}
          toggleModal={() => showPaymentModal(true)}
        />
      </div>
      <div
        className={joinClasses([
          "p-4 items-center justify-between print:hidden",
          noActions ? "hidden" : "flex",
        ])}
      >
        <div>
          {data.status === "cancelled" || data.status === "completed" ? (
            <Button disabled>
              {STATUS.find((a) => a.id === data.status)?.label}
            </Button>
          ) : (
            <Button kind="success" onClick={() => handleUpdateStatus()}>
              {nextStatus(data.status).label}
            </Button>
          )}
        </div>
        {(data.status === "pending" ||
          data.status === "cooking" ||
          data.status === "ready") && (
          <Button
            kind="transparent"
            onClick={() => showCancelModal(true)}
            className="bg-red-100 hover:bg-red-200 text-red-900"
          >
            Cancelar Pedido
          </Button>
        )}
      </div>
      <Modal open={paymentModal} onClose={() => showPaymentModal(false)}>
        <PaymentHandler
          data={{...data, paymentMethod}}
          onClose={() => showPaymentModal(false)}
          onAccept={(m, s) => {
            setPaymentMethod(m);
            setPaymentStatus(s);
            showPaymentModal(false);
          }}
        />
      </Modal>

      <Modal open={cancelModal} onClose={() => showCancelModal(false)}>
        <div className="p-6">
          <h4>Cancelar Pedido</h4>
          <hr />
          <p>
            Estas a punto de cancelar este pedido, esta acción no se puede
            revertir.{" "}
            {data.paymentMethod === "card" &&
              `Si el cliente realizo su pago con tarjeta se realizara
              automaticamente un reembolso en puntos.`}
          </p>
          <hr />

          <Button
            kind="transparent"
            onClick={() => handleCancelation()}
            className="bg-red-100 hover:bg-red-200 text-red-900"
          >
            Confimar Cancelación
          </Button>
        </div>
      </Modal>
    </>
  );
}

interface PaymentHandlerProps {
  data: OrderInvoice;
  onAccept: (
    value: OrderInvoicePaymentMethod,
    status: OrderInvoicePaymentStatus
  ) => void;
  onClose: () => void;
}
const PaymentHandler = ({data, onClose, onAccept}: PaymentHandlerProps) => {
  const [kind, setKind] = useState<OrderInvoicePaymentMethod>(
    data.paymentMethod
  );

  return (
    <div className="p-6">
      <h4>Procesar Pago</h4>
      <p className="text-sm leading-tight text-gray-500">
        Al cambiar el método de pago, el pedido se marca como pagado.
      </p>
      <hr />
      <div className="grid grid-cols-3 gap-2">
        <PaymentCardIcon
          active={kind === "cash"}
          kind="cash"
          onClick={() => setKind("cash")}
        />
        <PaymentCardIcon
          active={kind === "card"}
          kind="card"
          onClick={() => setKind("card")}
        />
        <PaymentCardIcon
          active={kind === "courtesy"}
          kind="courtesy"
          onClick={() => setKind("courtesy")}
        />
      </div>
      <hr />
      <div className="flex items-center justify-between">
        <Button
          kind="success"
          disabled={
            data.paymentStatus === "paid" ||
            data.paymentStatus === "cancelled" ||
            data.paymentStatus === "refunded"
          }
          onClick={() => onAccept(kind, "paid")}
        >
          Aceptar
        </Button>
        <Button
          kind="transparent"
          onClick={onClose}
          className="bg-red-100 hover:bg-red-200 text-red-900"
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};

interface PaymentCardIconProps {
  kind: OrderInvoicePaymentMethod;
  active?: boolean;
  onClick: () => void;
}
const PaymentCardIcon = ({kind, active, onClick}: PaymentCardIconProps) => {
  return (
    <button
      onClick={onClick}
      className={joinClasses([
        "p-4 flex flex-col btn items-center justify-center border-2 hover:border-primary-500",
        active ? "border-primary-600" : "border-gray-200",
      ])}
    >
      <div
        className={joinClasses([
          "text-4xl",
          kind === "card"
            ? "ri-bank-card-line"
            : kind === "cash"
            ? "ri-hand-coin-line"
            : kind === "rewards"
            ? "ri-copper-coin-line"
            : kind === "courtesy"
            ? "ri-star-line"
            : "ri-question-line",
          active ? "text-primary-600" : "text-gray-600",
        ])}
      />
      <h4 className={active ? "text-primary-600" : "text-gray-600"}>
        {kind === "card"
          ? "Tarjeta"
          : kind === "cash"
          ? "Efectivo"
          : kind === "rewards"
          ? "Puntos"
          : kind === "courtesy"
          ? "Cortesia"
          : "Desconocido"}
      </h4>
    </button>
  );
};
