import {GoogleMap, OverlayView} from "@react-google-maps/api";
import React, {useCallback} from "react";
import Setup from "../Setup";
import { joinClasses } from "../ui/utils/Utils";

interface Props {
  center: google.maps.LatLngLiteral;
  className?: HTMLDivElement['className']
}

const initCenter = {lat: 22.23, lng: -97.85} as google.maps.LatLngLiteral;
export default function Map({center, className}: Props) {
  const onLoad = useCallback(
    (map) => {
      map.setOptions({
        gestureHandling: "cooperative",
        fullscreenControl: true,
        disabledDefaultUI: true,
        zoom: 13,
        center: center ?? initCenter,
      });
    },
    [center]
  );

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerClassName={joinClasses(["w-full h-[220px]", className ?? ''])}
      center={center ?? initCenter}
      zoom={13}
    >
      {center && (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={center}
        >
          <div className="h-9 w-9 rounded-full bg-black flex items-center justify-center">
            <img src={Setup.assets.brand} alt="" className="h-7 w-auto" />
          </div>
        </OverlayView>
      )}
    </GoogleMap>
  );
}
