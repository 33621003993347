import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import InputSearch from "../components/InputSearch";
import CategoryOrderingModal from "../components/modals/CategoryOrderingModal";

import Button from "../ui/button/Button";
import Table from "../ui/tables/Table";
import {findQueryText, sorter} from "../utils/Utils";
import {RowItemAvatarName, RowItemChip} from "./TableAtoms";
import {useAppSelector} from "../redux/store";

export default function CategoryTable() {
  const categories = useAppSelector((state) => state.database.categories);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [visible, setVisible] = useState(false);

  const data = sorter(
    categories.filter((a) => findQueryText(a.name, query)),
    "name"
  ).sort((a, b) => Number(a.order) - Number(b.order));

  console.log(data);

  return (
    <>
      <div className="mb-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputSearch
          value={query}
          onChange={setQuery}
          onClear={() => setQuery("")}
        />
        <div>
          <Button onClick={() => setVisible(true)}>
            <span className="ri-arrow-up-down-line text-lg mr-2" />
            Ordenar Categorias
          </Button>
        </div>
      </div>
      <Table
        columns={["Nombre", "Publicado"]}
        data={data.map((item) => [
          <RowItemAvatarName
            name={item.name}
            description={item.description}
            hideMedia
          />,
          <RowItemChip value={item.published} />,
          item,
        ])}
        onClick={(index) => {
          navigate(`/menu/categories/${data[index].id}`);
        }}
      />

      <CategoryOrderingModal
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
}
