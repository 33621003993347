import React from "react";
import {joinClasses} from "../ui/utils/Utils";

interface Props {
  title?: string;
  subtitle?: string;
  children?: any;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
  contentClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
}
export default function Card({title, subtitle, children, className, contentClassName}: Props) {
  return (
    <div className={joinClasses(["card", className ?? ""])}>
      {(!!title || !!subtitle) && (
        <div>
          {!!title && <h5>{title}</h5>}
          {!!subtitle && <p className="text-gray-500 text-sm">{subtitle}</p>}
          <hr />
        </div>
      )}
      <div className={contentClassName ?? ""}>{children}</div>
    </div>
  );
}
