import React from "react";
import Select from "../ui/input/Select";
import useAllowedRestaurants from "../hooks/useAllowedRestaurants";
import useCurrentRestaurant from "../hooks/useCurrentRestaurant";
import {Restaurant} from "../interface";
import {useAppDispatch} from "../redux/store";
import {setRestaurantId} from "../redux/databaseSlice";
import {authRef, makeRef} from "../Firebase";
import {update} from "firebase/database";

interface Props {
  hideUnpublished?: boolean;
}

export default function RestaurantSelect({hideUnpublished}: Props) {
  const restaurant = useCurrentRestaurant();
  const restaurants = useAllowedRestaurants();
  const allowed = hideUnpublished
    ? restaurants.filter((a) => a.published)
    : restaurants;
  const dispatch = useAppDispatch();

  const handleChange = (data: Restaurant) => {
    const ref = makeRef(`administrators/${authRef.currentUser?.uid}`);
    update(ref, {restaurantId: data.id})
      .then(() => console.log("update success"))
      .catch((err) => console.log((err as Error).message));
    dispatch(setRestaurantId(data.id));
  };

  return (
    <Select
      options={allowed}
      label={"name"}
      value={restaurant}
      onChange={handleChange}
    />
  );
}
