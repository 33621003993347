import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { joinClasses } from "../utils/Utils";

type labelArg<T> = keyof T;

interface Props<T> {
  options: T[];
  value?: T | null;
  label: labelArg<T>;
  onChange: (value: T) => void;
  placeholder?: string;
}

export default function Select<T>({ options, value, label, onChange, placeholder }: Props<T>) {
  return (
    <div className="w-full">
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button className="relative btn btn-md bg-white w-full shadow">
                <span className="flex items-center flex-1">
                  <span className="truncate text-black">
                    {!!value ? value[label] : placeholder ? placeholder : "Selección"}
                  </span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <span className="ri-arrow-down-s-line text-xl text-black" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((item, index) => (
                    <Listbox.Option
                      key={`value-${index}`}
                      className={({ active }) =>
                        joinClasses([
                          active ? "text-black bg-slate-200" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9",
                        ])
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <div>
                            <span
                              className={joinClasses([
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate text-black",
                              ])}
                            >
                              {item[label]}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={joinClasses([
                                active ? "text-primary-600" : "",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              ])}
                            >
                              <span className="h-5 w-5 ri-check-fill" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
