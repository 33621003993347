import {app} from "../Firebase";
import {
  getDatabase,
  push as pushDatabase,
  ref as refDatabase,
} from "@firebase/database";
import {
  ref as refStorage,
  uploadString,
  getStorage,
  getDownloadURL,
  uploadBytes,
} from "@firebase/storage";
import {
  LatLng,
  OrderInvoiceDeliveryMethod,
  OrderInvoicePaymentMethod,
  OrderInvoicePaymentStatus,
  OrderInvoiceStatus,
  Place,
  Week,
} from "../interface";
import {
  GeocodeResult,
  Place as GooglePlace,
} from "@googlemaps/google-maps-services-js";
import {isPointInPolygon} from "geolib";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/es-mx";
import {InitWeek} from "../data/InitData";
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale("es-mx");

/**
 * WeekHours Parser
 * @description Fills out an hour schedule if days are missing.
 *  */
export function weekParser(week?: Week) {
  // if no hours found return empty hours;
  if (!week) return InitWeek;
  // for each day find values or return empty hours;
  return InitWeek.map((hours, day) =>
    week ? (week[day] ? week[day] : hours) : hours
  );
}

export const isWithinHourly = (week?: Week) => {
  const num = dayjs().day();
  const hasTime = weekParser(week).filter((times, day) =>
    day === num
      ? times.filter(({starts, ends}) =>
          dayjs().isBetween(
            dayjs()
              .set("h", Number(starts.split(":")[0]))
              .set("m", Number(starts.split(":")[1])),
            dayjs()
              .set("h", Number(ends.split(":")[0]))
              .set("m", Number(ends.split(":")[1]))
          )
        ).length
      : false
  );
  return hasTime.length > 0;
};

/**
 * shouldUploadMedia
 */
export function shouldUploadMedia(value: string) {
  if (!value) return false;
  return (
    value.includes("data:image/jpeg;base64") ||
    value.includes("data:image/png;base64") ||
    !value.includes("https://firebasestorage.googleapis.com")
  );
}

export const uploadImageAsync = async (
  uri: string,
  path?: string
): Promise<string> => {
  try {
    const blob: any = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log("blobError" + e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = refStorage(getStorage(app), path);
    const snapshot = await uploadBytes(ref, blob, {
      contentType: "image/jpeg",
    });

    blob.close();

    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch ({message}:any) {
    console.log("uploadImageAsyncError: " + message);
    return "";
  }
};

type Type = "image/png" | "image/jpeg";
export async function dataUrlUploader(props: {
  data: string;
  path: string;
  type: Type;
  name: string;
}) {
  const db = getDatabase(app);
  const dbRef = refDatabase(db, "v1");
  const id = pushDatabase(dbRef).key;
  const st = getStorage(app);
  const stRef = refStorage(
    st,
    `v1/${props.path}/${props.name}-${id}${
      props.type === "image/jpeg" ? ".jpg" : ".png"
    }`
  );
  try {
    const upload = await uploadString(stRef, props.data, "data_url", {
      contentType: props.type,
    });
    return getDownloadURL(upload.ref);
  } catch (error) {
    if (error instanceof Error) {
      console.log(error.message);
      return "";
    } else {
      return "";
    }
  }
}
export async function uploadMediaArray(
  list: string[],
  path: string,
  name: string,
  type: Type
) {
  try {
    return Promise.all(
      list.map(async (file, index) => {
        return await dataUrlUploader({
          path: path,
          data: file,
          type: type,
          name: `${name}-${index}`,
        });
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      console.log(error.message);
      return [];
    } else {
      return [];
    }
  }
}

const accentsMap = new Map([
  ["A", "Á|À|Ã|Â|Ä"],
  ["a", "á|à|ã|â|ä"],
  ["E", "É|È|Ê|Ë"],
  ["e", "é|è|ê|ë"],
  ["I", "Í|Ì|Î|Ï"],
  ["i", "í|ì|î|ï"],
  ["O", "Ó|Ò|Ô|Õ|Ö"],
  ["o", "ó|ò|ô|õ|ö"],
  ["U", "Ú|Ù|Û|Ü"],
  ["u", "ú|ù|û|ü"],
  ["C", "Ç"],
  ["c", "ç"],
  ["N", "Ñ"],
  ["n", "ñ"],
]);

const reducer = (acc: string, [key]: any) =>
  acc.replace(new RegExp(accentsMap.get(key) as any, "g"), key);

export const removeAccents = (text: string) =>
  [...accentsMap].reduce(reducer, text.normalize("NFD"));

/** Normalize text for matching */
export const findQueryText = (value: string, query: string) => {
  const normalValue = removeAccents(value.toLocaleLowerCase());
  const normalQuery = removeAccents(query.toLocaleLowerCase());
  const strip = normalValue
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
  const stripeQ = normalQuery
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  return strip.includes(stripeQ);
};
export const parsePlaceResults = (
  list: GeocodeResult[] | GooglePlace[],
  kind: "search" | "geocode"
) => {
  return list.map((item) => {
    if (kind === "geocode") {
      const value = item as GeocodeResult;
      return {
        id: value.place_id,
        name: value.formatted_address.split(",")[0],
        address: value.formatted_address,
        latitude: value.geometry.location.lat,
        longitude: value.geometry.location.lng,
        placeId: value.place_id,
      };
    }
    if (kind === "search") {
      const value = item as GooglePlace;
      return {
        id: value.place_id,
        name: value.name,
        address: value.formatted_address,
        latitude: value.geometry?.location.lat,
        longitude: value.geometry?.location.lng,
        placeId: value.place_id,
      };
    }
    return {};
  });
};
export const parseLocationRange = (list: Place[], zone: LatLng[]) => {
  return list.map((item) => ({
    ...item,
    withinRange: isPointInPolygon(
      {latitude: item.latitude, longitude: item.longitude},
      zone
    ),
  }));
};

export const toShortId = (value?: string | null) => {
  if (!value) return "NULL";
  return value
    .replace(/-/g, "")
    .replace(/_/g, "")
    .substring(0, 8)
    .toLocaleUpperCase();
};

// Custom Sorter
type sortArg<T> = keyof T | `-${string & keyof T}`;
/**
 * byPropertiesOf
 * @description Returns a comparator for objects of type <T>.
 * @param sortBy the names of the properties to sort by. If `—` is used it'll sort in descending order.
 */
export function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
  function compareByProperty(arg: sortArg<T>) {
    let key: keyof T;
    let sortOrder = 1;
    if (typeof arg === "string" && arg.startsWith("-")) {
      sortOrder = -1;
      key = arg.substring(1) as keyof T;
    } else {
      key = arg as keyof T;
    }
    return function (a: T, b: T) {
      const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      return result * sortOrder;
    };
  }
  return function (obj1: T, obj2: T) {
    let i = 0;
    let result = 0;
    const numberOfProperties = sortBy?.length;
    while (result === 0 && i < numberOfProperties) {
      result = compareByProperty(sortBy[i])(obj1, obj2);
      i++;
    }
    return result;
  };
}

/**
 * Sorter
 * @description sorts an array of <T> by the specified properties of <T>.
 * @param arr Array of <T>
 * @param sortBy The names of the properties to sort by. Prefix with `—` for descending order.
 */
export function sorter<T extends object>(
  arr: T[],
  ...sortBy: Array<sortArg<T>>
) {
  return arr.sort(byPropertiesOf<T>(sortBy));
}

export function getElementWidth(element: HTMLElement | null) {
  if (!element) return 0;
  return element.getBoundingClientRect().width;
}

export function stringToSlug(text: string) {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 /]/g, "")
    .replace(/\//g, "-")
    .replace(/\s+/g, "-");
}

export const calculateFinalPrice = (
  showSalePrice?: boolean,
  salePrice?: number,
  price?: number,
  chargeTax?: boolean
) => {
  const salePriceNumber = Number(salePrice ?? 0);
  const priceNumber = Number(price ?? 0);
  const value = showSalePrice ? salePriceNumber : priceNumber;
  const taxPrice = parseFloat(
    Number(chargeTax ? Number(value) * 1.16 : Number(value)).toFixed(2)
  );
  const regularTaxPrice = parseFloat(
    Number(chargeTax ? Number(price) * 1.16 : Number(price)).toFixed(2)
  );
  return [taxPrice, regularTaxPrice];
};

export const objectsEqual = (o1: any, o2: any): boolean =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const paymentStatusLabel = (
  status?: OrderInvoicePaymentStatus | null
) => {
  switch (status) {
    case "cancelled":
      return "Cancelado";
    case "paid":
      return "Pagado";
    case "processing":
      return "Procesando";
    case "refunded":
      return "Reembolsado";
    case "unpaid":
      return "Sin Pagar";
    default:
      return "Desconocido";
  }
};

export const orderStatusLabel = (status?: OrderInvoiceStatus | null) => {
  switch (status) {
    case "cancelled":
      return "Cancelado";
    case "completed":
      return "Completado";
    case "cooking":
      return "Preparando";
    case "pending":
      return "Nuevo";
    case "ready":
      return "Listo";
    default:
      return "Desconocido";
  }
};

export const deliveryMethodLabel = (
  method?: OrderInvoiceDeliveryMethod | null
) => {
  switch (method) {
    case "delivery":
      return "Domicilio";
    case "pickup":
      return "Sucursal";
    case "table":
      return "Mesa";
    default:
      return "Desconocido";
  }
};

export const paymentMethodLabel = (method?: OrderInvoicePaymentMethod | null) => {
  switch (method) {
    case 'card': return 'Tarjeta';
    case 'cash': return 'Efectivo';
    case 'courtesy': return 'Cortesia';
    case 'rewards': return 'Puntos';
    default: return 'Desconocido';
  }
}


/**
 * groupArrayBy
 * @description Groups Object Arrays by a key value
 */
 export function groupArrayBy<T, K extends keyof any>(a: T[], k: (b: T) => K) {
  return a.reduce((prev, curr) => {
    const group = k(curr);
    if (!prev[group]) prev[group] = [];
    prev[group].push(curr);
    return prev;
  }, {} as Record<K, T[]>);
}

/**
 * uniqueArraySet
 * @description Removes any duplicates in array
 */
 export function uniqueArraySet(array: Array<any>) {
  //  @ts-ignore
  return [...new Set(array.map((s) => JSON.stringify(s)))].map((s) =>
    JSON.parse(s)
  );
}

export function convertArrayToObject<
  T extends {[prop in string | number]: any},
  K extends keyof Pick<
    T,
    {
      [Key in keyof T]: T[Key] extends string | number ? Key : never;
    }[keyof T]
  > = keyof Pick<
    T,
    {
      [Key in keyof T]: T[Key] extends string | number ? Key : never;
    }[keyof T]
  >,
  A extends T[] = T[]
>(array: readonly T[], key: K) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue) as {[propkey in A[number][K]]: A[number]};
}