import React from "react";
import {joinClasses} from "../ui/utils/Utils";

interface Props {
  title: string;
  subtitle?: string;
  innerClassName?: HTMLDivElement["className"];
  children?: any;
  goBack?: boolean;
}
export default function Header({
  title,
  subtitle,
  innerClassName,
  children,
  goBack,
}: Props) {
  return (
    <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="col-span-1">
        <h3>{title}</h3>
        {!!subtitle && <p className="leading-tight">{subtitle}</p>}
        {!!goBack && (
          <button
            className="flex items-center text-gray-500"
            onClick={() => window.history.back()}
          >
            <span className="ri-arrow-left-line text-xl" />
            <span>Regresar</span>
          </button>
        )}
      </div>
      <div
        className={joinClasses([
          "flex items-center col-span-1 md:col-span-2",
          innerClassName ?? "",
        ])}
      >
        {children}
      </div>
    </div>
  );
}
