import React, {useCallback, useState} from "react";
import {GoogleMap, OverlayView} from "@react-google-maps/api";
import {StyleObject} from "styletron-standard";
import Setup from "../Setup";

interface Props {
  values: google.maps.visualization.WeightedLocation[];
  center: google.maps.LatLngLiteral;
  mapsContainerStyle?: StyleObject;
}

const initCenter = {lat: 22.23, lng: -97.85} as google.maps.LatLngLiteral;

export default function HeatMap({values, center, mapsContainerStyle}: Props) {
  const [zoom, setZoom] = useState(13);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      // GENERAL
      map.setOptions({
        gestureHandling: "cooperative",
        fullscreenControl: true,
        disableDefaultUI: true,
        zoom: 13,
        zoomControl: true,
        center: center ?? initCenter,
      });

      const heatView = new google.maps.visualization.HeatmapLayer({
        data: values,
        radius: 25,
        maxIntensity: 20,
      });
      heatView.setMap(map);
    },
    [values, center]
  );

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={{
        height: "220px",
        width: "100%",
        ...mapsContainerStyle,
      }}
      center={center}
      zoom={zoom}
      onCenterChanged={() => setZoom(16)}
    >
      {center && (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={center}
        >
          <div className="h-9 w-9 rounded-full bg-black flex items-center justify-center">
            <img src={Setup.assets.brand} alt="" className="h-7 w-auto" />
          </div>
        </OverlayView>
      )}
    </GoogleMap>
  );
}
