import { UserRole } from "./interface";
import Account from "./screens/Account";
import CustomerDetails from "./screens/CustomerDetails";
import Customers from "./screens/Customers";
import Dashboard from "./screens/Dashboard";
// import Experiments from "./screens/Experiments";
import FormCategory from "./screens/FormCategory";
import FormDish from "./screens/FormDish";
import FormIngredient from "./screens/FormIngredient";
import FormRestaurant from "./screens/FormRestaurant";
import FormVariation from "./screens/FormVariation";
import History from "./screens/History";
import Locations from "./screens/Locations";
import Menu from "./screens/Menu";
import Orders from "./screens/Orders";
import Settings from "./screens/Settings";
import Users from "./screens/Users";
import Broadcaster from "./screens/Broadcaster";

interface Route {
  path: string;
  element: any;
  icon?: string;
  label?: string;
  role: UserRole[];
  sidebar?: boolean;
}

const management = ["administrator", "manager"] as UserRole[];
const admin = ["administrator"] as UserRole[];
const all = ["administrator", "manager", "user"] as UserRole[];

const Routes = [
  {
    path: "/dashboard",
    icon: "dashboard-3",
    element: <Dashboard />,
    role: management,
    sidebar: true,
  },
  {
    path: "/orders",
    icon: "shopping-basket",
    element: <Orders />,
    role: all,
    sidebar: true,
  },
  {
    path: "/history",
    icon: "history",
    element: <History />,
    role: management,
    sidebar: true,
  },
  {
    path: "/menu",
    icon: "restaurant",
    element: <Menu />,
    role: [...management, "marketing"],
    sidebar: true,
  },
  {
    path: "/menu/dishes/:id",
    element: <FormDish />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/dishes-add",
    element: <FormDish />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/categories/:id",
    element: <FormCategory />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/categories-add",
    element: <FormCategory />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/variations/:id",
    element: <FormVariation />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/variations-add",
    element: <FormVariation />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/ingredients/:id",
    element: <FormIngredient />,
    role: [...management, "marketing"],
  },
  {
    path: "/menu/ingredients-add",
    element: <FormIngredient />,
    role: [...management, "marketing"],
  },
  {
    path: "/customers",
    icon: "group",
    element: <Customers />,
    role: management,
    sidebar: true,
  },
  {
    path: "/customers/:id",
    element: <CustomerDetails />,
    role: management,
  },
  {
    path: "/broadcaster",
    icon: "radar",
    element: <Broadcaster />,
    role: ["administrator", "manager", "marketing"],
    sidebar: true,
  },
  {
    path: "/locations",
    icon: "store-2",
    element: <Locations />,
    role: admin,
    sidebar: true,
  },
  {
    path: "/locations-add",
    element: <FormRestaurant />,
    role: management,
  },
  {
    path: "/locations/:id",
    element: <FormRestaurant />,
    role: management,
  },
  {
    path: "/users",
    icon: "contacts",
    element: <Users />,
    role: management,
    sidebar: true,
  },
  {
    path: "/settings",
    icon: "settings",
    element: <Settings />,
    role: admin,
    sidebar: true,
  },
  {
    path: "/account",
    icon: "account-circle",
    element: <Account />,
    role: all,
    sidebar: true,
  },
  // {
  //   path: "/experiments",
  //   icon: "magic",
  //   element: <Experiments />,
  //   role: admin,
  //   sidebar: true,
  // },
] as Route[];
export default Routes;
