import React from "react";

interface Props {
  value: boolean;
  onChange: () => void;
}

export default function Switch(props: Props) {
  return (
    <button onClick={props.onChange}>
      <div
        className={`h-10 w-16 ${
          !props.value ? "bg-gray-300" : "bg-green-600"
        } rounded-3xl flex items-center px-1 transition duration-500 ease-in-out`}
      >
        <div
          className={`flex items-center justify-center h-8 w-8 rounded-full bg-white transform ${
            props.value
              ? "translate-x-6 ring-2 ring-green-400 text-green-600"
              : "-translate-x-0 text-transparent"
          } transition duration-200 ease-in-out`}
        />
      </div>
    </button>
  );
}
