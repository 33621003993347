import classNames from "classnames";
import React, {Fragment} from "react";
import {
  Cell,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
} from "recharts";

interface Data {
  title: string;
  value: number;
  caption: string;
  color: string;
}

export interface CardWithDonutProps {
  title: string;
  data: Data[];
}

export default function CardWithDonut(props: CardWithDonutProps) {
  return (
    <div className="card">
      <h3 className="text-lg font-medium">{props.title}</h3>
      <div className="my-8">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={props.data}
              cx="50%"
              cy="50%"
              dataKey="value"
              innerRadius={35}
              outerRadius={100}
              labelLine={false}
              label={(pass) => renderCustomLabel(pass, props.data)}
              className="pointer-events-none"
            >
              {props.data.map((item, index) => (
                <Cell key={`cell-${index}`} fill={item.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <ul className="space-y-4 p-4 pt-0">
        {props.data.map((item, index) => {
          return (
            <li key={`item-${index}`} className="flex w-full gap-4">
              <div
                className="h-4 w-4 rounded-full shrink-0"
                style={{backgroundColor: item.color}}
              />
              <div>
                <div className="font-medium leading-none">
                  {item.title}
                </div>
                <div className="text-slate-500 text-sm">
                  {item.value} {item.caption}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const renderCustomLabel = (props: PieLabelRenderProps, data: Data[]) => {
  const RADIAN = Math.PI / 180;
  const radius =
    Number(props.innerRadius) +
    Number(props.outerRadius) -
    Number(props.innerRadius) * 0.5;
  const x = Number(props.cx) + radius * Math.cos(-props.midAngle * RADIAN);
  const y = Number(props.cy) + radius * Math.sin(-props.midAngle * RADIAN);

  if (!Number(props.percent)) return <Fragment />;
  return (
    <foreignObject x={x - 16} y={y - 16} width="100%" height="100%">
      <div
        className={classNames(
          "px-2 py-1 rounded-full shadow shadow-md border text-xs font-medium text-center flex items-center gap-1",
          "bg-white border-slate-300 text-black"
        )}
        style={{maxWidth: Number(props?.percent) * 100 > 9 ? "60px" : "50px"}}
      >
        <div
          className="block h-2 w-2 shrink-0 rounded-full"
          style={{backgroundColor: data[props.index || 0].color}}
        />
        {`${(Number(props?.percent) * 100).toFixed(0)}%`}
      </div>
    </foreignObject>
  );
};
