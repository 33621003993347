import React from "react";
import {TimePicker} from "baseui/timepicker";
import {Week} from "../interface";
import Button from "../ui/button/Button";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

interface Props {
  value: Week;
  onChange: (value: Week) => void;
}

export default function WeekSchedule({value, onChange}: Props) {
  return (
    <div className="w-full">
      {value.map((times, day) => {
        return (
          <div
            className="grid grid-cols-1 md:grid-cols-4 gap-y-2 md:gap-4 mb-4"
            key={`day-${day}`}
          >
            <div className="p-3 bg-gray-200 col-span-1 self-start">
              <h6 className="capitalize">
                {dayjs().set("day", day).format("dddd")}
              </h6>
            </div>
            <div className="col-span-3">
              {times.map(({starts, ends}, index) => {
                return (
                  <div
                    className="grid grid-cols-2 gap-4 mb-2"
                    key={`times-${day}-${index}`}
                  >
                    <TimePicker
                      creatable
                      step={300}
                      value={dayjs(starts, "H:mm").toDate()}
                      placeholder="00:00"
                      format="24"
                      onChange={(date) => {
                        const updates = value.map((item, idx) =>
                          idx === day
                            ? item.map((a, i) =>
                                i === index
                                  ? {...a, starts: dayjs(date).format("HH:mm")}
                                  : a
                              )
                            : item
                        );
                        onChange(updates);
                      }}
                    />
                    <div className="flex">
                      <TimePicker
                        creatable
                        step={300}
                        value={dayjs(ends, "H:mm").toDate()}
                        placeholder="00:00"
                        format="24"
                        onChange={(date) => {
                          const updates = value.map((item, idx) =>
                            idx === day
                              ? item.map((a, i) =>
                                  i === index
                                    ? {...a, ends: dayjs(date).format("HH:mm")}
                                    : a
                                )
                              : item
                          );
                          onChange(updates);
                        }}
                      />
                      <Button
                        kind="danger"
                        className="h-8 aspect-square ml-3"
                        onClick={() => {
                          const updates = value.map((item, idx) =>
                            idx === day
                              ? item.filter((x, i) => i !== index)
                              : item
                          );
                          onChange(updates);
                        }}
                      >
                        <div className="ri-close-fill text-xl" />
                      </Button>
                    </div>
                  </div>
                );
              })}
              {times.length < 4 && (
                <Button
                  kind="success"
                  size="sm"
                  onClick={() => {
                    const time = {starts: "00:00", ends: "23:59"};
                    const merged = value.map((item, index) => {
                      return index === day ? item.concat([time]) : item;
                    });
                    onChange(merged);
                  }}
                >
                  <div className="ri-add-fill text-xl mr-2" />
                  Agregar
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
