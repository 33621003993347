import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { LoadScript } from "@react-google-maps/api";
import { BrowserRouter } from "react-router-dom";

import "./app.scss";
import "remixicon/fonts/remixicon.css";

import { Client } from "styletron-engine-atomic";
import { DebugEngine, Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, LightTheme, LocaleProvider } from "baseui";
import { SnackbarProvider } from "baseui/snackbar";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import Locale from "./Locale";

const engine = new Client();
const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <LoadScript
        googleMapsApiKey={"AIzaSyDWnyhKTVUd60BEhFL2WXVx2oTQHt27k3Y"}
        libraries={["geometry", "visualization", "drawing"]}
        language={"es"}
        region={"mx"}
      >
        <StyletronProvider value={engine} debug={debug} debugAfterHydration>
          <LocaleProvider locale={Locale}>
            <BaseProvider theme={LightTheme}>
              <SnackbarProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </SnackbarProvider>
            </BaseProvider>
          </LocaleProvider>
        </StyletronProvider>
      </LoadScript>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
